import React, { useEffect, useState } from "react";
import { Input, Grid, Typography, Button } from "@mui/material";

export default function ResultButtonCard(props) {
  const [resultData, setResultData] = useState(props.resultData);

  useEffect(() => {
    setResultData(props.resultData);
    console.log("I am re rendering now ........", props.ResultData);
  }, [props.resultData]);

  return (
    <>
      {resultData?.skillScore?.map((data, key) =>
        data.rubricsQts.map((datas, i) => (
          <>
            <Grid
              container
              xs={12}
              style={{
                backgroundColor: "#ffffff",
                height: "auto",
                width: "93%",
                marginLeft: "10px",
                marginRight: "10px",
                marginTop: "7px",
                // margin: "10px",
                borderRadius: "10px",
              }}
            >
              <Grid item style={{ textAlign: "left" }}>
                <Typography
                  className="lightGreyColorTypo Fs-0-8"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingTop: "3px",
                    paddingBottom: "10px",
                  }}
                >
                  {datas.quesDesc}
                </Typography>
              </Grid>

              {datas.quesType == 1 && (
                <Grid container style={{ marginBottom: "12px" }}>
                  <Grid item xs={4} marginLeft="6px">
                    <Input
                      className="RubicInputs"
                      id="QuNo"
                      name="quesNo"
                      disabled={true}
                      // onChange={(e) => handleInputChange(e, i)}
                      value={datas?.type}
                      // onChange={(e) => {
                      //   // add to list
                      //   setRubricsInput([
                      //     ...rubricsQts,
                      //     {
                      //       quesNo: " ",
                      //     },
                      //   ]);
                      // }}
                    ></Input>
                  </Grid>
                </Grid>
              )}

              {datas.quesType == 2 && (
                <Grid container style={{ marginBottom: "4px" }}>
                  <Grid item xs={4}>
                    <button
                      type="submit"
                      value="Yes"
                      className={
                        datas?.type == "Yes"
                          ? "GreenButton lightGreyColorTypo Fs-0-8 SmallButtons"
                          : "WhiteButton700"
                      }
                    >
                      Yes
                    </button>
                  </Grid>
                  <Grid item xs={4}>
                    <button
                      type="submit"
                      value="No"
                      className={
                        datas?.type == "No"
                          ? "RedButton700 lightGreyColorTypo Fs-0-8 SmallButtons "
                          : "WhiteButton700   ProfileButton"
                      }
                    >
                      NO
                    </button>
                  </Grid>
                </Grid>
              )}

              {datas.quesType == 3 && (
                <Grid container style={{ marginBottom: "4px" }}>
                  <Grid item xs={4}>
                    <button
                      type="submit"
                      value="Not Yet"
                      className={
                        datas?.type == "Not Yet"
                          ? "RedButton700 lightGreyColorTypo Fs-0-8 SmallButtons "
                          : "WhiteButton700"
                      }
                    >
                      Not Yet
                    </button>
                  </Grid>
                  <Grid item xs={4}>
                    <button
                      type="submit"
                      value="Getting There"
                      className={
                        datas?.type == "Getting There"
                          ? "YellowButton700 lightGreyColorTypo Fs-0-8 SmallButtons"
                          : "WhiteButton700   ProfileButton"
                      }
                    >
                      Getting There
                    </button>
                  </Grid>
                  <Grid item xs={4}>
                    <button
                      type="submit"
                      value="Gets IT"
                      className={
                        datas?.type == "Gets IT"
                          ? "GreenButton700 lightGreyColorTypo Fs-0-8 SmallButtons "
                          : "WhiteButton700   ProfileButton"
                      }
                    >
                      Gets IT
                    </button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        ))
      )}
    </>
  );
}
