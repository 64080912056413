import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { FETCH_URL } from "../../../fetchIp";
import LoadingSpinner from "../../Spinner/LoadingSpinner";

const SecurityNew = () => {
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const role = 4;
  const [isLoading, setIsLoading] = useState(false);
  const [isShow, setIsShow] = useState(true);

  const [passwordInput, setPasswordInput] = useState({
    password: "",
    confirmPassword: "",
  });
  const serviceUserId = localStorage.getItem("userId");

  const showAlert = (msg, type) => {
    enqueueSnackbar(msg, {
      variant: type,
      autoHideDuration: 3000,
    });
  };

  const PasswordValidation = () => {
    const passwordInputValue = newPassword;

    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const digitsRegExp = /(?=.*?[0-8])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp = /.{6,}/;
    const passwordLength = passwordInputValue.length;
    const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
    const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
    const digitsPassword = digitsRegExp.test(passwordInputValue);
    const specialCharPassword = specialCharRegExp.test(passwordInputValue);
    const minLengthPassword = minLengthRegExp.test(passwordInputValue);
    let errMsg = "";
    if (passwordLength === 0) {
      errMsg = "Password can not  empty";
    } else if (!uppercasePassword) {
      errMsg = "At least one Uppercase";
    } else if (!lowercasePassword) {
      errMsg = "At least one Lowercase";
    } else if (!digitsPassword) {
      errMsg = "At least one digit";
    } else if (!specialCharPassword) {
      errMsg = "At least one Special Characters";
    } else if (!minLengthPassword) {
      errMsg = "At least minumum 6 characters";
    } else {
      errMsg = "";
    }

    if (passwordInputValue !== confirmPassword) {
      errMsg = "Confirm password is not matched";
    }

    if (errMsg.length !== 0) {
      showAlert(errMsg, "error");
      return false;
    } else {
      return true;
    }
  };

  const updatePassword = async (e) => {
    e.preventDefault();
    try {
      if (PasswordValidation()) {
        setIsLoading(true);
        console.log({
          role,
          serviceUserId,
          currentPassword,
          newPassword,
        });
        const response = await fetch(`${FETCH_URL}/api/auth/updatePassword`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            role,
            serviceUserId,
            currentPassword,
            newPassword,
          }),
        });

        const res = await response.json();
        console.log("response is : ", res);
        if (response.ok) {
          showAlert(res.msg, "success");
          navigate(`/student/home`);
        } else {
          showAlert(res.err, "error");
        }
        setIsLoading(false);
      }
    } catch (err) {
      console.log("Error is ", err);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? <LoadingSpinner /> : null}
      <Grid container direction="row">
        <div className="BackNotificaton">
          <IoIosArrowBack onClick={() => navigate(-1)} />
        </div>
      </Grid>
      <Typography
        className="GreyColorTypography"
        sx={{ pl: "19px", fontSize: "1.5rem" }}
      >
        Security
      </Typography>
      <div className="securityCont">
        <form onSubmit={updatePassword}>
          <div className="GreyColorTypography school">
            <label>Current Password</label>
            <input
              type={!isShow ? "text" : "password"}
              value={currentPassword}
              className="pl-15"
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </div>

          <div className="GreyColorTypography school">
            <label>New Password</label>
            <input
              type={!isShow ? "text" : "password"}
              value={newPassword}
              className="pl-15"
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>

          <div className="GreyColorTypography school">
            <label>Confirm Password</label>
            <input
              type={!isShow ? "text" : "password"}
              value={confirmPassword}
              className="pl-15"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>

          <div className="hideShow">
            <label onClick={() => setIsShow(!isShow)}>
              {isShow ? "Show Password" : "Hide Password"}
            </label>
          </div>

          <div className="GreyColorTypography resetBtns pb-25">
            <button>Reset Password</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SecurityNew;
