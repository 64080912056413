import React, { useEffect, useState } from "react";
import kid from "../../../assets/img/practice_win (1).png";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../../Context/AuthContext";

export default function CoursesWinRestart() {
  const location = useLocation();
  const { trainerId } = useAuth();
  const [Locomotor, setLocomotor1] = useState();
  const [BodyManagement, setBodyManagement1] = useState();
  const [ObjectControl, setObjectControl1] = useState();

  let LocoSkill = 0;
  let BodySkill = 0;
  let ObjSkill = 0;
  let LocoPer = 0;
  let BodyPer = 0;
  let ObjPer = 0;

  let locoP = 0;
  let bodyP = 0;
  let objP = 0;
  console.log("State item is ", location?.state);

  let resultPP = location?.state?.total;
  const stateAssignedPP = location?.state?.states;
  const skillScore = location?.state?.skillScore;

  console.log(stateAssignedPP, "stateAssignedPP");

  const stateDataResult = location?.state?.total;
  console.log(stateDataResult, "stateDataResult");
  console.log("State all item", stateAssignedPP, skillScore);

  const [locomotor, setLocomotor] = useState({
    locopercent: 0,
    resultPP: 0,
  });
  const [bodyManagement, setBodyManagement] = useState({
    bodypercent: 0,
    resultPP: 0,
  });
  const [objectControl, setObjectControl] = useState({
    objectpercent: 0,
    resultPP: 0,
  });
  const [skillData, setSkillData] = useState();

  const skillData1 = () => {
    skillScore?.map((data) => {
      if (data?.mainSkill == "Body Management") {
        BodySkill++;
        BodyPer += parseInt(data?.percent);
      }
      if (data?.mainSkill == "Object Control") {
        ObjSkill++;
        ObjPer += parseInt(data?.percent);
      }
      if (data?.mainSkill == "Locomotor") {
        LocoSkill++;
        LocoPer += parseInt(data?.percent);
      }
    });

    if (LocoPer !== 0) {
      LocoPer = LocoPer / LocoSkill;
      setLocomotor1(LocoPer);
    }
    if (BodyPer !== 0) {
      BodyPer = BodyPer / BodySkill;
      setBodyManagement1(BodyPer);
    }

    if (ObjPer !== 0) {
      ObjPer = ObjPer / ObjSkill;
      setObjectControl1(ObjPer);
    }
  };

  useEffect(() => {
    // skills();
    skillData1();
  }, []);

  useEffect(() => {
    if (window.ReactNativeWebView) window.ReactNativeWebView.postMessage(3);
  }, []);

  return (
    <>
      <div className="baccha bg-333333">
        <div className="bacchaImg">
          <img src={kid} alt="" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            position: "absolute",
            top: "40%",
          }}
        >
          <div className="resultPP">
            <h3>
              {/* {stateDataResult}/{stateAssignedPP?.LPXp} PP */}
              {resultPP?.toFixed()}/{stateAssignedPP?.LPXp} PP
            </h3>
            <h4>Play Points</h4>
          </div>
          <div className="resultScore">
            <h2>
              {/* {stateDataResult}/{stateAssignedPP?.LPXp} PP */}
              {resultPP?.toFixed()}/{stateAssignedPP?.LPXp} PP
            </h2>
            <h5>Score</h5>
          </div>
          <hr className="wid" />

          <div className="completeGrid">
            <div className="resultValue">
              <h3>
                Locomotor&nbsp;{" "}
                {Locomotor == undefined || isNaN(Locomotor) ? "0" : Locomotor}%
              </h3>
              <h3>
                Body Management&nbsp;
                {BodyManagement == undefined || isNaN(BodyManagement)
                  ? "0"
                  : BodyManagement}
                %
              </h3>
              <h3>
                Object Control&nbsp;
                {ObjectControl == undefined || isNaN(ObjectControl)
                  ? "0"
                  : ObjectControl}
                %
              </h3>
              {console.log("Dataiiiiiiiiiiii is ", {
                Locomotor,
                BodyManagement,
                ObjectControl,
              })}
            </div>
            <div className="resultPPs">
              <div className="resultPPs">
                <h3>
                  {isNaN((resultPP * Locomotor) / 100)
                    ? "0"
                    : (resultPP * Locomotor) / 100}{" "}
                  PP
                </h3>
                <h3>
                  {isNaN((resultPP * BodyManagement) / 100)
                    ? "0"
                    : (resultPP * BodyManagement) / 100}{" "}
                  PP
                </h3>
                <h3>
                  {isNaN((resultPP * ObjectControl) / 100)
                    ? "0"
                    : (resultPP * ObjectControl) / 100}{" "}
                  PP
                </h3>
              </div>
            </div>
          </div>
          <Link to="/student/courses">
            <div className="resultPPBtn">
              <button>Continue</button>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}
