import React, { useState } from "react";
//import routes from "../routes";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";

import Authroutes from "../Authroutes";

export default function Auth() {
  const getRoutes = (Authroutes) => {
    return Authroutes.map((prop, key) => {
      // console.log("My prop Layout ======>", prop.link);
      if (prop.layout === "/auth") {
        // console.log("If condition    ");
        return (
          <Route
            path={prop.layout + prop.link}
            element={prop.Element}
            key={key}
          />
        );
      } else {
        console.log("AHHHHHHHI");
        return null;
      }
    });
  };
  //console.log("Routes======>", { authroutes });

  return (
    <>
      <Routes>
        {getRoutes(Authroutes)}
        <Route path="/" element={<Navigate replace={true} to="/auth" />} />
        <Route
          exact
          path="/auth"
          element={<Navigate replace={true} to="/auth/signin" />}
        />
        {/* <Route path="*" element={<Navigate to="/" />} /> */}
      </Routes>
    </>
  );
}
