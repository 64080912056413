import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import {
  Typography,
  Grid,
  Drawer,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Toolbar,
  Box,
} from "@mui/material";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ZoomerImg from "../../assets/img/Zoomer.png";
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import { RiSearchLine } from "react-icons/ri";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import { FETCH_URL } from "../../fetchIp";

export default function PrimarySearchAppBar() {
  const [notification, setNotification] = useState(0);
  const serviceUserId = localStorage.getItem("userId");

  // const ref = React.useRef(null);
  // const pathname = window.location.pathname;
  // const [bottomvalue, setBottomValue] = useState(pathname);
  // const [value, setValue] = React.useState(0);
  // const [openDrawer, setOpenDrawer] = React.useState(false);
  // const [selectedIndex, setSelectedIndex] = React.useState(0);

  // const BasicInfo = [
  //   {
  //     BasicTypo: " Build foundation skills     ",
  //   },
  //   {
  //     BasicTypo: "  6 essential Skills and games     ",
  //   },
  //   {
  //     BasicTypo: " Basic equipment kit   ",
  //   },
  //   {
  //     BasicTypo: "  Basic FMS Reports   ",
  //   },
  // ];

  const getAllNotification = async () => {
    console.log(
      "Notification",
      `${FETCH_URL}/api/lessonplan/notification/getSingleNotificationByUserIdEfficient/${serviceUserId}`
    );
    try {
      const resp = await fetch(
        `${FETCH_URL}/api/lessonplan/notification/getSingleNotificationByUserIdEfficient/${serviceUserId}`,
        {
          method: "GET",
          "Content-type": "application/json",
        }
      );

      const res = await resp.json();
      console.log("Notification Data", res);
      if (resp.ok) {
        let notif = 0;

        res?.msg?.map((item) => {
          if (!item?.status) notif++;
        });

        setNotification(notif);
      }
    } catch (err) {
      console.log("Error is ", err);
    }
  };

  useEffect(() => {
    getAllNotification();
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar style={{ backgroundColor: "#CDDC39" }} position="fixed">
        <Toolbar>
          <Sidebar />
          <Typography align="center" style={{ width: "100%", height: "60px" }}>
            <img
              style={{
                height: "70px",
              }}
              src={ZoomerImg}
            />
          </Typography>

          <Box sx={{ flexGrow: 1 }} />

          <Link edge="end" to={`/student/notification`}>
            <Badge className="Badge" badgeContent={notification}>
              <CircleNotificationsIcon
                style={{
                  color: "white",
                  fontSize: "30px",
                  marginLeft: "20px",
                }}
              />
            </Badge>
          </Link>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
