import React, { useState, useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import { VscBellDot } from "react-icons/vsc";
import { Grid, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FETCH_URL } from "../../../fetchIp";
import { useAuth } from "../../../Context/AuthContext";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LoadingSpinner from "../../Spinner/LoadingSpinner";
import axios from "axios";

export default function AssessmentNew() {
  const [changeValue, setChangeValue] = useState("");
  const navigate = useNavigate();
  const { trainerId } = useAuth();
  const [gradeData, setGradeData] = useState([0]);
  const [gradeValue, setGradeValue] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [school, setSchool] = useState([]);

  const handleChange = async (schoolId) => {
    try {
      setChangeValue(schoolId);
      const data = await axios.get(
        `${FETCH_URL}/api/school/grade/getGradeByTrainerAndSchoolId/${trainerId}/${schoolId}`
      );
      setGradeValue(data?.data?.msg);

      console.log("Grade data is ", data);
    } catch (err) {
      console.log("Error is ", err);
    }
  };

  const getGrade = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getTrainerGrades/${trainerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setGradeData(res.msg);
        let schoolData = [];
        let schoolIds = [];
        console.log("Grade Data =======>", res.msg);

        for (let grade of res?.msg) {
          if (!schoolIds.includes(grade?.schoolId)) {
            schoolIds.push(grade?.schoolId);
            schoolData.push({
              schoolName: grade?.schoolName,
              schoolId: grade?.schoolId,
            });
          }
        }

        const data = await axios.get(
          `${FETCH_URL}/api/school/grade/getGradeByTrainerAndSchoolId/${trainerId}/${schoolIds[0]}`
        );

        setChangeValue(schoolIds[0]);

        console.log("DAta is sssssss", data);
        console.log("School data is ", schoolData);
        setSchool(schoolData);
        setGradeValue(data?.data?.msg);
      } else {
        console.error("API error =====>", res.err);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.log("Catch block ====>", error);
    }
  };

  useEffect(() => {
    getGrade();
  }, []);

  return (
    <>
      {isLoading ? <LoadingSpinner /> : null}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        backgroundColor="#CDDC39"
        borderRadius="0px"
        height="82px"
      >
        <div className="BackHead ">
          <IoIosArrowBack onClick={() => navigate(-1)} />
        </div>
        <div className="WhiteColorTypography MyclassesTypo ml-10px">
          Assessments
        </div>
        <div>{/* <VscBellDot className="BellIcon" /> */}</div>
      </Grid>

      <div className="schlBox">
        <div className="blackType schlText">
          <h2>School</h2>
        </div>
        <FormControl
          sx={{ m: 2.2, minWidth: "90%" }}
          size="small"
          className="select-radius-8px"
        >
          <InputLabel id="school_id">Select School</InputLabel>
          <Select
            labelId="school_id"
            id="school_id"
            value={changeValue}
            label="Select School"
            onChange={(e) => handleChange(e.target.value)}
          >
            {school?.map((data, index) => {
              return (
                <MenuItem
                  selected={!index ? true : false}
                  value={data?.schoolId}
                >
                  {data.schoolName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>

      <Grid mt={1.5}>
        <div className="blackType gradeText">
          <h2>Grades</h2>
        </div>

        <Grid container p={1}>
          {gradeValue?.map((item, index) => {
            return (
              <Grid item xs={6} key={item?._id} p={0.6} mb={1}>
                <Grid className={"assessment-css"}>
                  <Link to="/student/assesmentgrade" state={item}>
                    <Grid className="space-between pb-8px">
                      <Typography className="color-6f6f6f font-w-700">
                        Grade
                      </Typography>
                      <Typography className={"color-black font-w-700"}>
                        {item?.grade}
                      </Typography>
                    </Grid>

                    <Grid className="space-between pb-8px">
                      <Typography className="color-6f6f6f font-w-700">
                        Division
                      </Typography>
                      <Typography className={"color-black font-w-700"}>
                        {item?.divisions.length}
                      </Typography>
                    </Grid>

                    <Grid className="space-between pb-8px">
                      <Typography className="color-6f6f6f font-w-700">
                        Student
                      </Typography>
                      <Typography className={"color-black font-w-700"}>
                        {item?.assignedLearners?.length}
                      </Typography>
                    </Grid>
                  </Link>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
}
