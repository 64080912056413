import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import card from "../../../assets/img/Rectangle 2934.png";
import { Progress } from "react-sweet-progress";
import { FETCH_URL } from "../../../fetchIp";
import { useAuth } from "../../../Context/AuthContext";
import axios from "axios";
import HideImageIcon from "@mui/icons-material/HideImage";
import {
  Avatar,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Grid,
} from "@mui/material";
import LoadingSpinner from "../../Spinner/LoadingSpinner";
import moment from "moment";

export default function OngoingCo() {
  const { trainerId } = useAuth();
  const [courses, setCourses] = useState([]);
  const [learner, setLearner] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  async function SingleTrainer() {
    await axios
      .get(`${FETCH_URL}/api/learner/getSingleTrainer/${trainerId}`)
      .then((resp) => {
        let currentDate = moment().format();

        const upcomingCourses = resp?.data?.msg?.assignedCourses?.filter(
          (data) => currentDate <= moment(data?.expireOn).format()
        );

        CourseApi(upcomingCourses);
        setLearner(resp?.data?.msg);
        console.log(resp, "assignedCourses data");
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function CourseApi(courseId) {
    setIsLoading(true);
    console.log(courseId, "courseId");
    axios
      .post(`${FETCH_URL}/api/lessonplan/course/getCoursesByCourseids/`, {
        CourseIds: courseId,
      })
      .then((resp) => {
        console.log(resp, "course data");
        setCourses(resp?.data?.msg);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    SingleTrainer();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid className="bg-f8f9fd">
      {isLoading ? <LoadingSpinner /> : null}
      {courses.length == 0 ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              height: "70vh",
            }}
          >
            <span className="fs-14 text-757575">There is course assigned</span>
          </div>
        </>
      ) : (
        <>
          {courses.map((data, key) => {
            let PP = 0;
            let comp = 0;
            if (data?.isCourse == false && data?.lessonPlans?.length === 0) {
              PP = data?.assignedPP;
            } else {
              data.lessonPlans.map((data) => {
                if (data?.completion) {
                  comp++;
                }
                PP += data?.assignedPP;
              });
            }

            return (
              <>
                <Grid p={1}>
                  <Link
                    to="/student/startlevel"
                    state={{ data }}
                    key={key}
                    className="radius-10"
                  >
                    <Card sx={{ width: "100%" }} className="p-relative">
                      <Typography
                        className="p-absolute course-pp"
                        sx={{ zIndex: "300 !important" }}
                      >
                        {PP}
                        {" PP"}
                      </Typography>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="140"
                          image={
                            data?.courseThumbnail !== undefined ? (
                              data?.courseThumbnail
                            ) : (
                              <Avatar variant="rounded">
                                <HideImageIcon />
                              </Avatar>
                            )
                          }
                          alt=""
                        />
                        <CardContent p="10px">
                          <Typography
                            gutterBottom
                            className="font-w-700 cl-title"
                            component="div"
                          >
                            {data?.courseTitle}
                          </Typography>
                          <div className="artsPro">
                            <Progress
                              percent={
                                data?.lessonPlans?.length != 0
                                  ? parseInt(
                                      (comp * 100) / data?.lessonPlans?.length
                                    )
                                  : 0
                              }
                              className="progressbar"
                            />
                          </div>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Link>
                </Grid>
              </>
            );
          })}
        </>
      )}
    </Grid>
  );
}
