import { Typography, Grid, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import admin from "../../assets/img/admin.png";
import RemoveIcon from "@mui/icons-material/Remove";
import kit from "../../assets/img/kit.png";

const SendMail = () => {
  const navigate = useNavigate();
  return (
    <Grid className="bg-white h-100vh">
      <Grid container p={1}>
        <Grid item xs={12} className={"align-center d-flex"} mt={9} ml={2}>
          <RemoveIcon />{" "}
          <Typography className="font-14 font-w-700">
            Forget Password
          </Typography>
        </Grid>

        <Grid className="p-absolute" p={"16px"} right={"10px"}>
          <img src={kit} height={"108px"} />
        </Grid>

        <Grid item xs={12} p={2}>
          <Typography fontSize={"45px"} fontWeight="700">
            Forget Password
          </Typography>
        </Grid>
        <Grid item xs={12} className="justify-center">
          <img src={admin} alt="" height={"193"} />
        </Grid>

        <Grid item xs={12} p={2}>
          <Typography ml={2} className="font-14" fontWeight={"700"}>
            Steps to Reset Password
          </Typography>
          <ol>
            <li>
              <Typography className="font-14 font-w-600 color-565656">
                Mail at Support@zoommer.in with your username.
              </Typography>
            </li>
            <li>
              <Typography className="font-14 font-w-600 color-565656">
                Contact Zoommer Admin
              </Typography>
            </li>
            <li>
              <Typography className="font-14 font-w-600 color-565656">
                Contact School Admin
              </Typography>
            </li>
          </ol>
        </Grid>

        <Grid item xs={12} className="button-signIn" p={2}>
          <Button
            className="w-100 transform-none bg-D1E700 radius-15 font-24 font-w-400"
            variant="contained"
            onClick={() => navigate(-1)}
            // sx={{ p: "16px" }}
          >
            Back to sign in
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SendMail;
