import NativeHome from "./pages/DrawerComponent/Native4code/nativeHome";
import SendMail from "./pages/SignIn/SendMail";
import SignIn from "./pages/SignIn/Signin";
// import Congrat from "./pages/SignIn/Congrat";
import SignPlay from "./pages/SignIn/SignPlay";

const Authroutes = [
  {
    invisible: false,
    name: "signIn",
    link: "/signIn",
    layout: "/auth",
    Element: <SignIn />,
    activeIndex: 0,
  },
  // {
  //   invisible: true,
  //   link: "/congrat",
  //   layout: "/auth",
  //   Element: <Congrat />,
  // },
  {
    invisible: true,
    link: "/signPlay",
    layout: "/auth",
    Element: <SignPlay />,
  },
  {
    invisible: true,
    link: "/sendmail",
    layout: "/auth",
    Element: <SendMail />,
  },
  ,
  {
    invisible: true,
    link: "/nativehome",
    layout: "/auth",
    Element: <NativeHome />,
  },
];

export default Authroutes;
