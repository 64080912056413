import React from "react";
import {
  Grid,
  Typography,
  Button,
  DialogActions,
  IconButton,
} from "@mui/material";

import { useQuestion } from "../../../../../../Context/QuestionContext";
import Zoommerlogo from "../../../../../../assets/img/ZoommerWhitelogo.png";
import Instruimg from "../../../../../../assets/img/TrainerQuiz.png";
import { IoCloseSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
export default function Profile({ TabNext }) {
  return (
    <>
      <Grid container>
        <Grid
          container
          item
          style={{
            width: "100%",
            justifyContent: "flex-end",
            marginTop: "-362px",
          }}
        >
          <Link to="/student/course">
            <IconButton className="DialogCloseButton">
              <IoCloseSharp className="DialogCloseIcon" />
            </IconButton>
          </Link>
        </Grid>
        <Typography
          className="Fs-1-4 BlueColorTypography"
          style={{
            paddingLeft: "10px",
            marginTop: "10px",
          }}
        >
          Aptitude Test
        </Typography>
        <Grid container className="QuesIborder">
          <Grid item xs={12}>
            <ul>
              <Typography className="Fs-1-2  GreyColorTypography">
                Instruction:
              </Typography>
            </ul>
            <ul>
              <li>
                <Typography> Total number of questions : 13 </Typography>
              </li>
              <li>
                <Typography>
                  Each question carry 1 mark, no negative marks.
                </Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
        <Grid container className="QuesIborder">
          <Grid item xs={12}>
            <ul>
              <Typography className="Fs-1-2  GreyColorTypography">
                Note :
              </Typography>
            </ul>
            <ul>
              <li>
                <Typography>
                  Click the “Finish quiz” button given in bottom of this page to
                  submit your answer.
                </Typography>
              </li>
              <li>
                <Typography>Don’t refresh the page. </Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Grid>
      <DialogActions>
        <Grid container justifyContent="center">
          <Button
            className="GreenButton700"
            style={{
              borderRadius: "20px",
              padding: "6px 25px ",
              fontSize: "1.2rem",
              marginBottom: "19px ",
            }}
            onClick={TabNext}
          >
            {" "}
            Start Quiz
          </Button>
        </Grid>
      </DialogActions>
    </>
  );
}
