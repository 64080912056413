import React, { useState, useEffect } from "react";
import { Typography, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { FETCH_URL } from "../../../fetchIp.js";
import grey from "../../../assets/img/Rectangle 4332.png";
import moment from "moment";
import axios from "axios";

export default function BasicTabs() {
  const [eventData, setEventData] = useState([]);
  const [value, onChange] = useState(new Date());
  const [info, setInfo] = useState([]);
  const path = "student";

  const token = localStorage.getItem("loginToken");

  function upcoming(date) {
    console.log("Moment is ", moment(date).format());

    let newDate = moment(date).format("YYYY-MM-DD");
    axios
      .get(`${FETCH_URL}/api/school/event/getAllEvents?specificDate=${newDate}`)
      .then((resp) => {
        console.log("Upcomming Data is", resp.data);
        setInfo(resp.data.msg);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(async () => {
    upcoming(moment(value).format());
  }, [value]);

  return (
    <>
      <div>
        <Grid container direction="row">
          <Grid item justifyContent="center" className="EventCardGrid4Style">
            <Calendar onChange={onChange} value={value} />
          </Grid>
        </Grid>

        <div style={{ marginTop: "10px" }}>
          {info.map((item) => {
            return (
              <>
                <Link to={`/${path}/eventprofile`} state={{ item }}>
                  <div className="eve101Cont" style={{ marginBottom: "10px" }}>
                    <div className="event-date">
                      <Typography className="w-100">
                        {moment(item?.dateOfCreation).format("ddd")}
                      </Typography>{" "}
                      <Typography className="text-black ">
                        {moment(item?.dateOfCreation).format("D")}
                      </Typography>
                    </div>

                    <Grid className="p-relative">
                      <div className="eve101">
                        <h2 className="w-68-per triple-dot first-cap">
                          {item.eventName}
                        </h2>

                        <h3 className="first-cap" style={{ marginTop: "16px" }}>
                          {item?.eventDetails?.slice(0, 50)}
                        </h3>
                      </div>

                      <div className="eve101PP p-absolute top-4px right-0px">
                        <button>
                          {item?.assignedPP}
                          {" PP"}
                        </button>
                      </div>
                    </Grid>
                  </div>
                </Link>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}
