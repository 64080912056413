import React, { useEffect, useState } from "react";
import kid from "../../../assets/img/practice_win (1).png";
import { Link, useLocation } from "react-router-dom";
import { FETCH_URL } from "../../../fetchIp";
import { useAuth } from "../../../Context/AuthContext";
import axios from "axios";
import {
  Grid,
  Typography,
  Rating,
  Button,
  TextareaAutosize,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";

export default function CompScore() {
  const location = useLocation();
  const { trainerId } = useAuth();
  const [Locomotor, setLocomotor1] = useState();
  const [BodyManagement, setBodyManagement1] = useState();
  const [ObjectControl, setObjectControl1] = useState();

  const [isActive, setIsActive] = useState(false);
  const [comment, setComment] = useState([]);
  const [rating, setRating] = useState([]);
  const [feedbackLength, setFeedbackLength] = useState(0);
  const [feedback, setFeedback] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [trainer, setTrainer] = useState([]);

  let LocoSkill = 0;
  let BodySkill = 0;
  let ObjSkill = 0;
  let LocoPer = 0;
  let BodyPer = 0;
  let ObjPer = 0;

  let locoP = 0;
  let bodyP = 0;
  let objP = 0;
  console.log("State item is ", location.state);

  let resultPP = location?.state?.total;
  const stateAssignedPP = location?.state?.stateData?.lpdata;
  const actualLessonPlanLength =
    location?.state?.stateData?.actualLessonPlanLength;
  const lessonPlanLength = location?.state?.stateData?.lessonPlanLength;
  const courseData = location?.state?.stateData?.courseData;
  const lessonResId = location?.state?.stateData?.resultIdlessonCourseId;
  console.log(stateAssignedPP, "stateAssignedPP");

  const stateDataResult = location?.state?.total;
  console.log(stateDataResult, "stateDataResult");

  const [locomotor, setLocomotor] = useState({
    locopercent: 0,
    resultPP: 0,
  });
  const [bodyManagement, setBodyManagement] = useState({
    bodypercent: 0,
    resultPP: 0,
  });
  const [objectControl, setObjectControl] = useState({
    objectpercent: 0,
    resultPP: 0,
  });
  const [skillData, setSkillData] = useState();

  const getSingleTrainer = async () => {
    try {
      const resp = await axios.get(
        `${FETCH_URL}/api/learner/getSingleTrainer/${trainerId}`
      );
      setTrainer(resp?.data?.msg);

      console.log("Data is ", resp);
    } catch (err) {
      // setIsLoading(false);
    }
  };

  const submitFeedback = async (e) => {
    e.preventDefault();
    try {
      console.log("Data isssssssssssssssss: ", rating, comment);
      // return;
      const responce = await fetch(
        `${FETCH_URL}/api/lessonplan/course/giveFeedback`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            fullName: trainer?.trainerDetail?.fullName,
            email: trainer?.contactDetail?.trainerEmail,
            userId: trainerId,
            phone: trainer?.contactDetail?.trainerPhoneNo,
            courseId: courseData?._id,
            feedback: [
              {
                feedbackRating: rating,
                feedbackAns: comment,
              },
            ],
          }),
        }
      );
      const res = await responce.json();
      setIsShow(false);
      console.log("Response is : ", res);
    } catch (err) {
      console.log("Error is ", err);
    }
  };

  function skills() {
    axios
      .get(`${FETCH_URL}/api/assessment/getResultByResultId/${lessonResId}`)
      .then((resp) => {
        console.log(resp?.data?.data, "skills data");
        setSkillData(resp?.data?.data);

        resp?.data?.data?.skillScore.map((item) => {
          if (item?.mainSkill == "Body Management") {
            bodyP = bodyP + +item?.points;
            setBodyManagement({
              bodypercent: item?.percent,
              resultPP: bodyP,
            });
          } else if (item?.mainSkill == "Locomotor") {
            locoP = locoP + +item?.points;
            setLocomotor({ locopercent: item?.percent, resultPP: locoP });
          } else if (item?.mainSkill == "Object Control") {
            objP = objP + +item?.points;
            setObjectControl({
              objectpercent: item?.percent,
              resultPP: objP,
            });
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });

    // axios
    //   // .post(`${FETCH_URL}/api/assessment/getResultByLessonPlanIds`, {
    //   .post(`${FETCH_URL}/api/assessment/getResultByResultId`, {
    //     userId: trainerId,
    //     lessonPlanIds: [{ lessonPlanId: stateAssignedPP?._id }],
    //   })
    //   .then((resp) => {
    //     console.log("skills data", resp.data.msg);

    //     setSkillData(resp.data.msg);
    //     resp?.data?.msg?.[0]?.skillScore?.map((item) => {
    //       if (item?.mainSkill == "Body Management") {
    //         bodyP = bodyP + +item?.points;
    //         setBodyManagement({
    //           bodypercent: item.percent,
    //           resultPP: bodyP,
    //         });
    //       } else if (item.mainSkill == "Locomotor") {
    //         locoP = locoP + +item?.points;
    //         setLocomotor({ locopercent: item?.percent, resultPP: locoP });
    //       } else if (item?.mainSkill == "Object Control") {
    //         objP = objP + +item?.points;
    //         setObjectControl({
    //           objectpercent: item?.percent,
    //           resultPP: objP,
    //         });
    //       }
    //     });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }

  const skillData1 = () => {
    stateAssignedPP?.skills?.map((data) => {
      if (data.mainSkill == "Body Management") {
        BodySkill++;
        BodyPer += parseInt(data.percent);
      }
      if (data.mainSkill == "Object Control") {
        ObjSkill++;
        ObjPer += parseInt(data.percent);
      }
      if (data.mainSkill == "Locomotor") {
        LocoSkill++;
        LocoPer += parseInt(data.percent);
      }
    });
    if (LocoPer !== 0) {
      LocoPer = LocoPer / LocoSkill;
      setLocomotor1(LocoPer);
    }
    if (BodyPer !== 0) {
      BodyPer = BodyPer / BodySkill;
      setBodyManagement1(BodyPer);
    }

    if (ObjPer !== 0) {
      ObjPer = ObjPer / ObjSkill;
      setObjectControl1(ObjPer);
    }
  };

  // skillData?.map((item) => {
  //   resultPP = item.resultPP;
  // });

  const setRatingCommentValue = (value, item) => {
    let flag = false;

    if (item?.feedbackType == 1) {
      console.log("Rating : ", rating, item);
      rating.map((data, i) => {
        if (data?._id == item?._id) {
          flag = true;
          rating[i].rating = +value;
        }
      });

      if (!flag) {
        item.rating = +value;

        setRating((arr) => [...arr, item]);
      }
    } else if (item?.feedbackType == 2) {
      comment.map((data, i) => {
        if (data?._id == item?._id) {
          flag = true;

          comment[i].comment = value;
        }
      });

      if (!flag) {
        item.comment = value;
        setComment((arr) => [...arr, item]);
      }
    }
  };

  useEffect(() => {
    skills();
    skillData1();
    getSingleTrainer();
  }, []);

  useEffect(() => {
    if (actualLessonPlanLength == lessonPlanLength) {
      setIsActive(true);
      setIsShow(true);
      setFeedbackLength(courseData?.feedback?.length);
      setFeedback(courseData?.feedback);
    } else {
      setIsActive(false);
    }
  }, []);

  useEffect(() => {
    if (window.ReactNativeWebView) window.ReactNativeWebView.postMessage(3);
  }, []);

  return (
    <>
      <Grid
        container
        className={
          !isActive ||
          feedbackLength == 0 ||
          feedbackLength == undefined ||
          !isShow
            ? "d-none"
            : "rating-area"
        }
      >
        <form onSubmit={submitFeedback}>
          <Grid
            container
            overflow={"hidden"}
            className="radius-10"
            sx={{
              marginLeft: "auto",
              marginRight: "auto",

              width: "90%",
            }}
          >
            <DialogTitle className="bg-white" sx={{ width: "92%" }}>
              <Grid textAlign="center" className="bg-white w-100">
                Feedback
              </Grid>
            </DialogTitle>
            <DialogContent
              className="bg-white w-100 d-flex-center "
              sx={{ padding: "0px" }}
            >
              <Grid className="rating-area-feedback" pb={1}>
                {feedback.map((item, key) => {
                  return (
                    <Grid
                      p={2}
                      mb={1}
                      overflow={"hidden"}
                      position={"relative"}
                      width={"100%"}
                      textAlign={"center"}
                    >
                      <Grid className="scrollY">
                        {item?.feedbackType == 1 ? (
                          <Grid>
                            <Typography fontWeight={"700"}>
                              {item?.feedbackQuery}
                            </Typography>
                            <Grid textAlign="center" mb={1}>
                              <Rating
                                name="size-large"
                                defaultValue={5}
                                size="large"
                                className="rating-start"
                                onChange={(e) =>
                                  setRatingCommentValue(e.target.value, item)
                                }
                              />
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid mb={2} textAlign={"center"}>
                            <Typography className="font-w-700" mb={1}>
                              {item?.feedbackQuery}
                            </Typography>
                            <TextareaAutosize
                              aria-label="minimum height"
                              className="rating-text-area"
                              onChange={(e) =>
                                setRatingCommentValue(e.target.value, item)
                              }
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </DialogContent>
            <DialogActions
              className="w-100"
              sx={{ background: "white", padding: "0px" }}
            >
              <Grid container justifyContent="space-between">
                <Button
                  variant="contained"
                  sx={{ width: "100%" }}
                  className="bg-5FA12F transform-none"
                  type="submit"
                >
                  Submit
                </Button>
                <Button
                  sx={{ width: "100%" }}
                  className="text-90C251 transform-none"
                  onClick={() => setIsShow(false)}
                >
                  Skip
                </Button>
              </Grid>{" "}
            </DialogActions>
          </Grid>
        </form>
      </Grid>
      <div className="baccha bg-333333">
        <div className="bacchaImg">
          <img src={kid} alt="" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            position: "absolute",
            top: "40%",
          }}
        >
          <div className="resultPP">
            <h3>
              {/* {stateDataResult}/{stateAssignedPP?.LPXp} PP */}
              {resultPP?.toFixed()}/{stateAssignedPP?.LPXp} PP
            </h3>
            <h4>Play Points</h4>
          </div>
          <div className="resultScore">
            <h2>
              {/* {stateDataResult}/{stateAssignedPP?.LPXp} PP */}
              {resultPP?.toFixed()}/{stateAssignedPP?.LPXp} PP
            </h2>
            <h5>Score</h5>
          </div>
          <hr className="wid" />

          <div className="completeGrid">
            <div className="resultValue">
              <h3>
                Locomotor&nbsp;{" "}
                {Locomotor == undefined || isNaN(Locomotor) ? "0" : Locomotor}%
              </h3>
              <h3>
                Body Management&nbsp;
                {BodyManagement == undefined || isNaN(BodyManagement)
                  ? "0"
                  : BodyManagement}
                %
              </h3>
              <h3>
                Object Control&nbsp;
                {ObjectControl == undefined || isNaN(ObjectControl)
                  ? "0"
                  : ObjectControl}
                %
              </h3>
              {console.log("Dataiiiiiiiiiiii is ", {
                Locomotor,
                BodyManagement,
                ObjectControl,
              })}
            </div>
            <div className="resultPPs">
              <div className="resultPPs">
                <h3>
                  {isNaN(locomotor.resultPP) ? "0" : locomotor.resultPP} PP
                </h3>
                <h3>
                  {isNaN(bodyManagement.resultPP)
                    ? "0"
                    : bodyManagement.resultPP}{" "}
                  PP
                </h3>
                <h3>
                  {isNaN(objectControl.resultPP) ? "0" : objectControl.resultPP}{" "}
                  PP
                </h3>
              </div>

              {/* <h3>{locomotor?.resultPP} PP</h3>
              <h3>{bodyManagement?.resultPP} PP</h3>
              <h3>{objectControl?.resultPP} PP</h3> */}
            </div>
          </div>
          <Link to="/student/courses">
            <div className="resultPPBtn">
              <button>Continue</button>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}
