import React, { useRef, useEffect, useState } from "react";
import Navbar from "../../Navbar/Navbar";
import { Grid, Typography, CardMedia, CardContent, Card } from "@mui/material";
import rank from "../../../assets/img/rank2.png";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useAuth } from "../../../Context/AuthContext";
import { FETCH_URL } from "../../../fetchIp";
import kidsPlayings2 from "../../../assets/img/kidsPlaying2.png";
import "./home.css";
import PropTypes from "prop-types";
import { Tab, Box, Tabs, DialogContent } from "@mui/material";
import axios from "axios";
import LoadingSpinner from "../../Spinner/LoadingSpinner";
import leaderboardImg from "../../../assets/img/leader.png";
import { Stack, Avatar } from "@mui/material";
import { RxAvatar } from "react-icons/rx";
import moment from "moment";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Home() {
  const [courseData, setCourseData] = useState([]);
  const [grades, setGrades] = useState([]);
  const [pp, setPp] = useState(0);
  const [value, setValue] = React.useState(0);
  const [trlessonplandata, setTrlessonplandata] = useState([]);
  const [leaderBoard, setLeaderBoard] = useState([]);
  // const [trainerData, setTrainerData] = useState();
  const focusDiv = useRef();
  const { userDetails, trainerId } = useAuth();
  const [courses, setCourses] = useState([]);
  const [daily, setDaily] = useState(true);
  const [weekly, setWeekly] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getSingleTrainerComplete();
    getGrades();
    getPP();

    window.scrollTo(0, 0);
    leader(null, null, false);
  }, []);

  const TabChange = (event, newValue) => {
    setValue(newValue);
    // if (newValue === 0) {
    //   setDaily(true);
    //   setWeekly(null);
    //   getLeaderBoard();
    // } else if (newValue === 1) {
    //   setDaily(null);
    //   setWeekly(true);
    //   getLeaderBoard();
    // } else {
    //   setDaily(null);
    //   setWeekly(null);
    //   getLeaderBoard();
    // }
  };

  const changeTab = (tab) => {
    if (tab == 2) {
      leader("daily", null);
    } else if (tab == 1) {
      leader(null, "weekly");
    } else if (tab == 0) {
      leader();
    }
  };

  function leader(daily = null, weekly = null, status = true) {
    setIsLoading(true);
    console.log(
      `api/assessment/leaderboard/total/learner?daily=${daily}&weekly=${weekly}`
    );

    axios
      .get(
        `${FETCH_URL}/api/assessment/leaderboard/trainer?daily=${daily}&weekly=${weekly}`
      )
      .then((resp) => {
        console.log(resp.data.agreg, "leaderboard data");
        setLeaderBoard(resp.data.agreg);

        if (status) setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  const getPP = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/assessment/getPPByTrainer/${trainerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setPp(res.totalPP);
        console.log("Total PP =======>", res.totalPP);
      } else {
        console.error("API error =====>", res.err);
      }
    } catch (error) {
      setIsLoading(false);

      console.log("Catch block ====>", error);
    }
  };

  const getGrades = async () => {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getTrainerGrades/${trainerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setGrades(res.msg);
        console.log("Grade =======>", res.msg);
      } else {
        console.error("API error =====>", res.err);
      }
    } catch (error) {
      setIsLoading(false);

      console.log("Catch block ====>", error);
    }
  };

  const getLessonPlanByLessonPlanIdsComplete = async (lessonPlanIds) => {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/getLessonPlanByLessonPlanids`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({ lessonPlanIds }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        setTrlessonplandata(res.msg);
        console.log("Learners Data =======>", res.msg);
      } else {
        console.error("API error =====>", res.err);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("Catch block ====>", error);
    }
  };

  const getSingleTrainerComplete = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/getSingleTrainer/${trainerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const res = await response.json();
      if (response.ok) {
        const currentDate = moment().format();

        const upcomingTRLesson = res?.msg?.assignedLessonPlans?.filter(
          (data) => currentDate <= moment(data?.expireOn).format()
        );

        const upcomingCourses = res?.msg?.assignedCourses?.filter(
          (data) => currentDate <= moment(data?.expireOn).format()
        );

        CourseApi(upcomingCourses);
        getLessonPlanByLessonPlanIdsComplete(upcomingTRLesson);

        console.log("LessonPlan Data =======>", res.msg);
        console.log("LessonPlan Data =======>", upcomingTRLesson);
      } else {
        console.error("API error =====>", res.err);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Catch block ====>", error);
    }
  };

  function CourseApi(courseId) {
    setIsLoading(true);

    console.log(courseId, "courseId");
    axios
      .post(`${FETCH_URL}/api/lessonplan/course/getCoursesByCourseids/`, {
        CourseIds: courseId,
      })
      .then((resp) => {
        console.log(resp, "course data");
        setCourses(resp.data.msg);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  console.log(courses, "courses data");
  console.log(trlessonplandata, "upcoming lessonplandata all details");

  let findObj;
  findObj = leaderBoard.filter((item) => {
    return item.trainerName === userDetails?.trainerDetail?.fullName;
  });

  // console.log(findObj, "findObj");

  return (
    <>
      {isLoading ? <LoadingSpinner /> : null}
      <Navbar />
      <Grid container className="" ref={focusDiv} p={1} mt={8}>
        <Grid container className="space-between">
          <Typography className="GreenColorTypography Hhiitypo w-72-per triple-dot first-cap">
            Hi, {userDetails?.trainerDetail?.fullName}
          </Typography>
          <Typography className="GreenColorTypoButton  Hpptypo">
            {findObj[0]?.total === undefined || null ? "0" : findObj[0]?.total}{" "}
            PP
          </Typography>
        </Grid>

        <Grid container item marginTop="25px" className="space-between-center">
          <Typography className="GreyColorTypography Hviewtypo color-3a3a3b">
            Courses
          </Typography>
          {courses.length > 0 ? (
            <Link to="/student/courses">
              <Typography
                className="GreenColorTypography Fs-0-8"
                style={{
                  alignSelf: "center",
                  paddingLeft: "14px",
                  alignItems: "center",
                }}
              >
                See More
              </Typography>
            </Link>
          ) : null}
        </Grid>

        {courses.length == 0 ? (
          <Grid height={"153px"} className={"w-100 space-between-center"}>
            <Typography
              className={"w-100 text-center text-b2b1b2 family-calibri"}
              fontWeight={"600"}
            >
              No courses available
            </Typography>
          </Grid>
        ) : (
          <>
            <div className="gameCard">
              <Grid display={"flex"}>
                {courses.map((item, key) => {
                  let PP = 0;
                  item.lessonPlans.map((data, i) => {
                    PP += data.assignedPP;
                  });
                  return (
                    <>
                      <Link
                        to="/student/startlevel"
                        state={{ data: item }}
                        key={key}
                        className={
                          "radius-10 overflow-hidden border-dddddd m-0-4px"
                        }
                      >
                        <Card
                          key={item?._id}
                          className={
                            "h-180 w-236px radius-10 border-E4E6EF mr-10"
                          }
                        >
                          <Grid>
                            <CardMedia
                              className="border-bottom-e3e3e3 h-138px"
                              image={item?.courseThumbnail}
                              title="green iguana"
                            />
                          </Grid>
                          <CardContent
                            sx={{ p: "10px" }}
                            className="space-between-center border-top"
                          >
                            <Typography
                              className="text-black w-55-per triple-dot"
                              fontWeight={"700"}
                            >
                              {item?.courseTitle?.slice(0, 20)}
                            </Typography>
                            <Typography
                              className="text-black"
                              fontWeight={"700"}
                            >
                              {/* {item?.assignedPP} */}
                              {PP}
                              {" PP"}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Link>
                    </>
                  );
                })}
              </Grid>
            </div>
          </>
        )}

        <Grid container item justifyContent="space-between" marginTop="20px">
          <Typography className="GreyColorTypography Hviewtypo color-3a3a3b">
            Grades
          </Typography>
          {grades.length > 0 ? (
            <Link to="/student/classes">
              <Typography
                alignSelf="center"
                className="GreenColorTypography Hviewtypo"
                fontSize={"12px !important"}
              >
                See More
              </Typography>
            </Link>
          ) : null}
        </Grid>

        {grades.length == 0 ? (
          <>
            <Grid height={"153px"} className={"w-100 space-between-center"}>
              <Typography
                className={"w-100 text-center text-b2b1b2 family-calibri"}
                fontWeight={"600"}
              >
                No Grade has been assigned
              </Typography>
            </Grid>
          </>
        ) : (
          <div container p={1} className="gameCard">
            {grades.slice(0, 4).map((item) => {
              return (
                <Grid
                  key={item?._id}
                  p={0.6}
                  mb={1}
                  className="min-width-180px"
                >
                  <Grid className={"assessment-css assessment-font-14px"}>
                    <Link to="/student/classesuser" state={item}>
                      <Grid className="space-between pb-8px">
                        <Typography className="color-6f6f6f font-w-700">
                          Grade
                        </Typography>
                        <Typography className={"color-black font-w-700"}>
                          {item?.grade}
                        </Typography>
                      </Grid>

                      <Grid className="space-between pb-8px">
                        <Typography className="color-6f6f6f font-w-700">
                          Division
                        </Typography>
                        <Typography className={"color-black font-w-700"}>
                          {item?.divisions.length}
                        </Typography>
                      </Grid>

                      <Grid className="space-between pb-8px">
                        <Typography className="color-6f6f6f font-w-700">
                          Student
                        </Typography>
                        <Typography className={"color-black font-w-700"}>
                          {item?.assignedLearners?.length}
                        </Typography>
                      </Grid>
                    </Link>
                  </Grid>
                </Grid>
              );
            })}
          </div>
        )}

        <Grid container item marginTop="5px" className="perSee">
          <Typography className="GreyColorTypography Hviewtypo color-3a3a3b">
            Training
          </Typography>
          {trlessonplandata.length > 0 ? (
            <Link to="/student/trainings">
              <Typography
                className="GreenColorTypography Fs-0-8"
                style={{
                  alignSelf: "center",
                  paddingLeft: "14px",
                  alignItems: "center",
                }}
              >
                See More
              </Typography>
            </Link>
          ) : null}
        </Grid>

        {trlessonplandata.length == 0 ? (
          <>
            <Grid height={"153px"} className={"w-100 space-between-center"}>
              <Typography
                className={"w-100 text-center text-b2b1b2 family-calibri"}
                fontWeight={"600"}
              >
                No Training has been assigned
              </Typography>
            </Grid>
            {/* <Grid container className="">
              <Typography
                align="center"
                className="GreenButton700 ViewCourseButton"
              >
                No Training has been assigned
              </Typography>
            </Grid> */}
          </>
        ) : (
          <>
            <div className="gameCard">
              <Grid display={"flex"}>
                {trlessonplandata.map((user, key) => (
                  <Link
                    to="/student/trainingstart"
                    state={user}
                    key={key}
                    className={"radius-10 overflow-hidden border-dddddd mr-8px"}
                  >
                    <Card
                      key={user?._id}
                      className={"h-180 w-236px radius-10 border-E4E6EF mr-10"}
                    >
                      <Grid>
                        <CardMedia
                          className="border-bottom-e3e3e3 h-138px"
                          image={user?.LPThumbnail}
                          title="green iguana"
                        />
                      </Grid>
                      <CardContent
                        sx={{ p: "10px" }}
                        className="space-between-center border-top"
                      >
                        <Typography
                          className="text-black w-55-per triple-dot"
                          fontWeight={"700"}
                        >
                          {user?.LPTitle?.slice(0, 20)}
                        </Typography>
                        <Typography className="text-black" fontWeight={"700"}>
                          {user?.LPXp}
                          {" PP"}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Link>
                ))}
              </Grid>
            </div>
          </>
        )}

        <Grid container item marginTop="15px" className="perSee">
          <Typography className="GreyColorTypography Hviewtypo color-3a3a3b">
            Leaderboard
          </Typography>
          <Link to="/student/leaderboardnew">
            <Typography
              className="GreenColorTypography Fs-0-7"
              style={{
                alignSelf: "center",
                paddingLeft: "14px",
                alignItems: "center",
              }}
            >
              See More
            </Typography>
          </Link>
        </Grid>
        <Grid
          container
          item
          style={{
            justifyContent: "center",
            backgroundColor: "#cddc39",
            borderRadius: "10px",
            marginTop: "5px",
            paddingBottom: "12%",
            paddingTop: "12px",
          }}
        >
          <Grid item>
            <div style={{ position: "relative" }} className="AllGP">
              <Link to="/student/leaderboardnew">
                <img src={leaderboardImg} alt="" height={"316px"} />
              </Link>

              <Stack direction="row" spacing={2}>
                <Avatar
                  className="first-avatar"
                  alt={
                    leaderBoard[1]?.userImg == undefined
                      ? leaderBoard[1]?.trainerName?.split(" ")[0]
                      : leaderBoard[1]?.userImg
                  }
                  src={
                    leaderBoard[1]?.userImg == undefined
                      ? RxAvatar
                      : leaderBoard[1]?.userImg
                  }
                  sx={{ height: "50px", width: "50px", fontSize: "25px" }}
                />
              </Stack>

              <Stack direction="row" spacing={2}>
                <Avatar
                  className="second-avatar"
                  alt={
                    leaderBoard[0]?.userImg == undefined
                      ? leaderBoard[0]?.trainerName?.split(" ")[0]
                      : leaderBoard[0]?.userImg
                  }
                  src={
                    leaderBoard[0]?.userImg == undefined
                      ? RxAvatar
                      : leaderBoard[0]?.userImg
                  }
                  sx={{ height: "50px", width: "50px", fontSize: "30px" }}
                />
              </Stack>

              <Stack direction="row" spacing={2}>
                <Avatar
                  className="third-avatar"
                  alt={
                    leaderBoard[2]?.userImg == undefined
                      ? leaderBoard[2]?.trainerName?.split(" ")[0]
                      : leaderBoard[2]?.userImg
                  }
                  src={
                    leaderBoard[2]?.userImg == undefined
                      ? RxAvatar
                      : leaderBoard[2]?.userImg
                  }
                  sx={{ height: "50px", width: "50px", fontSize: "25px" }}
                />
              </Stack>
              <div
                style={{
                  position: "absolute",
                  top: "-4px",

                  backgroundColor: "white",
                }}
              >
                <Tabs
                  value={value}
                  onChange={TabChange}
                  aria-label="basic tabs example"
                  className="AResultTabStyle"
                >
                  <Tab
                    className="AResultTabChanges"
                    label="All Time"
                    onClick={() => changeTab(0)}
                  />
                  <Tab
                    className="AResultTabChanges"
                    label="This Week"
                    onClick={() => changeTab(1)}
                  />
                  <Tab
                    className="AResultTabChanges"
                    label="Today"
                    onClick={() => changeTab(2)}
                  />
                </Tabs>

                <TabPanel value={value} index={0}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                  >
                    <div className="GP0">
                      {!isNaN(leaderBoard[0]?.total)
                        ? leaderBoard[0]?.total?.toFixed() + " PP"
                        : ""}
                    </div>
                    <div className="GP1">
                      {!isNaN(leaderBoard[1]?.total)
                        ? leaderBoard[1]?.total?.toFixed() + " PP"
                        : ""}
                    </div>
                    <div className="GP2">
                      {!isNaN(leaderBoard[2]?.total)
                        ? leaderBoard[2]?.total?.toFixed() + " PP"
                        : ""}
                    </div>
                    <div className="name0">
                      {leaderBoard[1]?.trainerName?.split(" ")[0].slice(0, 10)}
                    </div>
                    <div className="name1">
                      {leaderBoard[0]?.trainerName?.split(" ")[0].slice(0, 10)}
                    </div>
                    <div className="name2">
                      {leaderBoard[2]?.trainerName?.split(" ")[0].slice(0, 10)}
                    </div>
                  </Grid>
                </TabPanel>

                <TabPanel value={value} index={1}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                  >
                    <div className="GP0">
                      {!isNaN(leaderBoard[0]?.total)
                        ? leaderBoard[0]?.total?.toFixed() + " PP"
                        : ""}
                    </div>
                    <div className="GP1">
                      {!isNaN(leaderBoard[1]?.total)
                        ? leaderBoard[1]?.total?.toFixed() + " PP"
                        : ""}
                    </div>
                    <div className="GP2">
                      {!isNaN(leaderBoard[2]?.total)
                        ? leaderBoard[2]?.total?.toFixed() + " PP"
                        : ""}
                    </div>
                    <div className="name0">
                      {leaderBoard[1]?.trainerName?.split(" ")[0].slice(0, 10)}
                    </div>
                    <div className="name1">
                      {leaderBoard[0]?.trainerName?.split(" ")[0].slice(0, 10)}
                    </div>
                    <div className="name2">
                      {leaderBoard[2]?.trainerName?.split(" ")[0].slice(0, 10)}
                    </div>
                  </Grid>
                </TabPanel>

                <TabPanel value={value} index={2}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                  >
                    <div className="GP0">
                      {!isNaN(leaderBoard[0]?.total)
                        ? leaderBoard[0]?.total?.toFixed() + " PP"
                        : ""}
                    </div>
                    <div className="GP1">
                      {!isNaN(leaderBoard[1]?.total)
                        ? leaderBoard[1]?.total?.toFixed() + " PP"
                        : ""}
                    </div>
                    <div className="GP2">
                      {!isNaN(leaderBoard[2]?.total)
                        ? leaderBoard[2]?.total?.toFixed() + " PP"
                        : ""}
                    </div>
                    <div className="name0">
                      {leaderBoard[1]?.trainerName?.split(" ")[0].slice(0, 10)}
                    </div>
                    <div className="name1">
                      {leaderBoard[0]?.trainerName?.split(" ")[0].slice(0, 10)}
                    </div>
                    <div className="name2">
                      {leaderBoard[2]?.trainerName?.split(" ")[0].slice(0, 10)}
                    </div>

                    {/* <div className="GP0">{leaderBoard[0]?.total} PP</div>
                    <div className="GP1">{leaderBoard[1]?.total} PP</div>
                    <div className="GP2">{leaderBoard[2]?.total} PP</div>
                    <div className="name0">
                      {leaderBoard[1]?.trainerName.split(" ")[0]}
                    </div>
                    <div className="name1">
                      {leaderBoard[0]?.trainerName.split(" ")[0]}
                    </div>
                    <div className="name2">
                      {leaderBoard[2]?.trainerName.split(" ")[0]}
                    </div> */}
                  </Grid>
                </TabPanel>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          item
          style={{
            backgroundColor: "#ffffff",
            borderRadius: "10px",
            marginLeft: "24px",
            width: "86%",
            padding: "15px 0px",
            marginTop: "-28px",
          }}
          justifyContent="space-evenly"
        >
          <Typography style={{ paddingRight: "20px" }}>
            {findObj[0]?.rank === undefined || null
              ? "0"
              : findObj[0]?.rank + 1}
          </Typography>
          <Typography>
            {userDetails?.trainerDetail?.fullName.split(" ")[0]}
          </Typography>
          <Typography style={{ paddingLeft: "20px" }}>
            {findObj[0]?.total === undefined || null ? "0" : findObj[0]?.total}{" "}
            PP
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
