import React from "react";
import { Grid, Typography } from "@mui/material";
// import { Bar } from "react-chartjs-2";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";
export default function signin() {
  //   const options = {
  //     responsive: true,
  //     plugins: {
  //       legend: {
  //         position: "top",
  //       },
  //       title: {
  //         display: true,
  //         text: "Chart.js Bar Chart",
  //       },
  //     },
  //   };

  //   const labels = [
  //     "Jan",
  //     "Feb",
  //     "March",
  //     "April",
  //     "May",
  //     "June",
  //     "July",
  //     "Aug",
  //     "Sep",
  //     " Oct",
  //     "Nov",
  //     "Dec",
  //   ];

  //   const data = {
  //     labels,
  //     datasets: [
  //       {
  //         label: "Locomotor",
  //         data: [11, 2, 3, 11, 5, 6, 7, 8, 9, 10, 11, 12],
  //         backgroundColor: "rgb(71, 209, 71)",
  //       },
  //       {
  //         label: "Body Management",
  //         data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 10, 11],
  //         backgroundColor: "rgb(0, 172, 230)",
  //       },
  //       {
  //         label: "Object Control",
  //         data: [1, 2, 3, 4, 1, 11, 7, 8, 9, 10, 11, 12],
  //         backgroundColor: "rgb(255, 153, 0)",
  //       },
  //     ],
  //   };
  return (
    <>
      <Grid container>
        <Grid container item>
          <Grid container item xs={3} alignItems="center">
            <Typography className="GreyColorTypography" fontSize="12px">
              ACT-101
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography className=" GreyColorTypography" fontSize="12px">
              Toss the smiley upwards with both hands and catch with both
              handsas it comes down
            </Typography>
          </Grid>
          <Grid container item className="RSRCQGridStyle">
            <Grid container item xs={12}>
              <Grid item xs={4}>
                <Grid xs={9} item justifyContent="flex-start">
                  <Typography
                    align="center"
                    className="DarkGreyColorTypo"
                    fontSize="12px"
                  >
                    Q1 : 32
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  align="center"
                  className="DarkGreyColorTypo"
                  fontSize="12px"
                >
                  Q2 : 40 (+30%)
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Grid container item justifyContent="flex-end">
                  <Typography
                    align="center"
                    className="DarkGreyColorTypo"
                    fontSize="12px"
                  >
                    Q3 : 50 (+30%)
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} className="RSRCQGridStyle">
              <Grid item xs={4}>
                <Grid container item justifyContent="flex-start">
                  <Typography align="center" className="RSRCQDatatyle">
                    40/50
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={4} alignItems="center" alignSelf="center">
                <Typography
                  align="center"
                  className="DarkGreyColorTypo fS-12px"
                >
                  FMS
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Grid container item justifyContent="flex-end">
                  <Typography align="center" className="RSRCQ3Datatyle">
                    80/100 PP
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} className="RSRCQGridStyle">
              <Grid item xs={4} alignSelf="center">
                <Grid xs={9} item justifyContent="flex-start">
                  <Typography
                    align="center"
                    className="DarkGreyColorTypo  fS-12px"
                  >
                    Score
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={4} alignSelf="center">
                <Typography
                  align="center"
                  className="DarkGreyColorTypo  fS-12px"
                >
                  Skills
                </Typography>
              </Grid>
              <Grid item xs={4} alignSelf="center">
                <Grid container item justifyContent="flex-end">
                  <Typography
                    align="center"
                    className="DarkGreyColorTypo  fS-12px"
                  >
                    Play Points
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} className="RSRCQGridStyle">
              <Grid item xs={4}>
                <Grid xs={8} item justifyContent="flex-start">
                  <Typography align="center" className="RSRCQDatatyle">
                    6
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={4} alignItems="center" alignSelf="center">
                <Typography
                  align="center"
                  className="DarkGreyColorTypo fS-12px"
                >
                  Throwing (35%)
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Grid container item justifyContent="flex-end">
                  <Typography align="center" className="RSRCQ3-1Datatyle">
                    28 PP
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} className="RSRCQGridStyle">
              <Grid item xs={4}>
                <Grid xs={8} item justifyContent="flex-start">
                  <Typography align="center" className="RSRCQ-1Datatyle">
                    14
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={4} alignItems="center" alignSelf="center">
                <Typography
                  align="center"
                  className="DarkGreyColorTypo fS-12px"
                >
                  Catching (50%)
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Grid container item justifyContent="flex-end">
                  <Typography align="center" className="RSRCQ3-1Datatyle">
                    40 PP
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} className="RSRCQGridStyle">
              <Grid item xs={4}>
                <Grid xs={8} item justifyContent="flex-start">
                  <Typography align="center" className="RSRCQ-1Datatyle">
                    20
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={4} alignItems="center" alignSelf="center">
                <Typography
                  align="center"
                  className="DarkGreyColorTypo fS-12px"
                >
                  Balancing (15%)
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Grid container item justifyContent="flex-end">
                  <Typography align="center" className="RSRCQ3-1Datatyle">
                    12 PP
                  </Typography>{" "}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            md={10}
            sm={10}
            xs={10}
            lg={10}
            style={{ marginLeft: "100px" }}
          >
            {/* <Bar options={options} data={data} /> */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
