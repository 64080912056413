import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// import card from "../../../assets/img/Rectangle 2934.png";
import { Progress } from "react-sweet-progress";
import { FETCH_URL } from "../../../fetchIp";
import { useAuth } from "../../../Context/AuthContext";
import moment from "moment";
import LoadingSpinner from "../../Spinner/LoadingSpinner";
import {
  Grid,
  Typography,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@mui/material";

export default function CompletedTR() {
  const [lessonPlanDataResult, setLessonPlanDataResult] = useState([]);
  const { trainerId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  const getLessonPlanByLessonPlanIdsforResult = async (lessonPlanIds) => {
    try {
      let lpOlddata;
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/getLessonPlanByLessonPlanids`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({ lessonPlanIds }),
        }
      );
      const res = await response.json();

      if (response.ok) {
        lpOlddata = res.msg;
        console.log("Learners Data =======>", res?.msg);
      } else {
        console.error("API error =====>", res.err);
      }

      const response2 = await fetch(
        `${FETCH_URL}/api/assessment/getResultByLessonPlanIds`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({ lessonPlanIds, userId: trainerId }),
        }
      );

      const res2 = await response2.json();
      if (response2.ok) {
        for (let i = 0; i < res?.msg?.length; i++) {
          let lpdata = res2.msg.find(
            (data) => data.lessonPlanId === res?.msg[i]?._id
          );
          console.log("lpData", lpdata);
          if (lpdata) {
            lpOlddata[i].assignedPP = lpdata.assignedPP;
            lpOlddata[i].resultPP = lpdata.resultPP;
            lpOlddata[i].totalScore = lpdata.totalScore;
          }
        }

        console.log("Learners Data =======>", res.msg);
      } else {
        console.error("API error =====>", res.err);
      }

      setIsLoading(false);
      setLessonPlanDataResult(lpOlddata);
    } catch (error) {
      setIsLoading(false);
      console.log("Catch block ====>", error);
    }
  };

  const getSingleTrainerforResult = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/getSingleTrainer/${trainerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        let currentDate = moment().format();
        const completedLesson = res?.msg?.assignedLessonPlans?.filter(
          (data) => currentDate > moment(data?.expireOn).format()
        );

        await getLessonPlanByLessonPlanIdsforResult(completedLesson);

        console.log("LessonPlan Data =======>", res.msg);
        console.log("upcoming Lesson plan =======>", completedLesson);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
      setIsLoading(false);
    }
  };

  useEffect(async () => {
    getSingleTrainerforResult();
  }, []);

  console.log(lessonPlanDataResult, "result for Training");

  return (
    <>
      {isLoading ? <LoadingSpinner /> : null}
      {lessonPlanDataResult.length == 0 && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              height: "70vh",
            }}
          >
            <span className="fs-14 text-757575">
              There is no any completed lessonplan
            </span>
          </div>
        </>
      )}
      {lessonPlanDataResult?.map((user, key) => {
        return (
          <>
            <Grid p={1}>
              <Link to="/student/trainingcomp" key={key} state={user}>
                <Card sx={{ width: "100%" }} className="p-relative">
                  <Typography className="p-absolute course-pp zIndex-10">
                    {user?.LPXp} PP
                  </Typography>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="140"
                      image={user?.LPThumbnail}
                      alt=""
                    />
                    <CardContent p="10px">
                      <Typography
                        gutterBottom
                        className="font-w-700 cl-title"
                        component="div"
                      >
                        {user?.LPTitle}
                      </Typography>
                      <div className="artsPro">
                        <Progress
                          percent={user?.completion ? 100 : 0}
                          className="progressbar"
                        />
                      </div>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
            </Grid>
            ;
          </>
        );
      })}
    </>
  );
}
