import React, { useState, useEffect } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar } from "react-circular-progressbar";
import Male from "../../../assets/img/Pexels Photo by Pixabay.png";
import { FETCH_URL } from "../../../fetchIp";
import { useAuth } from "../../../Context/AuthContext";
import AssementButtonCardTwo from "./AssementButtonCardTwo";
import ResultButtonCardTwo from "./ResultButtonCardTwo";
import AssesmentUser from "./AssesmentUser";
import moment from "moment";
import Stack from "@mui/material/Stack";
// import avatar from "../../DrawerComponent/Co"
import { RxAvatar } from "react-icons/rx";
import Avatar from "@mui/material/Avatar";

export default function AssesmentNewStatus() {
  const navigate = useNavigate();

  const location = useLocation();
  const data = location?.state;

  const user = data?.user;
  // console.log(user, "userdata");

  const AssesData = data?.AssessmentData;
  // console.log(AssesData, "assesmentdata");

  const [resultId, setResultId] = useState("");
  const [assessmentData, setAssessmentData] = useState();
  const [resultData, setResultData] = useState();

  const [score, setScore] = useState(0);
  const [totalScore, setTotalScore] = useState(0);
  const [percentage, setPercentage] = useState(0);

  let view;

  useEffect(() => {
    const getResultId = async () => {
      try {
        const Result = await fetch(`${FETCH_URL}/api/assessment/createResult`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            userId: user?._id,
            name: user?.learnerDetail?.fullName,
            isLearner: true,
            isAssessment: true,
            assessment: AssesData,
            learnerDetails: {
              schoolName: user?.academicDetails?.schoolName,
              location: user?.academicDetails?.location,
              schoolId: user?.assignedSchool,
              grade: user?.academicDetails?.grade,
              gradeId: user?.assignedGrade,
            },
          }),
        });

        const res = await Result.json();
        console.log(res, "resresresres");

        // res.msg2.skillScore.map((item) => {
        //   item.rubricsQts.map((items) => {
        //     view = items.quesDesc;
        //   });
        // });
        // console.log(view, "greeeeeeeeeeeeeeeeeee");

        if (Result.ok) {
          setAssessmentData(AssesData);
          setResultId(res?.msg2?._id);
          setScore(res?.msg2?.totalScore);
          let score1 = 0;
          await res?.msg2?.skillScore?.map((data, key) =>
            data?.rubricsQts?.map((datas, i) => (score1 += datas?.quesMarks))
          );

          setPercentage((res?.msg2?.totalScore / score1) * 100);
          setTotalScore(score1);
          console.log("df", score1);
          setResultData(res.msg2);
          console.log("Result Data =======>", res?.msg2);
        } else {
          console.error("API error =====>", res?.err);
        }
      } catch (error) {}
    };
    getResultId();
  }, []);

  const [test, setTest] = useState(1);

  const onButtonChange = (key, i, value, minvalue) => {
    console.log(key, i, value);
    // // setAssessmentData(data=>[...data,{"data.skills[key].rubricsQts[i].type":value}])
    let vari = assessmentData;
    vari.skills[key].rubricsQts[i].type = value;
    if (value == "Not Yet") {
      vari.skills[key].rubricsQts[i].points = 0;
    } else if (value == "Getting There") {
      vari.skills[key].rubricsQts[i].points =
        Number(vari.skills[key].rubricsQts[i].quesMarks) * 0.5;
    } else if (value == "Gets IT") {
      vari.skills[key].rubricsQts[i].points =
        Number(vari.skills[key].rubricsQts[i].quesMarks) * 1;
    } else if (value == "Yes") {
      vari.skills[key].rubricsQts[i].points =
        Number(vari.skills[key].rubricsQts[i].quesMarks) * 1;
    } else if (value == "No") {
      vari.skills[key].rubricsQts[i].points = 0;
    } else {
      if (Number(value) >= minvalue) {
        vari.skills[key].rubricsQts[i].points =
          Number(vari.skills[key].rubricsQts[i].quesMarks) * 1;
      } else {
        vari.skills[key].rubricsQts[i].points = 0;
      }
    }
    // vari.skills[key].rubricsQts[i].type=value;
    console.log("MY VARI ======>", vari);
    setAssessmentData(vari);
    setTest((data) => data + 1);
    // console.log("AssessmentData,",vari)
  };

  const calculateAndSaveAssessment = async () => {
    try {
      let totalScore = 0;
      await assessmentData?.skills?.map((data, key) =>
        data?.rubricsQts?.map((datas, i) => (totalScore += datas?.points))
      );

      const response = await fetch(
        `${FETCH_URL}/api/assessment/calculateAssessmentResult/${resultId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            totalScore,
            skills: assessmentData?.skills,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response from login==>", res);
        navigate(-1);
      } else {
        // alert(res.err);
        console.log("My response from login==>", totalScore);
        console.log("My response from login==>", assessmentData?.skills);
      }
    } catch (error) {
      console.log("error while calculateAssessment", error);
    }
  };

  console.log(percentage, "percentagepercentagepercentage");

  useEffect(() => {
    console.log("UserData is ", user);
  }, []);
  return (
    <>
      <Grid container height="104px" className="bg-zoommer">
        <Grid className="space-between-center w-100 mt_30px">
          <Typography className="BackHead">
            <IoIosArrowBack onClick={() => navigate(-1)} />
          </Typography>

          <Typography className="first-cap color-white font-w-700 ml-10px font-18">
            {AssesData?.assessmentName}
          </Typography>

          <Typography></Typography>
        </Grid>
      </Grid>

      <div className="jumpedContAsses">
        <div className="jumpsImage first-cap">
          <img src={AssesData?.assessmentImage} style={{ marginLeft: "7px" }} />
        </div>

        <div className="PPInfo">
          <Grid item xs={12}>
            <Typography align="center" className="PPCourseButton">
              {AssesData?.assignedXp} PP
            </Typography>
          </Grid>
        </div>

        <div className="jumpInfo">
          <div className="jumpDetail">
            <h2 className="first-cap">{AssesData?.assessmentName}</h2>
            <h3>{AssesData?.uid}</h3>
            <h3>
              {" "}
              {moment(AssesData?.assignedGrades?.[0]?.assignedOn).format(
                "DD/MM/YYYY"
              )}
            </h3>
            <div className="subBtns">
              {score > 0 ? (
                <>
                  <button>Completed</button>
                </>
              ) : (
                <>
                  <button onClick={calculateAndSaveAssessment}>Submit</button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="assesWid">
        <div className="assesUsers">
          <div className="assesInfo">
            <div className="assesImgs">
              <Stack direction="row" spacing={2}>
                <Avatar
                  alt={user?.learnerDetail?.fullName?.[0].toUpperCase()}
                  src={
                    user?.learnerDetail?.learnerImg
                      ? user?.learnerDetail?.learnerImg
                      : RxAvatar
                  }
                  sx={{ height: "80px", width: "80px", fontSize: "35px" }}
                />
              </Stack>
            </div>
            <div className="assesTexts">
              <h2 className="first-cap">{user?.learnerDetail?.fullName}</h2>
              <div className="assesMinTxt">
                <h5> {user?.learnerDetail?.regNo},</h5>
                <h5 style={{ marginLeft: "5px" }}>
                  {user?.academicDetails?.grade}
                </h5>
              </div>
              <h5 style={{ width: "98%" }}>
                {user?.academicDetails?.schoolName}
              </h5>
            </div>
          </div>
          <div className="proscores">
            <div className="proAsses">
              <CircularProgressbar
                justifyContent="center"
                className="CircularBar stroke-white"
                value={percentage.toFixed(2)}
                text={
                  percentage != 0
                    ? percentage == 100
                      ? "100%"
                      : percentage.toFixed(2) + "%"
                    : "0%"
                }
              />
            </div>
            <div className="scoresAsses">
              <h2>Score:</h2>
              <h3>
                {score}/{totalScore}
              </h3>
            </div>
          </div>
        </div>

        {score == 0 && (
          <AssementButtonCardTwo
            test={test}
            onButtonChange={onButtonChange}
            AssessmentData={assessmentData ? assessmentData : {}}
            resultId={resultId}
          />
        )}

        {/* {percentage > 0 ? (
          <>
            <div style={{ textAlign: "center", marginTop: "80px" }}>
              <span style={{ fontSize: 25, color: "#cddc39" }}>
                Your Assesment Has Been Completed
              </span>
            </div>
          </>
        ) : null} */}

        {score > 0 && (
          <ResultButtonCardTwo test={test} resultData={resultData} />
        )}
      </div>
    </>
  );
}
