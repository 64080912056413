import React from "react";
import {
  Grid,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import { VscBellDot } from "react-icons/vsc";

export default function Profile() {
  const navigate = useNavigate();
  const CardData = [
    {
      CouseName: " Jumping",
      CourseUid: " As-101",
      CourseData: " 12/02/2022",
      Score: " Grade 1-3 ",
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
  ];
  const TableData = [
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
  ];

  return (
    <>
      <div
        style={{
          backgroundColor: " #cddc39",
          borderBottomRightRadius: "25px",
          borderBottomLeftRadius: "25px",
          height: "140px",
        }}
      >
        <Grid container>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <div className="BackHead ">
              <IoIosArrowBack onClick={() => navigate(-1)} />
            </div>
            <div className="WhiteColorTypography MyclassesTypo">My Classes</div>
            <div>
              <VscBellDot className="BellIcon" />
            </div>
          </Grid>
        </Grid>
      </div>
      {CardData.map((user, key) => (
        <Grid container spacing={2} className="ResultProfileConatiner">
          <Grid item xs={4}>
            <Grid item className="OnGridImage"></Grid>
          </Grid>
          <Grid item xs={4}>
            <Typography className="GreyColorTypography">
              {user.CouseName}
            </Typography>
            <Typography className="lightGreyColorTypo Fs-0-8">
              {user.CourseUid}
            </Typography>
            <Typography className="lightGreyColorTypo Fs-0-8">
              {user.CourseData}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography align="center" className=" ResultGradeStyle">
              Grade 1-3
            </Typography>
          </Grid>
        </Grid>
      ))}
      <Grid
        container
        style={{
          marginLeft: "15px",
          marginTop: "10px",
          width: "93%",
          backgroundColor: "white",
          borderRadius: "10px ",
        }}
      >
        <Typography className="GreyColorTypography StudneListTypo">
          Student List
        </Typography>
        <div className="ResponsiveTable">
          <Table className="Tableroot">
            <TableHead>
              <TableRow className="Head">
                <TableCell className="ColHead">Roll no. </TableCell>
                <TableCell className="ColHead"> Name</TableCell>
                <TableCell className="ColHead">FMS Score</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {TableData.map((user, key) => (
                <TableRow>
                  <TableCell className="Col700"> {user.RollNo}</TableCell>
                  <TableCell className="Col700">
                    <Link
                      to="/student/resultstudentprofile"
                      className="LinkStyle"
                    >
                      {user.Name}
                    </Link>
                  </TableCell>
                  <TableCell className="Col700"> {user.Score}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Grid>
    </>
  );
}
