import React, { useEffect, useState } from "react";

import axios from "axios";
import { Typography, Grid, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { FETCH_URL } from "../../fetchIp";

export default function PushNotificationOpen() {
  const [learnerData, setLearnerdata] = useState([]);
  const role = localStorage.getItem("role");
  const [lp, setLp] = useState([]);
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const serviceUserId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const [assessmentData, setAssessmentData] = useState([]);

  const getAssessmentExplore = async (id) => {
    let assementId = [{ _id: id }];

    setIsLoading(true);

    try {
      const responce = await fetch(
        `${FETCH_URL}/api/assessment/getSingleAssessment/${id}`,
        {
          method: "GET",
          headers: {
            "Content-type": "Application/json",
          },
        }
      );

      const res = await responce.json();
      setIsLoading(false);
      if (responce.ok) {
        const resp = await axios.get(
          `${FETCH_URL}/api/learner/getSingleTrainer/${serviceUserId}`
        );
        let user = resp?.data?.msg;

        // let learnerDatas = await axios.get(
        //   `/api/auth/getSingleNormalLearner/${serviceUserId}`
        // );
        // let learner = learnerDatas?.data?.data;
        let AssessmentData = res?.msg?.[0];
        console.log("Response is Details Assessment", res, resp);

        navigate("/student/assesmentreport", {
          state: { user, AssessmentData },
        });
      }
    } catch (err) {
      console.log("Error is ", err);
      setIsLoading(false);
    }
  };

  function getLP(lessonPlanIds, expired = false) {
    let objectId = [{ lessonPlanId: lessonPlanIds }];

    axios
      .post(`/api/lessonplan/getLessonPlanByLessonPlanids`, {
        lessonPlanIds: objectId,
      })
      .then((resp) => {
        console.log(resp.data.msg, "lpData");

        let item = resp?.data?.msg?.[0];
        setIsLoading(false);

        if (expired) navigate("/student/trainingcomp", { state: item });
        else navigate("/student/trainingstart", { state: item });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }

  function getCourse(courseId, expired = false) {
    let objectId = [{ courseId: courseId }];

    console.log(courseId, "courseId");
    axios
      .post(`/api/lessonplan/course/getCoursesByCourseids/`, {
        CourseIds: objectId,
      })
      .then(async (resp) => {
        let item = resp?.data?.msg?.[0];
        console.log(item, "course data");

        setIsLoading(false);
        if (expired)
          navigate("/student/completelevel", {
            state: { data: item },
          });
        else
          navigate("/student/startlevel", {
            state: { data: item },
          });
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("notificationToken"));
    console.log("token is ", token);

    if (token?.type == "lessonplan") getLP(token?.id);
    else if (token?.type == "course") getCourse(token?.id);
    else if (token?.type == "lessonplanexpired") getLP(token?.id, true);
    else if (token?.type == "courseexpired") getCourse(token?.id, true);
    else if (token?.type == "assessmentassign") getAssessmentExplore(token?.id);
  }, []);

  useEffect(() => {
    if (window.ReactNativeWebView) window.ReactNativeWebView.postMessage(1);
  }, []);

  return (
    <>
      {isLoading ? <LoadingSpinner /> : null}
      <Grid>
        <Typography>Push Notifiactions</Typography>
      </Grid>
    </>
  );
}
