import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { MdKeyboardArrowRight } from "react-icons/md";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Context/AuthContext";
import axios from "axios";

export default function Profile() {
  const navigate = useNavigate();

  // const { authHelper } = useAuth();
  const { trainerId, authHelper } = useAuth();
  const { signOut } = authHelper;

  const FlushFirebaseToken = async () => {
    try {
      const responce = await axios.get(
        `/api/learner/removeNotificationTokenForTrainer/${trainerId}`
      );
      if (responce.ok) {
        console.log("Response is Signout", responce);
        console.log("Response is 123123", responce);

        return true;
      }
    } catch (err) {
      console.log("Error is ", err);
    }
  };

  const logoutMe = () => {
    FlushFirebaseToken();

    if (window.ReactNativeWebView) window.ReactNativeWebView.postMessage(true);
    navigate("/auth/signin");
    signOut();
  };

  return (
    <>
      <Grid container>
        <Grid container direction="row">
          <div className="BackBadge">
            <IoIosArrowBack onClick={() => navigate(-1)} />
          </div>
        </Grid>
        <Typography
          className="GreyColorTypography"
          sx={{ pl: "19px", fontSize: "2.2rem" }}
        >
          Settings
        </Typography>

        <Grid container className="Settingridcontainer">
          <Link to="/student/account" className="Settinglink">
            <Grid container item className="Settingcard1">
              <Grid item xs={2}>
                <Stack direction="row" spacing={2}>
                  <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
                </Stack>
              </Grid>
              <Grid item xs={9}>
                <Typography className="GreyColorTypography AccountTypo ">
                  Account <br />
                  <span className="lightGreyColorTypo AccountTypo">
                    Edit,Address,profile,picture
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography alignSelf="center">
                  <MdKeyboardArrowRight className="GreyColorTypography SettingArrowIcon" />
                </Typography>
              </Grid>
            </Grid>
          </Link>
          <Link to="/student/security" className="Settinglink">
            <Grid container item className="Settingcard1">
              <Grid item xs={2}>
                <Stack direction="row" spacing={2}>
                  <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
                </Stack>
              </Grid>
              <Grid item xs={9}>
                <Typography className="GreyColorTypography AccountTypo ">
                  Security <br />
                  <span className="lightGreyColorTypo AccountTypo">
                    Change password
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography alignSelf="center">
                  <MdKeyboardArrowRight className="GreyColorTypography SettingArrowIcon" />
                </Typography>
              </Grid>
            </Grid>
          </Link>
          <Link to="/student/notifcation" className="Settinglink">
            <Grid container item className="Settingcard1">
              <Grid item xs={2}>
                <Stack direction="row" spacing={2}>
                  <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
                </Stack>
              </Grid>
              <Grid item xs={9}>
                <Typography className="GreyColorTypography AccountTypo ">
                  Notification <br />
                  <span className="lightGreyColorTypo AccountTypo">
                    Change notification sound, text
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography alignSelf="center">
                  <MdKeyboardArrowRight className="GreyColorTypography SettingArrowIcon" />
                </Typography>
              </Grid>
            </Grid>{" "}
          </Link>
          {/* <Link to="/student/Paymentmethod" className="Settinglink">
            <Grid container item className="Settingcard1">
              <Grid item xs={2}>
                <Stack direction="row" spacing={2}>
                  <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
                </Stack>
              </Grid>
              <Grid item xs={9}>
                <Typography className="GreyColorTypography AccountTypo ">
                  Payment Method <br />
                  <span className="lightGreyColorTypo AccountTypo">
                    manage payment methods
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography alignSelf="center">
                  <MdKeyboardArrowRight className="GreyColorTypography SettingArrowIcon" />
                </Typography>
              </Grid>
            </Grid>
          </Link> */}
          <Grid container item className="Settingcard1">
            <Grid item xs={2}>
              <Stack direction="row" spacing={2}>
                <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
              </Stack>
            </Grid>
            <Grid item xs={9} pt={0.6}>
              <Typography className="GreyColorTypography AccountTypo ">
                Term & Condition <br />
                <span className="lightGreyColorTypo AccountTypo"></span>
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography alignSelf="center">
                <MdKeyboardArrowRight className="GreyColorTypography SettingArrowIcon" />
              </Typography>
            </Grid>
          </Grid>
          <Grid container item className="Settingcard1">
            <Grid item xs={2}>
              <Stack direction="row" spacing={2}>
                <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
              </Stack>
            </Grid>
            <Grid item xs={9} pt={0.6}>
              <Typography className="GreyColorTypography AccountTypo ">
                Privacy
                <br />
                <span className="lightGreyColorTypo AccountTypo"></span>
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography alignSelf="center">
                <MdKeyboardArrowRight className="GreyColorTypography SettingArrowIcon" />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="SettinGridIetm Settingcard1">
          <Typography
            align="center"
            className="GreyColorTypography"
            fontSize="1.3rem"
            onClick={() => {
              logoutMe();
            }}
          >
            Sign Out
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
