import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, IconButton } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
// import Man from "../../../assets/img/Man.png";
import Avatar from "@mui/material/Avatar";
import { useAuth } from "../../../Context/AuthContext";
import { FETCH_URL } from "../../../fetchIp";
import stats from "../../../assets/img/Vector (33).png";
import result from "../../../assets/img/Vector (34).png";
import leader from "../../../assets/img/Vector (35).png";
import set from "../../../assets/img/Vector (37).png";
// import SettingsIcon from "@mui/icons-material/Settings";
import SettingsSharpIcon from "@mui/icons-material/SettingsSharp";
import { RxAvatar } from "react-icons/rx";
import { Stack } from "@mui/material";
import axios from "axios";
import LoadingSpinner from "../../Spinner/LoadingSpinner";

export default function Profile() {
  const navigate = useNavigate();
  const { trainerId } = useAuth();
  const [score, setScore] = useState(0);
  const { authHelper } = useAuth();
  const { signOut } = authHelper;
  const [userDetails, setUserDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getScore = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/assessment/getResultByTrainer/${trainerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const res = await response.json();
      // console.clear();
      // console.log("response : ", res);
      if (response.ok) {
        setScore(res.totalScore);
        console.log("Score Data =======>", res);
      } else {
        console.error("API error =====>", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  const getSingleTrainer = async () => {
    try {
      const resp = await axios.get(
        `${FETCH_URL}/api/learner/getSingleTrainer/${trainerId}`
      );
      setUserDetail(resp?.data?.msg);
      setIsLoading(false);

      console.log("Data is ", resp);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const FlushFirebaseToken = async () => {
    try {
      const responce = await axios.get(
        `/api/learner/removeNotificationTokenForTrainer/${trainerId}`
      );
      if (responce.ok) {
        console.log("Response is Signout", responce);
        console.log("Response is 123123", responce);

        return true;
      }
    } catch (err) {
      console.log("Error is ", err);
    }
  };

  const logoutMe = () => {
    FlushFirebaseToken();

    if (window.ReactNativeWebView) window.ReactNativeWebView.postMessage(true);
    navigate("/auth/signin");
    signOut();
  };

  useEffect(async () => {
    await getScore();
    getSingleTrainer();
  }, []);

  return (
    <>
      {isLoading ? <LoadingSpinner /> : null}
      <div
        style={{
          height: "260px",
          width: "100%",
          backgroundColor: " #cddc39",
          borderBottomLeftRadius: "30px",
          borderBottomRightRadius: "30px",
        }}
      >
        <div>
          <Grid container>
            <Grid item>
              <div
                className="BackHead"
                style={{ color: "white", fontSize: "1.6rem" }}
              >
                <IoIosArrowBack className="" onClick={() => navigate(-1)} />
              </div>
            </Grid>
            <Grid
              mt={3}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{ justifyContent: "center", alignItem: "center" }}
            >
              <Stack direction="row" spacing={2}>
                <Avatar
                  alt={userDetails?.trainerDetail?.fullName?.[0]?.toUpperCase()}
                  // alt={learnerdata?.learnerDetail?.fullName?.[0].toUpperCase()}
                  src={
                    userDetails?.trainerDetail?.trainerImg != undefined
                      ? userDetails?.trainerDetail?.trainerImg
                      : RxAvatar
                  }
                  // fontSize={"40px"}
                  sx={{ height: "80px", width: "80px", fontSize: "45px" }}
                />
              </Stack>

              <Typography
                align="center"
                className="WhiteColorTypography first-cap"
                fontSize={"22px"}
                // marginBottom={"20px"}
              >
                {userDetails?.trainerDetail?.fullName?.slice(0, 25)}
              </Typography>

              {/* <Typography
                align="center"
                className="WhiteColorTypography"
                style={{ fontSize: "13px" }}
              >
                {learnerdata?.academicDetails?.grade}
              </Typography> */}
            </Grid>

            {/*  */}
            {/* <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{ justifyContent: "center", alignItem: "center" }}
              xs={12}
            >
              <Typography aling="center">
                <img
                  src={userDetails?.trainerDetail?.trainerImg}
                  style={{
                    width: "90px",
                    height: "80px",
                    borderRadius: "45px",
                  }}
                />
              </Typography>
              <Typography align="center" className="WhiteColorTypography">
                {userDetails?.trainerDetail?.fullName},
              </Typography>
              <Typography
                align="center"
                className="WhiteColorTypography"
                style={{ fontSize: "11px" }}
              >
                Trainer, Zoommer Play
              </Typography>
            </Grid> */}
          </Grid>
        </div>
      </div>
      <Grid container className="w-100-per" p={1} mt={-11}>
        {/* <Grid
          item
          xs={12}
          style={{
            boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.25)",
            marginTop: "-26px",
            padding: "5px",
            borderRadius: "20px",
            background: "white",
          }}
        >
          <Typography style={{ marginLeft: "10px" }}> Profile</Typography>
          <Progress percent={80} style={{ marginLeft: "10px", width: "95%" }} />
          <Typography
            align="right"
            className="GreenColorTypography"
            style={{ marginRight: "9px", marginTop: "8px", fontSize: "14px" }}
          >
            Complete profile to earn 100 PP
          </Typography>
        </Grid> */}
        <Grid
          container
          style={{
            marginTop: "13px",
            backgroundColor: "#ffffff",
            height: "auto",
            borderRadius: "15px",
            boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.25)",
            paddingBottom: "20px",
          }}
        >
          <Grid container item xs={7}>
            <Grid
              container
              item
              xs={4}
              style={{
                justifyContent: "center",
                paddingTop: "20px",
              }}
            >
              <Avatar className="GreenAvtImg"> </Avatar>
            </Grid>
            <Grid item xs={8} style={{ paddingTop: "20px" }}>
              <Typography className="GreenColorTypography">
                {userDetails?.assignedSchool?.length}
              </Typography>
              <Typography className="GreyColorTypography"> Schools </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={5}>
            <Grid
              container
              item
              xs={4}
              style={{
                justifyContent: "center",
                paddingTop: "20px",
              }}
            >
              <Avatar className="GreenAvtImg"> </Avatar>
            </Grid>
            <Grid item xs={8} style={{ paddingTop: "20px" }} pl={0.5}>
              <Typography className="GreenColorTypography"> {score}</Typography>
              <Typography className="GreyColorTypography">
                Play Points
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={7}>
            <Grid
              container
              item
              xs={4}
              style={{
                justifyContent: "center",
                paddingTop: "20px",
              }}
            >
              <Avatar className="GreenAvtImg"> </Avatar>
            </Grid>
            <Grid item xs={8} style={{ paddingTop: "20px" }}>
              <Typography className="GreenColorTypography">
                {userDetails?.assignedCourses?.length}
              </Typography>
              <Typography
                className="GreyColorTypography"
                style={{ fontSize: "14px" }}
              >
                Courses
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={5}>
            <Grid
              container
              item
              xs={4}
              style={{
                justifyContent: "center",
                paddingTop: "22px",
              }}
            >
              <Avatar className="GreenAvtImg"> </Avatar>
            </Grid>
            <Grid item xs={8} style={{ paddingTop: "18px" }} pl={0.5}>
              <Typography className="GreenColorTypography">
                {userDetails?.assignedSchool?.length}
              </Typography>
              <Typography className="GreyColorTypography">Grades</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div
        style={{ position: "relative", marginBottom: "80px", padding: "8px" }}
      >
        <Typography className="GreyColorTypography" p={1}>
          Explore
        </Typography>

        <Link to="/student/statsnew">
          <div className="pfGrids">
            <div className="pfImgs">
              <img src={stats} />
            </div>
            <div className="pfText">
              <h3>Stats</h3>
              <h5>Chek your progress</h5>
            </div>
          </div>
        </Link>

        <Link to="/student/resultnew">
          <div className="pfGrids">
            <div className="pfImgs">
              <img src={result} />
            </div>
            <div className="pfText">
              <h3>Result</h3>
              <h5>Chek your progress</h5>
            </div>
          </div>
        </Link>

        <Link to="/student/leaderboardnew">
          <div className="pfGrids">
            <div className="pfImgs">
              <img src={leader} />
            </div>
            <div className="pfText">
              <h3>Leaderboard</h3>
              <h5>Chek your progress</h5>
            </div>
          </div>
        </Link>

        <Link to="/student/trainings">
          <div className="pfGrids">
            <div className="pfImgs">
              <img src={result} />
            </div>
            <div className="pfText">
              <h3>Training</h3>
              <h5>Chek your progress</h5>
            </div>
          </div>
        </Link>

        <Link to="/student/setting">
          <div className="pfGrids">
            <div className="pfImgs">
              <SettingsSharpIcon
                sx={{ color: "#cddc39" }}
                className="settingIcon"
              />
              {/* <img src={set} /> */}
            </div>
            <div className="pfText">
              <h3>Settings</h3>
              <h5>Chek your progress</h5>
            </div>
          </div>
        </Link>
      </div>

      <div className={"logoutBtns3 p-fixed-bottom"}>
        <button onClick={logoutMe}>Logout</button>
      </div>

      {/* <div className="logoutBtns">
        <button
          onClick={() => {
            signOut();
          }}
        >
          Logout
        </button>
      </div> */}
    </>
  );
}
