import React, { useState, useEffect } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { IoIosArrowBack } from "react-icons/io";

export default function Profile() {
  const { state } = useLocation();
  const [percentage, setPercentage] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let total = 0;

    for (let index = 0; index < state.assignedFolder.pages.length; index++) {
      const element = state.assignedFolder.pages[index];
      total = total + element.marks;
    }
    setTotal(total);
    setPercentage((state.totalScore / total) * 100);
    console.log("training result useEffect", state);
  }, []);

  const navigate = useNavigate();
  return (
    <>
      <div className="CPGreenDivStyle">
        <Grid container>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <div className="BackHead ">
              <IoIosArrowBack onClick={() => navigate(-1)} />
            </div>
            <div className="WhiteColorTypography CourseTypo"> Training</div>
            <div> </div>
          </Grid>
        </Grid>
      </div>

      <Grid container spacing={2} className="CourseProfileConatiner">
        <Grid item xs={4}>
          <Grid item className="OnGridImage"></Grid>
        </Grid>
        <Grid item xs={4}>
          <Typography className="GreyColorTypography">
            {state.LPTitle}
          </Typography>
          <Typography className="lightGreyColorTypo Fs-0-8">
            {state.LPUid}
          </Typography>
          <Typography className="lightGreyColorTypo Fs-0-8">
            {/* {state.CourseData} */}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography align="center" className=" PPCourseButton">
            {state.resultPP}/{state.assignedPP} PP
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item className="ProgressScoreGridItem">
          <Grid container item justifyContent="left">
            <Grid item xs={5}>
              <CircularProgressbar
                className="CircularBar"
                value={percentage}
                text={`${percentage}%`}
              />
            </Grid>
            <Grid
              item
              xs={7}
              style={{
                justifyContent: "center",
                borderRadius: "10px",
                backgroundColor: "#ffffff",
              }}
            >
              <Grid
                item
                style={{
                  justifyContent: "center",
                  borderRadius: "10px",
                  backgroundColor: "#D1EDBD",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                <Typography align="center" className=" lightGreyColorTypo">
                  {state.totalScore} / {total}
                </Typography>
                <Typography
                  style={{ paddingTop: "9px" }}
                  align="center"
                  className="GreyColorTypo"
                >
                  {" "}
                  Score
                </Typography>
              </Grid>
              <Grid item style={{ paddingTop: "15px" }}>
                <Typography align="center" className=" lightGreyColorTypo">
                  {state.resultPP}/{state.assignedPP}
                </Typography>
                <Typography
                  style={{ paddingTop: "10px" }}
                  align="center"
                  className="GreyColorTypo"
                >
                  PP Earned
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
