import React, { useState, useEffect } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
import { FETCH_URL } from "../../../../fetchIp";
import { useAuth } from "../../../../Context/AuthContext";

export default function Profile() {
  const [lessonPlanData, setLessonPlanData] = useState([]);
  const { trainerId } = useAuth();
  const navigate = useNavigate();

  const getLessonPlanByLessonPlanIds = async (lessonPlanIds) => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/getLessonPlanByLessonPlanids`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({ lessonPlanIds }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        setLessonPlanData(res.msg);
        console.log("Learners Data =======>", res.msg);
      } else {
        console.error("API error =====>", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  const getSingleTrainer = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/getSingleTrainer/${trainerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        const upcomingLesson = res.msg.assignedLessonPlans.filter(
          (data) => data.completion == false
        );

        await getLessonPlanByLessonPlanIds(upcomingLesson);

        console.log("LessonPlan Data =======>", res.msg);
        console.log("LessonPlan Data =======>", upcomingLesson);
      } else {
        console.error("API error =====>", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  useEffect(async () => {
    await getSingleTrainer();
  }, []);

  return (
    <>
      {lessonPlanData.map((user, key) => (
        <Grid
          container
          spacing={2}
          className="OnGoingConatiner"
          onClick={() => navigate("/student/trainingprofile", { state: user })}
        >
          <Grid item xs={4}>
            <Grid item>
              <img
                src={user.LPThumbnail}
                width="80"
                height="60"
                style={{ borderRadius: 10, margin: 10 }}
              />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Typography className="GreyColorTypography">
              {user.LPTitle}
            </Typography>
            <Typography className="lightGreyColorTypo Fs-0-8">
              {user.LPUid}
            </Typography>
            <Typography className="lightGreyColorTypo Fs-0-8">
              {user.CourseData}
            </Typography>

            <Typography
              align="center"
              className="GreenButton700 ViewCourseButton"
              style={{ width: "100%" }}
            >
              Attempt NOW
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography align="center" className=" PPCourseButton">
              {user.LPXp} PP
            </Typography>
          </Grid>
        </Grid>
      ))}
    </>
  );
}
