import React from "react";
import {
  Grid,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import ZoommerPlay from "./../../../../assets/img/Zoommer Play.png";
import SchoolRanking from "./../../../../assets/img/School Ranking.png";
import NationalRanking from "./../../../../assets/img/National Ranking.png";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";

const data = [
  {
    name: "sunday 2hr",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "monday 4hr",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "tuesday 3hr",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "thursday 2hr",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "friday 4hr",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "saturday 3hr",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
];
const TableData = [
  {
    RollNo: "1",
    Name: "Amit Yadav",
    Grade: "9.2",
    Score: "9.2",
  },
  {
    RollNo: "1",
    Name: "Amit Yadav",
    Grade: "9.2",
    Score: "9.2",
  },

  {
    RollNo: "1",
    Name: "Amit Yadav",
    Grade: "Preschool",
    Score: "9.2",
  },
  {
    RollNo: "1",
    Name: "Amit Yadav",
    Grade: "9.2",
    Score: "9.2",
  },

  {
    RollNo: "1",
    Name: "Amit Yadav",
    Grade: "9.2",
    Score: "9.2",
  },
  {
    RollNo: "1",
    Name: "Amit Yadav",
    Grade: "9.2",
    Score: "9.2",
  },
  {
    RollNo: "1",
    Name: "Amit Yadav",
    Grade: "9.2",
    Score: "9.2",
  },
];

export default function Profile() {
  const navigate = useNavigate();
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <>
      <Grid container>
        <Grid container direction="row">
          <div className="BackBadge">
            <IoIosArrowBack onClick={() => navigate(-1)} />
          </div>
        </Grid>
        <Typography
          className="GreyColorTypography"
          sx={{ pl: "19px", fontSize: "18px" }}
        >
          {" "}
          Stats
        </Typography>

        <Grid
          container
          xs={12}
          style={{
            marginTop: "20px",
            marginLeft: "10px",
            marginBottom: "10px",
          }}
        >
          <Grid item xs={4}>
            <Grid
              item
              xs={11}
              style={{
                borderRadius: "15px",
                backgroundColor: "#ffffff",
                padding: "13px 10px",
              }}
            >
              <Typography align="center" className="GreenColorTypography">
                <Typography fontSize="2.6rem"> 14 </Typography>
              </Typography>
              <Typography align="center" className="lightGreyColorTypo">
                Assesments
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid
              item
              xs={11}
              style={{
                borderRadius: "15px",
                backgroundColor: "#ffffff",
                padding: "13px 10px",
              }}
            >
              <Typography align="center" className="GreenColorTypography">
                <Typography fontSize="2.6rem"> 14 </Typography>
              </Typography>
              <Typography align="center" className="lightGreyColorTypo">
                Course
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid
              item
              xs={11}
              style={{
                borderRadius: "15px",
                backgroundColor: "#ffffff",
                padding: "13px 10px",
              }}
            >
              <Typography align="center" className="GreenColorTypography">
                <Typography fontSize="2.6rem"> 14 </Typography>
              </Typography>
              <Typography align="center" className="lightGreyColorTypo">
                Events
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <div style={{ width: "100%" }}>
          <h4 style={{ marginLeft: "15px" }} className="GreyColorTypography">
            Learning Time
          </h4>
          <ResponsiveContainer width="100%" aspect={1}>
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              barSize={20}
            >
              <XAxis
                dataKey="name"
                scale="point"
                padding={{ left: 10, right: 10 }}
                interval={"preserveStartEnd"}
              />
              <YAxis />
              <Tooltip />
              <Legend />
              <CartesianGrid strokeDasharray="3 3" />
              <Bar dataKey="pv" fill="#8884d8" background={{ fill: "#eee" }} />
            </BarChart>
          </ResponsiveContainer>
        </div>

        <Grid
          container
          style={{
            marginTop: "20px",
            marginLeft: "20px",
            width: "92%",
            backgroundColor: "#ffffff",
            borderRadius: "10px",
          }}
        >
          <Grid item xs={4}>
            <Grid
              item
              xs={11}
              style={{
                borderRadius: "15px",
                backgroundColor: "#ffffff",
                paddingTop: "50px",
                paddingBottom: "10px",
              }}
            >
              <Grid container item xs={12} justifyContent="center">
                <img src={ZoommerPlay} />
              </Grid>

              <Typography
                align="center"
                className="GreyColorTypography"
                fontSize="12px"
              >
                Zoommer Play
              </Typography>
              <Grid container item xs={12} justifyContent="center">
                <button className="GreenButton RankButton">Rank 5</button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid
              item
              xs={11}
              style={{
                borderRadius: "15px",
                backgroundColor: "#ffffff",
                paddingTop: "50px",
                paddingBottom: "10px",
              }}
            >
              <Grid container item xs={12} justifyContent="center">
                <img src={ZoommerPlay} />
              </Grid>

              <Typography
                align="center"
                className="GreyColorTypography"
                fontSize="12px"
              >
                Zoommer Play
              </Typography>
              <Grid container item xs={12} justifyContent="center">
                <button className="GreenButton RankButton">Rank 5</button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid
              item
              xs={11}
              style={{
                borderRadius: "15px",
                backgroundColor: "#ffffff",
                paddingTop: "50px",
                paddingBottom: "10px",
              }}
            >
              <Grid container item xs={12} justifyContent="center">
                <img src={ZoommerPlay} />
              </Grid>

              <Typography
                align="center"
                className="GreyColorTypography"
                fontSize="12px"
              >
                Zoommer Play
              </Typography>
              <Grid container item xs={12} justifyContent="center">
                <button className="GreenButton RankButton">Rank 5</button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* ////////////////////////////////////////////Table Start */}

      <Grid
        container
        style={{
          marginLeft: "15px",
          marginTop: "20px",
          width: "93%",
          backgroundColor: "white",
          borderRadius: "10px ",
        }}
      >
        <Grid container item xs={12} justifyContent="space-between">
          <Typography className="GreyColorTypography StudneListTypo">
            Top Students
          </Typography>
          <Typography
            className="GreyColorTypography StudneListTypo"
            style={{ paddingRight: "22px" }}
          >
            See All
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Typography
            className="GreyColorTypography400"
            style={{ fontSize: "12px", marginLeft: "20px" }}
          >
            Here’s your top 5 students in last 7 days
          </Typography>
        </Grid>
        <div className="ResponsiveTable">
          <Table className="Tableroot">
            <TableHead>
              <TableRow className="Head">
                <TableCell className="ColHeadSmallFont"></TableCell>
                <TableCell className="ColHeadSmallFont">
                  Student Name{" "}
                </TableCell>
                <TableCell className="ColHeadSmallFont"> Grade</TableCell>
                <TableCell className="ColHeadSmallFont">Play Points</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {TableData.map((user, key) => (
                <TableRow>
                  <TableCell className="Col700"> {user.RollNo}</TableCell>
                  <TableCell className="Col700">
                    <Link
                      to="/student/classesstudentreport"
                      className="LinkStyle"
                    >
                      {user.Name}
                    </Link>
                    <Typography
                      style={{ border: "2px sloid red", fontSize: "12px" }}
                    >
                      Loreum Ipsum, Noida
                    </Typography>
                  </TableCell>
                  <TableCell className="Col700"> {user.Grade}</TableCell>
                  <TableCell className="Col700"> {user.Score}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Grid>
    </>
  );
}
