import React, { useState, useEffect } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { VscBellDot } from "react-icons/vsc";
import Man from "../../../../assets/img/Man.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import AssementButtonCard from "../../../../components/common/AssementButtonCard";
import ResultButtonCard from "../../../../components/common/ResultButtonCard";
import { FETCH_URL } from "../../../../fetchIp";

export default function Profile() {
  const [resultId, setResultId] = useState("");
  const [assessmentData, setAssessmentData] = useState();
  const [resultData, setResultData] = useState();

  const [score, setScore] = useState(0);
  const [totalScore, setTotalScore] = useState(0);
  const [percentage, setPercentage] = useState(0);

  const locationData = useLocation();
  const user = locationData.state.user;
  const AssessmentData = locationData.state.AssessmentData;

  useEffect(() => {
    const getResultId = async () => {
      try {
        const Result = await fetch(`${FETCH_URL}/api/assessment/createResult`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            userId: user._id,
            name: user.learnerDetail.fullName,
            isLearner: true,
            isAssessment: true,
            assessment: AssessmentData,
            learnerDetails: {
              schoolName: user.academicDetails.schoolName,
              location: user.academicDetails.location,
              schoolId: user.assignedSchool,
              grade: user.academicDetails.grade,
              gradeId: user.assignedGrade,
            },
          }),
        });

        const res = await Result.json();
        if (Result.ok) {
          setAssessmentData(AssessmentData);
          setResultId(res.msg2._id);
          setScore(res.msg2.totalScore);

          let score1 = 0;
          await res.msg2?.skillScore?.map((data, key) =>
            data.rubricsQts.map((datas, i) => (score1 += datas.quesMarks))
          );

          setPercentage((res.msg2.totalScore / score1) * 100);
          setTotalScore(score1);
          console.log("df", score1);
          setResultData(res.msg2);
          console.log("Result Data =======>", res.msg2);
        } else {
          console.error("API error =====>", res.err);
        }
      } catch (error) {}
    };
    getResultId();
  }, []);

  const navigate = useNavigate();

  const [test, setTest] = useState(1);

  const onButtonChange = (key, i, value, minvalue) => {
    console.log(key, i, value);
    // // setAssessmentData(data=>[...data,{"data.skills[key].rubricsQts[i].type":value}])
    let vari = assessmentData;
    vari.skills[key].rubricsQts[i].type = value;
    if (value == "Not Yet") {
      vari.skills[key].rubricsQts[i].points = 0;
    } else if (value == "Getting There") {
      vari.skills[key].rubricsQts[i].points =
        Number(vari.skills[key].rubricsQts[i].quesMarks) * 0.5;
    } else if (value == "Gets IT") {
      vari.skills[key].rubricsQts[i].points =
        Number(vari.skills[key].rubricsQts[i].quesMarks) * 1;
    } else if (value == "Yes") {
      vari.skills[key].rubricsQts[i].points =
        Number(vari.skills[key].rubricsQts[i].quesMarks) * 1;
    } else if (value == "No") {
      vari.skills[key].rubricsQts[i].points = 0;
    } else {
      if (Number(value) >= minvalue) {
        vari.skills[key].rubricsQts[i].points =
          Number(vari.skills[key].rubricsQts[i].quesMarks) * 1;
      } else {
        vari.skills[key].rubricsQts[i].points = 0;
      }
    }
    // vari.skills[key].rubricsQts[i].type=value;
    console.log("MY VARI ======>", vari);
    setAssessmentData(vari);
    setTest((data) => data + 1);
    // console.log("AssessmentData,",vari)
  };

  const calculateAndSaveAssessment = async () => {
    try {
      let totalScore = 0;
      await assessmentData?.skills?.map((data, key) =>
        data.rubricsQts.map((datas, i) => (totalScore += datas.points))
      );

      const response = await fetch(
        `${FETCH_URL}/api/assessment/calculateAssessmentResult/${resultId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            totalScore,
            skills: assessmentData.skills,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("My response from login==>", res);
        navigate("/student/bouncingresult");
      } else {
        // alert(res.err);
        console.log("My response from login==>", totalScore);
        console.log("My response from login==>", assessmentData.skills);
      }
    } catch (error) {
      console.log("error while calculateAssessment", error);
    }
  };

  return (
    <>
      <div className="assesmentMainDiv">
        <Grid container>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: "20px" }}
          >
            <div className="BackHead ">
              <IoIosArrowBack onClick={() => navigate(-1)} />
            </div>
            <div className="WhiteColorTypography MyclassesTypo">
              Student status
            </div>
            <div>
              <VscBellDot className="BellIcon" />
            </div>
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={2} className="AssesProfileConatiner">
        <Grid item xs={3}>
          <Grid item className="OnGridImage"></Grid>
        </Grid>
        <Grid item xs={4}>
          <Typography className="GreyColorTypography">
            {AssessmentData.assessmentName}
          </Typography>
          <Typography className="lightGreyColorTypo Fs-0-8">
            {AssessmentData.uid}
          </Typography>
          <Typography className="lightGreyColorTypo Fs-0-8">
            12/03/2022
          </Typography>
        </Grid>
      </Grid>
      <Grid container className="assesmentSecondMain">
        <Grid container xs={7} className="assesmentThirdMain">
          <Grid item xs={5} className="assesImg">
            <img src={Man} className="RSRImage" />
          </Grid>
          <Grid item xs={7} style={{ textAlign: "" }}>
            <Typography className="lightGreyColorTypo">
              {user.learnerDetail.fullName}
            </Typography>
            <Typography className="lightGreyColorTypo Fs-0-8">
              {user.learnerDetail.regNo}
            </Typography>
            <Typography className="lightGreyColorTypo Fs-0-8">
              Grade -{user.academicDetails.grade}
            </Typography>
            <Typography className="lightGreyColorTypo Fs-0-8">
              {user.academicDetails.schoolName}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={4} className="assesmentFourMain">
          <CircularProgressbar
            justifyContent="center"
            className="CircularBar"
            value={percentage}
            text={`${percentage.toFixed(2)}%`}
          />
          <Grid container justifyContent="space-between">
            <Typography
              className="lightGreyColorTypo"
              style={{ marginLeft: "8px" }}
            >
              Score
            </Typography>
            <Typography className="lightGreyColorTypo">{score}</Typography>
            <Typography className="lightGreyColorTypo">/</Typography>
            <Typography
              className="lightGreyColorTypo"
              style={{ marginRight: "8px" }}
            >
              {totalScore}
            </Typography>
          </Grid>
        </Grid>

        {score == 0 && (
          <AssementButtonCard
            test={test}
            onButtonChange={onButtonChange}
            AssessmentData={assessmentData ? assessmentData : {}}
            resultId={resultId}
          />
        )}

        {score > 0 && <ResultButtonCard test={test} resultData={resultData} />}
      </Grid>

      {AssessmentData.skills[0]?.rubricsQts[0] && score == 0 ? (
        <Grid
          onClick={calculateAndSaveAssessment}
          item
          // className="SumbitButton"
          style={{
            width: "90%",
            borderRadius: "15px",
            margin: "20px",
            textAlign: "center",
          }}
          className="GreenButton700"
        >
          <Button className="GreenButton700  SumbitButt ">Submit</Button>
        </Grid>
      ) : null}
    </>
  );
}
