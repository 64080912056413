import React from "react";
import { Grid, Typography } from "@mui/material";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export default function Paymentmethod() {
  const [value, setValue] = React.useState("Credit Card");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const navigate = useNavigate();

  return (
    <>
      <Grid container direction="row">
        <div className="BackNotificaton">
          <IoIosArrowBack onClick={() => navigate(-1)} />
        </div>
      </Grid>
      <Typography
        className="GreyColorTypography"
        sx={{ pl: "19px", fontSize: "1.5rem" }}
      >
        Payment Method
      </Typography>
      <div className="Radio-button">
        <FormControl style={{ marginTop: "3%" }}>
          <FormLabel
            id="demo-controlled-radio-buttons-group"
            className="GreyColorTypography"
          >
            Cards
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              className="radioTypo"
              value="Credit Card"
              control={<Radio className="Radiostyle" />}
              label="Credit Card"
            />
            <FormControlLabel
              className="radioTypo"
              value="Debit Card"
              control={<Radio className="Radiostyle" />}
              label="Debit Card"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div className="Radio-button Netbanking">
        <FormControlLabel
          className="radioTypo"
          value="Netbanking"
          control={<Radio className="Radiostyle" />}
          label="Netbanking"
        />
      </div>

      <div className="Radio-button" style={{ marginTop: "5%" }}>
        <FormControl style={{ marginTop: "3%" }}>
          <FormLabel
            id="demo-controlled-radio-buttons-group"
            className="GreyColorTypography"
          >
            UPI & Wallets
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              className="radioTypo"
              value="BHIM UPI"
              control={<Radio className="Radiostyle" />}
              label="BHIM UPI"
            />
            <FormControlLabel
              className="radioTypo"
              value="Paytm"
              control={<Radio className="Radiostyle" />}
              label="Paytm"
            />
            <FormControlLabel
              className="radioTypo"
              value="PhonPe"
              control={<Radio className="Radiostyle" />}
              label="PhonPe"
            />
            <FormControlLabel
              className="radioTypo"
              value="Gpay"
              control={<Radio className="Radiostyle" />}
              label="Gpay"
            />
          </RadioGroup>
        </FormControl>
      </div>
    </>
  );
}
