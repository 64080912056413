import React, { useState } from "react";
import { Grid, Box, Tab, Tabs, DialogContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import CompleteEve from "./CompleteEve";
import UpcomingEve from "./UpcmingEve";

export default function Events() {
  const navigate = useNavigate();

  const [locked, setLocked] = useState(0);
  const handleScreenUnlocked = () => {
    setLocked(0);
  };

  const handleScreenLocked = () => {
    setLocked(1);
  };

  return (
    <>
      <div
        style={{
          backgroundColor: " #cddc39",
        }}
      >
        <Grid container>
          <Grid container item>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <div className="BackHead ">
                <IoIosArrowBack onClick={() => navigate(-1)} />
              </div>
              <div
                className="WhiteColorTypography eventTypo"
                style={{ fontSize: "19px", paddingTop: "8px" }}
              >
                Events
              </div>
              <div> </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className="playFirst">
        <div className="playFirstChanges">
          <span
            onClick={handleScreenUnlocked}
            style={{ float: "left" }}
            className={locked === 0 ? "toggleBar" : "nontoggleBar"}
          >
            Upcoming
          </span>
          <span
            onClick={handleScreenLocked}
            style={{ float: "right" }}
            className={locked === 1 ? "toggleBar" : "nontoggleBar"}
          >
            Completed
          </span>
        </div>
      </div>

      <div>
        {locked === 0 ? (
          <>
            <UpcomingEve />
          </>
        ) : (
          <>
            <CompleteEve />
          </>
        )}
      </div>
    </>
  );
}
