import React, { useState, useEffect } from "react";
import { Grid, Typography, Tab, Tabs, DialogContent, Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import { VscBellDot } from "react-icons/vsc";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import PropTypes from "prop-types";
import "react-circular-progressbar/dist/styles.css";
//core module
import Man from "../../../../assets/img/Man.png";
import Q2Tab from "./Q2Tab";
import Q3Tab from "./Q3Tab";
import Q1Tab from "./Q1Tab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Profile() {
  const [value, setValue] = React.useState(0);
  const percentage = 80;

  const navigate = useNavigate();
  const CardData = [
    {
      CouseName: " Amit Yadav",
      CourseUid: " Grade 2",
      CourseData: " #3 in Zoommer rank",
      Score: " Grade 1-3 ",
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
  ];
  const TableData = [
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
    {
      RollNo: "1",
      Name: "Amit Yadav",
      Score: "9.2",
    },
  ];

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <div
        style={{
          backgroundColor: " #cddc39",
          borderBottomRightRadius: "25px",
          borderBottomLeftRadius: "25px",
          height: "auto",
          paddingBottom: "16px",
        }}
      >
        <Grid container>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <div className="BackHead ">
              <IoIosArrowBack onClick={() => navigate(-1)} />
            </div>
            <div className="WhiteColorTypography MyclassesTypo">
              Student report
            </div>
            <div>
              <VscBellDot className="BellIcon" />
            </div>
          </Grid>
        </Grid>
        {CardData.map((user, key) => (
          <Grid container className="StudentProfileConatiner">
            <Grid item xs={4}>
              <Grid container item style={{ justifyContent: "center" }}>
                <img src={Man} className="RSRImage" />
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Typography className="WhiteColorTypography">
                {user.CouseName}
              </Typography>
              <Typography className="WhiteColorTypography Fs-0-8">
                {user.CourseUid}
              </Typography>
              <Typography className="WhiteColorTypography Fs-0-8">
                {user.CourseData}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <CircularProgressbar
                className="StudentCircularBar"
                value={percentage}
                text={`${percentage}`}
              />
            </Grid>
          </Grid>
        ))}
      </div>
      <div>
        <Accordion
          className="ArrowIcon"
          style={{
            backgroundColor: "#ffffff",
            width: "93.7%",
            marginLeft: "14px",
            borderRadius: "10px",
            marginTop: "15px",
          }}
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className="GreyColorTypography">
              Personal Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid container item xs={12} style={{ paddingLeft: "20px" }}>
                <Grid item xs={4}>
                  <Typography className="lightGreyColorTypo ">
                    Full Name
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  :
                </Grid>
                <Grid item xs={5}>
                  <Typography className="GreyColorTypography ">
                    Amit yadav
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} className="PersonalContainer2">
                <Grid item xs={4}>
                  <Typography className="lightGreyColorTypo">
                    Date of birth
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  :
                </Grid>
                <Grid item xs={5}>
                  <Typography className="GreyColorTypography ">
                    12/08/2013
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} className="PersonalContainer2">
                <Grid item xs={4}>
                  <Typography className="lightGreyColorTypo">
                    Father’s name
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  :
                </Grid>
                <Grid item xs={5}>
                  <Typography className="GreyColorTypography ">
                    Ajay yadav
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} className="PersonalContainer2">
                <Grid item xs={4}>
                  <Typography className="lightGreyColorTypo">Age</Typography>
                </Grid>
                <Grid item xs={1}>
                  :
                </Grid>
                <Grid item xs={5}>
                  <Typography className="GreyColorTypography ">19</Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} className="PersonalContainer2">
                <Grid item xs={4}>
                  <Typography className="lightGreyColorTypo">
                    Phone Number
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  :
                </Grid>
                <Grid item xs={5}>
                  <Typography className="GreyColorTypography ">
                    9988552211
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} className="PersonalContainer2">
                <Grid item xs={4}>
                  <Typography className="lightGreyColorTypo">E-mail</Typography>
                </Grid>
                <Grid item xs={1}>
                  :
                </Grid>
                <Grid item xs={5}>
                  <Typography className="GreyColorTypography ">
                    imamityadav@gmail.com
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} className="PersonalContainer2">
                <Grid item xs={4}>
                  <Typography className="lightGreyColorTypo">
                    Address
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  :
                </Grid>
                <Grid item xs={5}>
                  <Typography className="GreyColorTypography ">
                    Amit yadav
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} className="PersonalContainer2">
                <Grid item xs={4}>
                  <Typography className="lightGreyColorTypo">
                    Phone Number
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  :
                </Grid>
                <Grid item xs={5}>
                  <Typography className="GreyColorTypography ">
                    loreum ipsum lor ipsum,121212,India
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="ArrowIcon"
          style={{
            backgroundColor: "#ffffff",
            width: "93.7%",
            marginLeft: "14px",
            borderRadius: "10px",
            marginTop: "15px",
          }}
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography className="GreyColorTypography">
              Academic Progress
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container className="TabGrid">
              <Grid item conatiner>
                <Tabs
                  value={value}
                  onChange={TabChange}
                  aria-label="basic tabs example"
                  className="RSRCTabStyle"
                >
                  <Tab
                    className="RSRCTabChanges"
                    label="Q1"
                    {...a11yProps(0)}
                  />
                  <Tab
                    className="RSRCTabChanges"
                    label="Q2"
                    {...a11yProps(1)}
                  />
                  <Tab
                    className="RSRCTabChanges"
                    label="Q3"
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Grid>
            </Grid>
            <DialogContent dividers={false} className="TabPanelStyle">
              <TabPanel value={value} index={0}>
                <Q1Tab />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Q2Tab />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Q3Tab />
              </TabPanel>
            </DialogContent>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}
