import React from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";



const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

export default function Profile() {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  }));
  return (
    <>
      <Grid container>
        <Grid container direction="row">
          <div className="BackBadge">
            <IoIosArrowBack onClick={() => navigate(-1)} />
          </div>
        </Grid>
        <Typography
          className="GreyColorTypography"
          sx={{ pl: "19px", fontSize: "18px" }}
        >
          {" "}
          Badge
        </Typography>

        <Grid
          container
          item
          style={{
            backgroundColor: "#ffffff",
            borderRadius: "10px",
            marginTop: "35px",
            marginBottom: "10px",
            padding: "30px 10px",
            marginLeft: "15px",
            width: "92%",
          }}
        >
          <Grid item container>
            <Grid item xs={2}>
              <Stack spacing={2} style={{ marginLeft: "20px" }}>
                <Badge
                  className="AvatarRed"
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <SmallAvatar sx={{ backgroundColor: "#ffffff" }}>
                      <span
                        style={{ fontSize: "10px" }}
                        className="GreyColorTypography"
                      >
                        {" "}
                        LV.4
                      </span>
                    </SmallAvatar>
                  }
                >
                  <Avatar className="AvatarRed"></Avatar>
                </Badge>
              </Stack>{" "}
            </Grid>
            <Grid item xs={9} paddingLeft="40px">
              <Typography fontSize="20px" className="GreyColorTypography">
                {" "}
                The First Step{" "}
              </Typography>
              <Typography fontSize="14px" className="GreyColorTypo">
                {" "}
                Reach your First 7-day strike
              </Typography>
              <Box sx={{ flexGrow: 1, mt: 2 }}>
                <BorderLinearProgress
                  className="Hprogressbar"
                  variant="determinate"
                  value={50}
                />
              </Box>
            </Grid>{" "}
          </Grid>
          <Grid item container>
            <Grid item xs={2} sx={{ mt: 4 }}>
              <Stack spacing={2} style={{ marginLeft: "20px" }}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <SmallAvatar sx={{ backgroundColor: "#ffffff" }}>
                      <span
                        style={{ fontSize: "10px" }}
                        className="GreyColorTypography"
                      >
                        LV.4
                      </span>
                    </SmallAvatar>
                  }
                >
                  <Avatar className="AvatarPurple"></Avatar>
                </Badge>
              </Stack>{" "}
            </Grid>
            <Grid item xs={9} sx={{ mt: 4 }} paddingLeft="40px">
              <Typography fontSize="20px" className="GreyColorTypography">
                Amateur
              </Typography>

              <Box sx={{ flexGrow: 1, mt: 1 }}>
                <Typography
                  align="center"
                  borderRadius="10px"
                  className="GreenButton700"
                >
                  Claim reward
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item container sx={{ mt: 3 }}>
            <Grid item xs={2}>
              <Stack spacing={2} style={{ marginLeft: "20px" }}>
                <Badge
                  className="AvatarRed"
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <SmallAvatar sx={{ backgroundColor: "#ffffff" }}>
                      <span
                        style={{ fontSize: "10px" }}
                        className="GreyColorTypography"
                      >
                        {" "}
                        LV.4
                      </span>
                    </SmallAvatar>
                  }
                >
                  <Avatar className="AvatarRed"></Avatar>
                </Badge>
              </Stack>{" "}
            </Grid>
            <Grid item xs={9} paddingLeft="40px">
              <Typography fontSize="20px" className="GreyColorTypography">
                Upcoming Pro
              </Typography>
              <Typography fontSize="14px" className="GreyColorTypo">
                {" "}
                Rank in Top 10 in a League
              </Typography>
              <Box sx={{ flexGrow: 1, mt: 2 }}>
                <BorderLinearProgress
                  className="Hprogressbar"
                  variant="determinate"
                  value={50}
                />
              </Box>
            </Grid>{" "}
          </Grid>
          <Grid item container sx={{ mt: 3 }}>
            <Grid item xs={2}>
              <Stack spacing={2} style={{ marginLeft: "20px" }}>
                <Badge
                  className="AvatarRed"
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <SmallAvatar sx={{ backgroundColor: "#ffffff" }}>
                      <span
                        style={{ fontSize: "10px" }}
                        className="GreyColorTypography"
                      >
                        {" "}
                        LV.4
                      </span>
                    </SmallAvatar>
                  }
                >
                  <Avatar className="AvatarRed"></Avatar>
                </Badge>
              </Stack>{" "}
            </Grid>
            <Grid item xs={9} paddingLeft="40px">
              <Typography fontSize="20px" className="GreyColorTypography">
                Ninja
              </Typography>
              <Typography fontSize="14px" className="GreyColorTypo">
                {" "}
                Master 3 movement Skills
              </Typography>
              <Box sx={{ flexGrow: 1, mt: 2 }}>
                <BorderLinearProgress
                  className="Hprogressbar"
                  variant="determinate"
                  value={50}
                />
              </Box>
            </Grid>{" "}
          </Grid>
          <Grid item container sx={{ mt: 3 }}>
            <Grid item xs={2}>
              <Stack spacing={2} style={{ marginLeft: "20px" }}>
                <Badge
                  className="AvatarRed"
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <SmallAvatar sx={{ backgroundColor: "#ffffff" }}>
                      <span
                        style={{ fontSize: "10px" }}
                        className="GreyColorTypography"
                      >
                        {" "}
                        LV.4
                      </span>
                    </SmallAvatar>
                  }
                >
                  <Avatar className="AvatarRed"></Avatar>
                </Badge>
              </Stack>{" "}
            </Grid>
            <Grid item xs={9} paddingLeft="40px">
              <Typography fontSize="20px" className="GreyColorTypography">
                The Archer
              </Typography>
              <Typography fontSize="14px" className="GreyColorTypo">
                {" "}
                Master 3 Object Control Skills
              </Typography>
              <Box sx={{ flexGrow: 1, mt: 2 }}>
                <BorderLinearProgress
                  className="Hprogressbar"
                  variant="determinate"
                  value={50}
                />
              </Box>
            </Grid>{" "}
          </Grid>
          <Grid item container sx={{ mt: 3 }}>
            <Grid item xs={2}>
              <Stack spacing={2} style={{ marginLeft: "20px" }}>
                <Badge
                  className="AvatarRed"
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <SmallAvatar sx={{ backgroundColor: "#ffffff" }}>
                      <span
                        style={{ fontSize: "10px" }}
                        className="GreyColorTypography"
                      >
                        {" "}
                        LV.4
                      </span>
                    </SmallAvatar>
                  }
                >
                  <Avatar className="AvatarRed"></Avatar>
                </Badge>
              </Stack>{" "}
            </Grid>
            <Grid item xs={9} paddingLeft="40px">
              <Typography fontSize="20px" className="GreyColorTypography">
                The Archer
              </Typography>
              <Typography fontSize="14px" className="GreyColorTypo">
                {" "}
                Master 3 Object Control Skills
              </Typography>
              {/* <Box sx={{ flexGrow: 1, mt: 2 }}>
                <BorderLinearProgress
                  className="Hprogressbar"
                  variant="determinate"
                  value={50}
                />
              </Box> */}
            </Grid>{" "}
          </Grid>
          <Grid item container sx={{ mt: 3 }}>
            <Grid item xs={2}>
              <Stack spacing={2} style={{ marginLeft: "20px" }}>
                <Badge
                  className="AvatarRed"
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <SmallAvatar sx={{ backgroundColor: "#ffffff" }}>
                      <span
                        style={{ fontSize: "10px" }}
                        className="GreyColorTypography"
                      >
                        {" "}
                        LV.4
                      </span>
                    </SmallAvatar>
                  }
                >
                  <Avatar className="AvatarRed"></Avatar>
                </Badge>
              </Stack>{" "}
            </Grid>
            <Grid item xs={9} paddingLeft="40px">
              <Typography fontSize="20px" className="GreyColorTypography">
                The Archer
              </Typography>
              <Typography fontSize="14px" className="GreyColorTypo">
                {" "}
                Master 3 Object Control Skills
              </Typography>
              {/* <Box sx={{ flexGrow: 1, mt: 2 }}>
                <BorderLinearProgress
                  className="Hprogressbar"
                  variant="determinate"
                  value={50}
                />
              </Box> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
