import React from "react";
import { Grid, Typography, Button, DialogActions } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useQuestion } from "../../../../../../Context/QuestionContext";
import { useLessonPlan } from "../../../../../../Context/LPContext";

export default function Profile(props) {
  const { TabNext7, TabPre7 } = props;

  const { lessonplans } = useLessonPlan();
  const { questionData, setQuestionData, countDown, mins, secs } =
    useQuestion();

  const [value, setValue] = React.useState("");

  const changeData = (val, index) => {
    // console.log("Selected =======>", val, "  ", index);
    let data = questionData;
    let isCorrect;
    let marks;
    // console.log(changeData);

    if (val === lessonplans.questionnaire[6].correctAnswer) {
      isCorrect = true;
      marks = 5;
    } else {
      isCorrect = false;
      marks = 0;
    }

    data[index] = { value: val, isCorrect, marks, id: 7 };
    console.log("My final data fof Array ======>", data);
    setQuestionData(data);
  };

  React.useEffect(() => {
    let data = questionData;
    if (data.length > 0) {
      let radioData = data.find((x) => x?.id === 7);
      if (radioData) {
        setValue(radioData.value);
      }
    }
  }, []);

  return (
    <>
      <Grid container spacing={2} className="OnGoingConatiner">
        <Grid item xs={12}>
          <Typography
            className="GreyColorTypography   Tmarksstyle"
            align="right"
          >
            Time :
            <span className="colorred  ml6px">
              {mins !== null ? mins : 0} : {secs}
            </span>
          </Typography>
          <Typography className="GreyColorTypography">
            <>
              <FormControl className="m2px width96">
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  className="GreyColorTypography mb20px mt20px"
                >
                  7. {lessonplans.questionnaire[6].question}
                </FormLabel>
                <Typography
                  className="GreyColorTypography   Tmarksstyle"
                  align="right"
                >
                  <span className="colorred"> 5</span> Marks
                </Typography>
                {lessonplans.questionnaire[6].options.map((data, i) => {
                  return (
                    <>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={value ? value : ""}
                        onChange={(e) => {
                          setValue(e.currentTarget.value);
                          changeData(e.currentTarget.value, 6);
                        }}
                      >
                        <FormControlLabel
                          className="RadioButton GreyColorTypography"
                          value={data.option}
                          control={<Radio />}
                          label={<Typography>{data.option}</Typography>}
                        />
                      </RadioGroup>
                    </>
                  );
                })}
              </FormControl>
            </>
          </Typography>
        </Grid>
      </Grid>
      <DialogActions className="Tactionbutton">
        <Grid container justifyContent="space-around">
          <Button onClick={TabPre7} className="GreyButton700 prevbutton">
            Previous
          </Button>
          <Button onClick={TabNext7} className="nextbutton GreenButton700">
            Next
          </Button>
        </Grid>
      </DialogActions>
    </>
  );
}
