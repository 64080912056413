import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import "./assets/css/MobileApp.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { SnackbarProvider } from "notistack";

ReactDOM.render(
  <Router>
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    >
      <App />
    </SnackbarProvider>
  </Router>,
  document.getElementById("root")
);
