import React from "react";
import {
  Grid,
  Typography,
  Button,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@mui/material";
import Zoommerlogo from "../../../../../../assets/img/ZoommerWhitelogo.png";
import Instruimg from "../../../../../../assets/img/TrainerQuiz.png";
import { IoCloseSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
export default function Profile() {
  return (
    <>
      <Grid container>
        <Grid
          container
          item
          justifyContent="center"
          style={{ backgroundColor: "#cddc39" }}
        >
          <img src={Zoommerlogo} style={{ width: "250px", height: "80px" }} />
          <Typography
            aling="center"
            style={{ fontSize: "23px" }}
            className="Fs-2 WhiteColorTypography"
          >
            Zoommer Sport Program
          </Typography>
          <Typography
            aling="center"
            className="WhiteColorTypography Fs-1-4"
            style={{ marginTop: "10px", marginBottom: "20px" }}
          >
            Educator Training Deck
          </Typography>
        </Grid>
        <img
          src={Instruimg}
          style={{
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "20px",
          }}
        />
      </Grid>
    </>
  );
}
