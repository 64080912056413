import React from "react";
import { useAuth } from "../../../Context/AuthContext";

const ContactNew = () => {
  const { userDetails } = useAuth();
  console.log(userDetails, "userDetails");

  return (
    <>
      <div className="formContainer pb-pt-24px bg-white">
        <form>
          <div className="GreyColorTypography school">
            <label>Full Address</label>
            <input
              type="text"
              value={userDetails.contactDetail.trainerAddress}
              className="outline-noen pl-15"
              readOnly={true}
            />
          </div>
          <div className="locate GreyColorTypography ">
            <div className="locateInfo2">
              <label>State</label>
              <input
                type="text"
                value={userDetails.contactDetail.trainerState}
                className="outline-noen pl-15"
                readOnly={true}
              />
            </div>
            <div className="locateInfo2">
              <label>Country</label>
              <input
                type="text"
                value={userDetails.contactDetail.trainerCountry}
                className="outline-noen pl-15"
                readOnly={true}
              />
            </div>
          </div>
          <div className="GreyColorTypography school">
            <label>Email</label>
            <input
              type="text"
              value={userDetails.contactDetail.trainerEmail}
              className="outline-noen pl-15"
              readOnly={true}
            />
          </div>
          <div className="GreyColorTypography school">
            <label>Phone Number</label>
            <input
              type="text"
              value={userDetails.contactDetail.trainerPhoneNo}
              className="outline-noen pl-15"
              readOnly={true}
            />
          </div>

          {/* <div className={"account-update-btn"}>
            <button className="updateProfile">Save</button>
          </div> */}
          {/* <div className="GreyColorTypography">
            <button>Save</button>
          </div> */}
        </form>
      </div>
    </>
  );
};

export default ContactNew;
