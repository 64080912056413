import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";
import LoadingSpinner from "../../Spinner/LoadingSpinner";
import { useAuth } from "../../../Context/AuthContext";
import { useNavigate } from "react-router-dom";

export default function NativeHome() {
  const [isLoading, setIsLoading] = useState(true);
  const { authHelper } = useAuth();
  const { signIn } = authHelper;
  const navigate = useNavigate();

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    console.log("Data is ", userData);

    if (userData != undefined || userData != null) {
      localStorage.setItem("serviceUserId", userData?.serviceUserId);
      localStorage.setItem("userId", userData?.userId);
      localStorage.setItem("loginToken", userData?.token);
      localStorage.setItem("role", userData?.role);

      navigate("/student/home");
      signIn();

      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <>
      {isLoading ? <LoadingSpinner /> : null}
      {/* <Grid>
        <Typography variant="h2">Home Page</Typography>
        <Typography>Data is: {userData1}</Typography>
      </Grid> */}
    </>
  );
}
