import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { useQuestion } from "../../../../Context/QuestionContext";
import { useLessonPlan } from "../../../../Context/LPContext";
import resultimage from "../../../../assets/img/resultimage.png";

export default function Profile() {
  const { lessonplans } = useLessonPlan();

  const d = new Date().getDate();
  const m = new Date().getMonth();
  const y = new Date().getFullYear();
  const CardData = [
    {
      CouseName: " Jumping",
      CourseUid: " As-101",
      CourseData: " 12/02/2022",
      Score: " 40 /50 ",
    },
  ];
  const navigate = useNavigate();

  const { questionData, setQuestionData, countDown, setCount } = useQuestion();

  const [perc, setPerc] = React.useState(0);
  const [ansTotal, setAnsTotal] = React.useState(0);

  useEffect(() => {
    countDown(true);

    console.log("Question Result =====>", questionData);
    let total = 0;
    for (let index = 0; index < questionData.length; index++) {
      const data = questionData[index];
      total += data.marks;
    }
    setAnsTotal(total);
    setPerc(Math.round((total * 100) / 65));
    console.log("Final Total =======>", total);

    // Clear on unMount
    return () => {
      setQuestionData([]);
      setCount(false);
    };
  }, [questionData]);

  return (
    <>
      <div className="CPGreenDivStyle">
        <Grid container>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <div className="BackHead ">
              <IoIosArrowBack onClick={() => navigate(-1)} />
            </div>
            <div className="WhiteColorTypography CourseTypo"> Course</div>
            <div> </div>
          </Grid>
        </Grid>
      </div>
      {CardData.map((user, key) => (
        <Grid container spacing={2} className="CourseProfileConatiner">
          <Grid item xs={4}>
            <Grid item className="OnGridImage"></Grid>
          </Grid>
          <Grid item xs={4}>
            <Typography className="GreyColorTypography">
              {lessonplans.LPTitle}
            </Typography>
            <Typography className="lightGreyColorTypo Fs-0-8">
              {lessonplans.LPUid}
            </Typography>
            <Typography className="lightGreyColorTypo Fs-0-8">
              {`${d}/${m}/${y}`}
            </Typography>
            {/* <Typography
              align="center"
              className="GreenButton700 DownloadReportButton"
            >
              <Link to="/student/courseprofile"> Download report </Link>
            </Typography> */}
          </Grid>
          <Grid item xs={4}>
            <Typography align="center" className=" PPCourseButton">
              {ansTotal}/65 PP
            </Typography>
            {/* <Typography
              align="center"
              className="WhiteButton700 ShareCourseButton"
            >
              <Link to="/student/courseprofile"> share </Link>
            </Typography> */}
          </Grid>
        </Grid>
      ))}
      <Grid container>
        <Grid item className="CourseNameGridItem">
          <Typography
            align="center"
            className="GreenColorTypography CourseProfileName"
          >
            {lessonplans.LPTitle}{" "}
            <span className="CPUId"> {lessonplans.LPUid} </span>
          </Typography>
          <Grid item className="CPImage">
            <img src={resultimage} />
          </Grid>
          <Grid container item justifyContent="center">
            <Grid item xs={5} style={{ marginTop: "10px" }}>
              <CircularProgressbar
                className="CircularBar"
                value={perc}
                text={`${perc}%`}
              />
            </Grid>

            <Grid container justifyContent="space-evenly">
              <Typography className="GreyColorTypography CPScoreTypo ">
                Score
              </Typography>
              <Typography className="  GreenColorTypography  CPScoreNo">
                {ansTotal}/65
              </Typography>
              <Typography></Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          {" "}
          <Link to="/student/course">
            <Typography className="GreyButton700 CPBackButton">
              {" "}
              Back
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </>
  );
}
