import React from "react";
import { useState, useEffect, useContext } from "react";
import {
  Grid,
  Typography,
  Box,
  TextField,
  Button,
  IconButton,
  Input,
} from "@mui/material";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import Stack from "@mui/material/Stack";
// import Snackbar from "@mui/material/Snackbar";
// import MuiAlert from "@mui/material/Alert";
import { CgArrowLongRight } from "react-icons/cg";
import { FaFacebookF } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { SiTwitter } from "react-icons/si";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";
import { useLogin } from "../../Context/LoginContext";
import { FETCH_URL } from "../../fetchIp";
import { useSnackbar } from "notistack";

export default function Signin() {
  const { authHelper } = useAuth();
  const { signIn } = authHelper;
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const login = async () => {
    try {
      if (email.length === 0) {
        showAlert("Email is Required", "error");
        return 0;
      } else if (password?.length < 6) {
        showAlert("Password at least 6 digits", "error");
        return 0;
      }
      setIsLoading(true);
      const response = await fetch(`${FETCH_URL}/api/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });
      const res = await response.json();

      console.log("response is signin", res);
      if (response.ok) {
        if (res?.role == 4) {
          console.log("My response from login==>", res);
          localStorage.setItem("loginToken", res.token);
          localStorage.setItem("role", res.role);
          // localStorage.setItem("userId", res.serviceUserId);
          // localStorage.setItem("serviceUserId", res.userId);
          localStorage.setItem("userId", res.userId);
          localStorage.setItem("serviceUserId", res.serviceUserId);
          signIn();

          /* notistack snackbar */
          enqueueSnackbar(res.msg, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else showAlert("Used Trainer credentials only", "error");
        // history.push("/admin/dashboard");
      } else {
        /* notistack snackbar */
        enqueueSnackbar(res.err, {
          variant: "error",
          autoHideDuration: 2000,
        });
      }
      setIsLoading(false);
    } catch (e) {
      console.log("Catch block error =>", e);
      setIsLoading(false);
    }
  };

  const showAlert = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant,
      autoHideDuration: 2000,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    login();
  };

  return (
    <>
      {isLoading ? <LoadingSpinner /> : null}
      <Grid container className="MainGridSignIn">
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={2}>
              <Typography className="Bottom-Line"> </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography className="BcTypo SignFontLS">
                Welcome to Zoommer Play
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={8}>
            <Typography className="BcTypo SignFontL">
              Sign in to Zoommer Play
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography className="BcTypo  SignFontLS GreyColorTypography400">
              Enter your login details to access your account.
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "30px" }}>
            <Box>
              <Input
                fullWidth
                placeholder="Enter Mobile Number / UID / Email"
                id="fullWidth"
                className="SignIn-Field border-light"
                disableUnderline
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <Input
                fullWidth
                type={show ? "text" : "password"}
                placeholder="Password"
                id="fullWidth"
                disableUnderline
                className="SignIn-Field border-light"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </Box>
            <Grid xs={12} className="space-between-center">
              <Typography
                className="GreenColorTypography SignFontLXS Forgot-Button"
                onClick={() => setShow(!show)}
              >
                {show === false ? "Show Password" : "Hide Password"}
              </Typography>

              <Typography
                fontSize={"14px"}
                fontWeight={"600"}
                className="text-CDDC39"
                onClick={() => navigate("/auth/sendmail")}
              >
                Forgot Password
              </Typography>
            </Grid>
          </Grid>

          <Grid container className="SignIn-MainContainer">
            <Button
              variant="contained"
              fullWidth={true}
              className="GreenButton700 SignIn-Button"
              onClick={handleSubmit}
              // component={Link} to="/auth/congrat"
            >
              <Grid item xs={6} textAlign={"left"} pl={"20px"}>
                Sign in
              </Grid>
              <Grid item xs={6} style={{ marginTop: "5px" }}>
                <CgArrowLongRight className="RowIcon-xs6" />
              </Grid>
            </Button>
          </Grid>
        </form>
      </Grid>
      {/* <Grid container>
        <Grid
          item
          xs={3.5}
          className="GreenColorTypography Grid-Border-Bottom"
        ></Grid>
        <Grid item xs={5} className="GreyColorTypography400 Connect-with">
          or connect with
        </Grid>
        <Grid
          item
          xs={3.5}
          className="GreenColorTypography Grid-Border-Bottom"
        ></Grid>
      </Grid>
      <Grid container justifyContent="center" className="SocialIcon-Main-Grid">
        <IconButton>
          <Typography>
            <FaFacebookF className="Social-icon " />
          </Typography>
        </IconButton>
        <IconButton>
          <Typography className="GIcon-Typo">
            <FcGoogle className="Social-icon" />
          </Typography>
        </IconButton>
        <IconButton>
          <Typography>
            <SiTwitter className="Social-icon" />
          </Typography>
        </IconButton>
      </Grid>
      <Grid container justifyContent="center" className="HaveAccount">
        <Typography className="GreyColorTypography400 HaveAccountF">
          Don’t have an account?
        </Typography>
        <Typography className="GreenColorTypography HaveAccountF">
          Sign up
        </Typography>
      </Grid> */}
    </>
  );
}
