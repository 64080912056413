import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  BottomNavigation,
  BottomNavigationAction,
  Drawer,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
// ReactIcons
import { BiBarChartAlt2 } from "react-icons/bi";
import { IoGameControllerOutline } from "react-icons/io5";
import { MdOutlinePlayLesson } from "react-icons/md";
import { MdOutlineExplore } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { RiHome2Line } from "react-icons/ri";
// core component
import routes from "../routes";
export default function FixedBottomNavigation() {
  const ref = React.useRef(null);
  const pathname = window.location.pathname;
  const [bottomvalue, setBottomValue] = useState(pathname);
  const [value, setValue] = React.useState(0);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  // console.log(
  //   "PathName ====>",
  //   window.location.pathname,
  //   "other ===>",
  //   routes[0].layout + routes[0].link
  // );

  useEffect(() => {
    [...routes].forEach((route) => {
      switch (window.location.pathname) {
        case `${route.layout + route.link} `:
          if (value !== route.activeIndex) {
            setValue(route.activeIndex);
          }
          break;
        default:
          break;
      }
    });
  }, [value, selectedIndex.routes]);
  useEffect(() => {
    setBottomValue(pathname);
  }, []);
  const onChange = (event, newValue) => {
    setBottomValue(newValue);
  };

  return (
    <Box sx={{ pb: 7 }} ref={ref}>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
        className="PaperZindex"
      >
        <BottomNavigation
          className="BottomActionButton"
          showLabels
          value={bottomvalue}
          onChange={onChange}
        >
          <BottomNavigationAction
            component={Link}
            to={"/student/home"}
            label="Home"
            icon={<RiHome2Line className="Fs-2-4" />}
          />
          <BottomNavigationAction
            component={Link}
            to={"/student/courses"}
            label="Learn"
            icon={<MdOutlinePlayLesson className="Fs-2-4" />}
          />
          <BottomNavigationAction
            component={Link}
            to={"/student/trainings"}
            label="Trainings"
            icon={<BiBarChartAlt2 className="Fs-2-4" />}
          />
          <BottomNavigationAction
            component={Link}
            to={"/student/assesmentnew"}
            label="Assesments"
            icon={<MdOutlinePlayLesson className="Fs-2-4" />}
          />
          <BottomNavigationAction
            component={Link}
            to={"/student/profile"}
            label="Profile"
            icon={<CgProfile className="Fs-2-4" />}
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
