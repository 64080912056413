import React, { useState, useEffect } from "react";
import { Typography, Grid, Button, Box, Tab, Tabs } from "@mui/material";
import "react-calendar/dist/Calendar.css";
//core component
import { useNavigate, useLocation } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { RiShieldStarLine } from "react-icons/ri";
import { AiOutlinePieChart } from "react-icons/ai";
import { MdInsertChartOutlined } from "react-icons/md";
import axios from "axios";
import moment from "moment";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  // const [value, onChange] = useState(new Date());
  const navigate = useNavigate();

  const location = useLocation();
  const eventDetails = location.state.item;
  const data = eventDetails;
  console.log(data, "i am data");

  const [value, setValue] = React.useState(0);
  const [info, setInfo] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    eveprofile();
  }, []);

  function eveprofile() {
    axios
      .get(`/api/school/event/getAllEvents`)
      .then((resp) => {
        console.log("Data is ", resp.data.msg);
        setInfo(resp.data.msg);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  let overallPP = parseInt(eventDetails?.assignedPP);

  let addClass = "";
  if (+overallPP > 999) {
    addClass = "fs-41";
  } else if (+overallPP > 9999) {
    addClass = "fs-33";
  } else if (+overallPP > 99999) {
    addClass = "fs-28";
  }

  return (
    <>
      <div
        className="Image bg-white"
        sx={{ backgroundImage: `url(${eventDetails?.image})` }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          className="container-img"
        >
          <Grid item>
            <IoIosArrowBack
              className="back-whishlist-icon"
              onClick={() => {
                navigate(-1);
              }}
            />
          </Grid>

          <Grid item>
            <CloseIcon
              className="back-whishlist-icon"
              onClick={() => {
                navigate(-1);
              }}
            />
          </Grid>
        </Grid>
        {/* <IoIosArrowBack className="BackHeadEve" onClick={() => navigate(-1)} /> */}
        {/* <div className="imgHeart">
          <img
            src={
              // data.image
              cross
            }
            onClick={() => navigate(-1)}
          />
        </div> */}
        <Grid container item className="Catchingdiv">
          <Typography
            fontSize="18px"
            className="WhiteColorTypography w-100 gradent-black pl-16px"
          >
            {eventDetails?.eventName?.slice(0, 30)}
            <br />
            <sapn>
              <Typography fontSize="12px" className="text-white ">
                {moment(eventDetails?.dateOfCreation).format("DD/MM/YYYY")} -{" "}
                {moment(eventDetails?.endDate).format("DD/MM/YYYY")}
              </Typography>
            </sapn>
          </Typography>
        </Grid>
      </div>
      <Grid p={0}>
        <Box sx={{ width: "100%", padding: "0px" }}>
          <Box
            className="bg-white"
            sx={{
              boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.1)",
              borderColor: "divider",
              marginBottom: "16px",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              indicatorColor="none"
              className="tab-text-color"
            >
              <Tab label="Overview" {...a11yProps(0)} className="colorTab" />
              <Tab label="Guidelines" {...a11yProps(1)} className="colorTab" />
              <Tab label="Rules" {...a11yProps(2)} className="colorTab" />
              <Tab label="Rewards" {...a11yProps(3)} className="colorTab" />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0} className="bg-white remove-padding">
            <Grid container className="navGrid">
              <Typography className="aboutText">About</Typography>
              <Typography
                className="GreyColorTypography"
                style={{
                  fontSize: "12px",
                  paddingTop: "5px",
                  paddingBottom: "10px",
                }}
              >
                {eventDetails?.eventDetails}
              </Typography>
            </Grid>

            <hr className="hrGrid" />

            <Grid className="navBoxes" xs={12} p={2}>
              <Typography
                paddingTop="10px"
                paddingBottom="2%"
                fontWeight="bold"
                color="#3A3A3B"
              >
                You’ll Get
              </Typography>

              <Grid container display="flex" alignItems="center">
                <Grid
                  item
                  xs={4}
                  className={`radius-8 h-73 `}
                  style={{ border: "2px solid #CDDC39" }}
                >
                  <Typography
                    fontSize="48px"
                    className={`font-w-700 text-cddc39 text-center ${addClass}`}
                  >
                    {/* {stateItem.assignedPP} */}
                    {eventDetails?.assignedPP}
                  </Typography>
                </Grid>

                <Grid item xs={8} textAlign="left" pl={1}>
                  <Typography className="font-w-700 light-black">
                    Play Points
                  </Typography>
                  <Typography className="RSRCTabStyle font-12">
                    More the play points, Higher the rank.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* <div className="joinsBtn">
              <button>Completed</button>
            </div> */}
          </TabPanel>

          <TabPanel value={value} index={1} className="bg-white remove-padding">
            <Grid container className="navGrid">
              <Typography className="aboutText">Guidelines</Typography>
              <Typography
                className="GreyColorTypography"
                style={{
                  fontSize: "12px",
                  paddingTop: "5px",
                  paddingBottom: "10px",
                }}
              >
                {eventDetails.eventGuidelines}
              </Typography>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={2} className="bg-white remove-padding">
            <Grid container className="navGrid">
              <Typography className="aboutText">Rules</Typography>
              <Typography
                className="GreyColorTypography"
                style={{
                  fontSize: "12px",
                  paddingTop: "5px",
                  paddingBottom: "10px",
                }}
              >
                {eventDetails.eventRules}
              </Typography>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={3} className="bg-white remove-padding">
            {/* <Grid container className="navGrid">
              <Typography className="aboutText">Rewards</Typography>
              <Typography
                className="GreyColorTypography"
                style={{
                  fontSize: "12px",
                  paddingTop: "5px",
                  paddingBottom: "10px",
                }}
              >
                {eventDetails?.rewards === undefined || eventDetails?.rewards === null
                  ? "No eventDetails of rewards"
                  : eventDetails?.rewards}
              </Typography>
            </Grid> */}

            {/* <hr className="hrGrid" /> */}
            <Grid className="navBoxes" xs={12} p={2}>
              <Typography
                paddingTop="10px"
                paddingBottom="2%"
                fontWeight="bold"
                color="#3A3A3B"
              >
                You’ll Get
              </Typography>

              <Grid container display="flex" alignItems="center">
                <Grid
                  item
                  xs={4}
                  className={`radius-8 h-73 `}
                  style={{ border: "2px solid #CDDC39" }}
                >
                  <Typography
                    fontSize="48px"
                    className={`font-w-700 text-cddc39 text-center ${addClass}`}
                  >
                    {/* {stateItem.assignedPP} */}
                    {eventDetails?.assignedPP}
                  </Typography>
                </Grid>

                <Grid item xs={8} textAlign="left" pl={1}>
                  <Typography className="font-w-700 light-black">
                    Play Points
                  </Typography>
                  <Typography className="RSRCTabStyle font-12">
                    More the play points, Higher the rank.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>

          <div className="joinsBtn">
            <button>Completed</button>
          </div>
        </Box>
      </Grid>
    </>
  );
}
