import React, { useState, useEffect } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import BouncingBall from "../../../../assets/img/BouncingBall.png";
import { Progress } from "react-sweet-progress";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "react-sweet-progress/lib/style.css";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { IoMdHeartEmpty } from "react-icons/io";
import { HiOutlineClipboardList } from "react-icons/hi";
import { GiSkills } from "react-icons/gi";
import { FiPlay } from "react-icons/fi";

export default function Profile() {
  const { state } = useLocation();
  const [percentage, setPercentage] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let total = 0;

    for (let index = 0; index < state.assignedFolder.pages.length; index++) {
      const element = state.assignedFolder.pages[index];
      total = total + element.marks;
    }
    setTotal(total);
    setPercentage((state.totalScore / total) * 100);
    console.log("state data ", state);
  }, []);
  const navigate = useNavigate();

  return (
    <>
      <div
        className="OnGoingProfileImage"
        style={{
          backgroundImage: `url(${state.LPThumbnail})`,
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          className="oncontainerimg"
        >
          <Grid item>
            <IoIosArrowBack
              fontSize="2rem"
              onClick={() =>
                // navigate("/student/training", { replace: true, state: 1 })
                navigate(-1)
              }
            />
          </Grid>
          <Grid item>
            <IoMdHeartEmpty fontSize="2.5rem" />
          </Grid>
        </Grid>
      </div>
      <Grid
        container
        style={{ marginLeft: "20px", width: "90%", zIndex: 1111 }}
      >
        <Grid item xs={12} className="oncontainerdatacard">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Typography className="GreyColorTypography onlptitle">
              {state?.LPTitle}
            </Typography>
            <Typography className="GreenColorTypoButton  Hpptypo">
              PP {state.assignedPP || state.LPXp}
            </Typography>
          </Grid>
          <Grid item className="pl">
            <Progress percent={69} className="progressbar" />
            <Typography className="BlackColorTypography  mt6px">
              About
            </Typography>
            <Typography className="GreyColorTypography  ondescrip">
              {state?.LPDesc}
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              className="onsportstypocard"
            >
              <Typography className="BlackColorTypography fs14px">
                Sports
              </Typography>
              <Typography className="BlackColorTypography   oncritypo">
                Cricket, Baseball, Basketball
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              className="onskilltypo"
            >
              <Typography className="BlackColorTypography">Skills</Typography>
              <Typography className="BlackColorTypography   oncritypo">
                Catching, Running, Swinging
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {state.resultPP ? (
          <>
            <Grid container spacing={2} xs={12} className="onyoucardcontainer">
              <Grid item xs={6} className="onyoucarditem">
                <Typography align="center" className="DarkBlackColorTypo">
                  Your Score
                </Typography>
                <Grid item xs={11}>
                  <Grid item className="backgroundPP">
                    <Typography className="BasicTypography Fs-0-8 center">
                      {state.resultPP}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography align="center" className="DarkBlackColorTypo">
                  Play Points
                </Typography>
              </Grid>
              <Grid item xs={6} className="onyoucarditem">
                <Typography align="center" className="DarkBlackColorTypo">
                  Your Result
                </Typography>
                <Grid item xs={11}>
                  <CircularProgressbar
                    className="CircularBar2"
                    value={percentage}
                    text={`${percentage}%`}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid container className="OnGoingConatiner">
              <Grid xs={12}>
                <Typography className="GreyColorTypography onyoutypo ">
                  You’ll Get
                </Typography>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Grid item className="backgroundPP">
                    <Typography className="BasicTypography Fs-0-8 center">
                      {state.assignedPP || state.LPXp}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={9}>
                  <Typography className="GreyColorTypography">
                    Play Points
                  </Typography>
                  <Typography className="lightGreyColorTypo Fs-0-8">
                    More the play points, more the rank.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        {!state.resultPP ? (
          <Grid
            onClick={() => navigate("/student/trainingMcqs", { state: state })}
            item
            // className="SumbitButton"
            style={{
              width: "100%",
              borderRadius: "5px",
              margin: "20px",
              textAlign: "center",
            }}
            className="GreenButton700"
          >
            <Button className="GreenButton700  SumbitButt ">Attempt</Button>
          </Grid>
        ) : (
          <Grid
            onClick={() =>
              // navigate("/student/training", { replace: true, state: 1 })
              navigate(-1)
            }
            item
            // className="SumbitButton"
            style={{
              width: "100%",
              borderRadius: "5px",
              margin: "20px",
              textAlign: "center",
            }}
            className="GreenButton700"
          >
            <Button className="GreenButton700  SumbitButt ">Back</Button>
          </Grid>
        )}
      </Grid>
    </>
  );
}
