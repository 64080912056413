import React, { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, Link } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { CircularProgressbar } from "react-circular-progressbar";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import white from "../../../../assets/img/Rectangle 4348.png";
import axios from "axios";
import { useAuth } from "../../../../Context/AuthContext";
import { FETCH_URL } from "../../../../fetchIp";
import img1 from "../../../../assets/img/img1.jpg";
import img2 from "../../../../assets/img/img2.jpg";
import img3 from "../../../../assets/img/img3.jpg";
import img4 from "../../../../assets/img/img4.jpg";
import LoadingSpinner from "../../../Spinner/LoadingSpinner";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function StatsNew() {
  const { userDetails, trainerId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  const data = [
    {
      name: "Locomoter",
      PP: 2400,
    },
    {
      name: `Object 
      Control1`,
      PP: 1398,
    },
    {
      name: `Body
      Management`,
      PP: 1398,
    },
  ];

  const navigate = useNavigate();
  // const percentage = 88;
  const [value, setValue] = React.useState(0);
  const [skill, setSkill] = useState("Locomotor");
  const [locomotor, setLocomotor] = useState([]);
  const [bodyManagement, setBodyManagement] = useState([]);
  const [objectControl, setObjectControl] = useState([]);
  const [locomotorData, setLocomotorData] = useState([]);
  const [bodyManagementData, setBodyManagementData] = useState([]);
  const [objectControlData, setObjectControlData] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [fmsReport, setFmsReport] = useState(0);
  const [graphData, setGraphData] = useState([
    { name: "Locomotor", PP: 0 },
    {
      name: `Object 
    Con..`,
      PP: 0,
    },
    {
      name: `Body
    Man..`,
      PP: 0,
    },
  ]);

  const SubSkillData = (mainskill) => {
    setIsLoading(true);
    axios
      .get(
        `${FETCH_URL}/api/assessment/getDistinctSubSkillByMainSkillPPByUserId/${trainerId}?mainskill=${mainskill}`
      )
      .then((resp) => {
        console.log("SubskillData", resp.data.msg);
        if (mainskill === "Locomotor") {
          setSkill("Locomotor");
          setLocomotorData(resp.data.msg);
        } else if (mainskill === "Object Control") {
          setSkill("Object Control");
          setObjectControlData(resp.data.msg);
        } else if (mainskill === "Body Management") {
          setSkill("Body Management");
          setBodyManagementData(resp.data.msg);
        }
        // setLocomotor(resp.data.msg);
        setIsLoading(false);
        console.log(resp, "stats data");
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      SubSkillData("Locomotor");
    } else if (newValue === 1) {
      SubSkillData("Object Control");
    } else {
      SubSkillData("Body Management");
    }
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));

  async function stats() {
    setIsLoading(true);

    const resp = await axios.get(
      `${FETCH_URL}/api/assessment/getDistinctMainSkillPPByUserId/${trainerId}`
    );
    console.log("Stats Response is ", resp);

    resp?.data?.msg?.map((item) => {
      if (item?._id?.skillName == "Locomotor") {
        setLocomotor(item);

        graphData[0].PP = item?.total;
      } else if (item?._id?.skillName == "Object Control") {
        setObjectControl(item);

        graphData[1].PP = item?.total;
      } else if (item?._id?.skillName == "Body Management") {
        setBodyManagement(item);

        graphData[2].PP = item?.total;
      }
    });

    setIsLoading(false);
  }

  const getFMSScore = async () => {
    setIsLoading(true);
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/assessment/getFMSScoreByUserId/${trainerId}`,
        {
          method: "GET",
          headers: {
            "Content-type": "Application/json",
          },
        }
      );

      const res = await responce.json();
      // if (res.status) {
      setPercentage(isNaN(res?.msg) ? 0 : res?.msg * 10);
      // }
      console.log("Response is ", res);
    } catch (err) {
      console.log("Error is ", err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getFMSScore();
    stats();
    SubSkillData(skill);

    console.log("Stats Data Response is ", graphData);
  }, []);

  return (
    <>
      {isLoading ? <LoadingSpinner /> : null}
      <div>
        <Grid
          direction="row"
          container
          justifyContent="space-between"
          alignItems="center"
          style={{
            paddingTop: "15px",
            background: "#cddc39",
            paddingBottom: "35px",
          }}
        >
          <div className="BackHeadStats">
            <IoIosArrowBack onClick={() => navigate(-1)} />
          </div>
          <div
            className="store-title text-white"
            style={{ marginLeft: "-10px" }}
          >
            Stats
          </div>
          <div></div>
        </Grid>

        <div className="proStats">
          <Grid p={0.5} className="space-between-center">
            <Typography
              fontSize={"12px"}
              fontWeight={"bold"}
              className="text-6f6f6f"
            >
              FMS Report
            </Typography>
            <Typography></Typography>
          </Grid>
          <div className="statsDiv">
            <Grid container>
              <Grid
                item
                xs={4}
                style={{
                  justifyContent: "center",
                  alignSelf: "center",
                  marginTop: "16px",
                }}
              >
                {console.log("Percentage is ", percentage)}
                <CircularProgressbar
                  className="CircularBarHome"
                  style={{ width: "100%" }}
                  value={percentage.toFixed(2)}
                  text={
                    percentage != 0
                      ? percentage == 100
                        ? "100%"
                        : percentage.toFixed(2) + "%"
                      : "0%"
                  }
                />
              </Grid>
              <Grid item xs={8} marginTop="5px" paddingRight={"4px"}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    sx={{ mt: 1 }}
                    className="lightGreyColorTypo Fs-0-7"
                  >
                    Locomotor
                  </Typography>
                  <Typography
                    sx={{ pt: 1.2, mr: 2 }}
                    className="lightGreyColorTypo Fs-0-7"
                  >
                    {locomotor?.total !== undefined ? locomotor?.total : 0}/
                    {locomotor?.overAll !== undefined ? locomotor?.overAll : 0}{" "}
                    PP
                  </Typography>
                </div>
                <Box sx={{ mt: 0 }}>
                  <BorderLinearProgress
                    className="Hprogressbar"
                    variant="determinate"
                    value={
                      locomotor?.total !== undefined
                        ? (
                            (locomotor?.total * 100) /
                            parseInt(locomotor?.overAll)
                          ).toFixed(2)
                        : 0
                    }
                  />
                </Box>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    sx={{ mt: 1 }}
                    className="lightGreyColorTypo Fs-0-7"
                  >
                    Object Control
                  </Typography>
                  <Typography
                    sx={{ pt: 1.2, mr: 2 }}
                    className="lightGreyColorTypo Fs-0-7"
                  >
                    {objectControl?.total !== undefined
                      ? objectControl?.total
                      : 0}
                    /
                    {objectControl?.total !== undefined
                      ? parseInt(objectControl?.overAll)
                      : 0}{" "}
                    PP
                  </Typography>
                </div>
                <Box sx={{ mt: 0 }}>
                  <BorderLinearProgress
                    className="Hprogressbar"
                    variant="determinate"
                    value={
                      objectControl?.total !== undefined
                        ? (
                            (objectControl?.total * 100) /
                            objectControl?.overAll
                          ).toFixed(2)
                        : 0
                    }
                  />
                </Box>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    sx={{ mt: 1 }}
                    className="lightGreyColorTypo Fs-0-7"
                  >
                    Body Management
                  </Typography>
                  <Typography
                    sx={{ pt: 1.2, mr: 2 }}
                    className="lightGreyColorTypo Fs-0-7"
                  >
                    {bodyManagement?.total !== undefined
                      ? bodyManagement?.total
                      : 0}
                    /
                    {bodyManagement?.overAll !== undefined
                      ? parseInt(bodyManagement?.overAll)
                      : 0}{" "}
                    PP
                  </Typography>
                </div>
                <Box sx={{ mt: 0 }}>
                  <BorderLinearProgress
                    className="Hprogressbar"
                    variant="determinate"
                    value={
                      bodyManagement?.total !== undefined
                        ? (
                            (bodyManagement?.total * 100) /
                            bodyManagement?.overAll
                          ).toFixed(2)
                        : 0
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>

      <div className="proStatsbar">
        <div className="days">
          <div>
            <h4
              style={{
                marginLeft: "4px",
                marginTop: "5px",
              }}
              className="GreyColorTypography"
            >
              Playing Points
            </h4>
          </div>
          {/* <div className="filterDays">
            <select>
              <option>Weekly</option>
              <option>Monthly</option>
            </select>
          </div> */}
        </div>

        <ResponsiveContainer width="100%" aspect={1}>
          <BarChart
            className="bar-chart-stats"
            width={500}
            height={300}
            data={graphData}
            margin={{
              top: 43,
              bottom: 5,
            }}
            barSize={20}
          >
            <XAxis
              dataKey="name"
              scale="point"
              padding={{ left: 10, right: 78 }}
              interval={"preserveStartEnd"}
            />
            <YAxis />
            <Tooltip />
            <Legend />
            <CartesianGrid strokeDasharray="3 3" />
            <Bar dataKey="PP" fill="#cddc39" background={{ fill: "#eee" }} />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <Box sx={{ width: "100%", marginTop: "10px" }}>
        <Box
          sx={{
            boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.1)",
            borderColor: "divider",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            indicatorColor="none"
            className="tab-user-select"
          >
            <Tab label="Locomotor" {...a11yProps(0)} className="statsTab" />
            <Tab
              label="Object Control"
              {...a11yProps(1)}
              className="statsTab"
            />
            <Tab
              label="Body Management"
              {...a11yProps(2)}
              className="statsTab"
            />
          </Tabs>
        </Box>
      </Box>

      <TabPanel value={value} index={0} className="tab-value">
        {locomotorData.map((item) => {
          return (
            <>
              <div className="statsNav">
                <div className="statsImg">
                  <img
                    src={
                      item?._id?.skillImg == undefined || null
                        ? img3
                        : item?._id?.skillImg
                    }
                    alt=""
                    height={"91px"}
                  />
                </div>
                <div className="statstext">
                  <div className="statsGame">
                    <h2>{item?._id?.skillName}</h2>
                    <h5>{item?.total} PP</h5>
                  </div>
                  <div className="statsBars">
                    <Box>
                      <BorderLinearProgress
                        className="Hprogressbar"
                        variant="determinate"
                        value={(item?.total * 100) / item?.overAll}
                      />
                    </Box>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </TabPanel>
      <TabPanel value={value} index={1} className="tab-value">
        {objectControlData.map((item) => {
          return (
            <>
              <div className="statsNav">
                <div className="statsImg">
                  <img
                    src={
                      item?._id?.skillImg == undefined || null
                        ? img3
                        : item?._id?.skillImg
                    }
                    alt=""
                    height={"91px"}
                  />
                </div>
                <div className="statstext">
                  <div className="statsGame">
                    <h2>{item?._id.skillName}</h2>
                    <h5>{item?.total} PP</h5>
                  </div>
                  <div className="statsBars">
                    <Box>
                      <BorderLinearProgress
                        className="Hprogressbar"
                        variant="determinate"
                        value={(item?.total * 100) / item?.overAll}
                      />
                    </Box>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </TabPanel>
      {console.log("bodyManagementData: ", bodyManagementData)}
      <TabPanel value={value} index={2} className="tab-value">
        {bodyManagementData.map((item, key) => {
          return (
            <>
              <div className="statsNav">
                <div className="statsImg">
                  <img
                    src={
                      item?._id?.skillImg == undefined || null
                        ? img3
                        : item?._id?.skillImg
                    }
                    alt=""
                    height={"91px"}
                  />
                </div>
                <div className="statstext">
                  <div className="statsGame">
                    <h2>{item?._id?.skillName}</h2>
                    <h5>{item?.total} PP</h5>
                  </div>
                  <div className="statsBars">
                    <Box>
                      <BorderLinearProgress
                        className="Hprogressbar"
                        variant="determinate"
                        value={(item?.total * 100) / item?.overAll}
                      />
                    </Box>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </TabPanel>
    </>
  );
}
