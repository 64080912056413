import React, { useState, useEffect } from "react";
import "./assets/css/MobileApp.css";
// core component
import { QuestionContext } from "./Context/QuestionContext";
import { LPContext } from "./Context/LPContext.js";
import { FETCH_URL } from "./fetchIp";
import { AuthContext } from "./Context/AuthContext.js";
import Admin from "./layouts/Admin";
import Auth from "./layouts/Auth";
import { Window, WindowSharp } from "@mui/icons-material";
import { Route, Navigate, useNavigate } from "react-router-dom";
import { navigate } from "react-big-calendar/lib/utils/constants";
import axios from "axios";

let a;

/* ==========================> Import Notes <========================== */
/* ###################################################################### */

function App() {
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [role, setRole] = React.useState(null);
  const [userDetails, setUserDetails] = React.useState(null);
  const [trainerId, setTrainerId] = React.useState(null);

  const [questionData, setQuestionData] = React.useState([]);
  const [lessonplans, setLessonPlans] = React.useState([]);
  // timer states

  const [secs, setSecs] = React.useState(2);
  const [mins, setMins] = React.useState(60);
  const [count, setCount] = React.useState(false);

  // axios.defaults.baseURL = "http://www.zoommer.co.in";
  // axios.defaults.headers.common = {
  //   Authorization: `Bearer ${token}`,
  // };

  // axios = axios.create({
  //   // baseURL: 'https://api.example.com',
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     "Content-Type": "application/json",
  //   },
  //   timeout: 10000,
  // });

  // let secs = 5;
  // let mins = 2;

  // function countDown() {
  //   let a = setInterval(() => {
  //     if (mins == null && secs == 0) {
  //       console.log("Finised");
  //       clearInterval(a);
  //       return;
  //     } else if (secs == 0) {
  //       setSecs((prevState) => 59);
  //       if (mins == 1) {
  //         setMins((prevState) => null);
  //       } else {
  //         setMins((prevState) => prevState - 1);
  //       }
  //     } else {
  //       setSecs((prevState) => prevState - 1);
  //     }
  //     console.log(mins, ":", secs);
  //   }, 1000);
  // }

  function countdown(turnOff) {
    let mins = 0;
    let seconds = 60;

    console.log("Turoffffffffff ==>", turnOff);

    if (turnOff) {
      clearInterval(a);
      setSecs(null);
      setMins(null);
      mins = 2;
      seconds = 60;
      return;
    }

    a = setInterval(() => {
      if (mins == 0 && seconds == 0) {
        clearInterval(a);
        console.log("timer has been completed");
        setSecs(null);
        setMins(null);
        navigate("/student/comresultprofile");
      } else if (seconds == 0) {
        mins = mins - 1;
        seconds = 60;
        setSecs(seconds);
        setMins(mins);
      }
      seconds = seconds - 1;
      console.log("time ===> ", mins + ":" + seconds);
      setSecs(seconds);
      setMins(mins);
    }, 1000);
  }
  const authHelper = React.useMemo(() => {
    return {
      signIn: async () => {
        try {
          const token = localStorage.getItem("loginToken");
          const role = localStorage.getItem("role");
          const serviceUserId = localStorage.getItem("serviceUserId");

          if (token !== null) {
            console.log("Token is =====>", token);
            setToken(token);
          }
          if (role !== null) {
            console.log("Role is =====>", role);
            setRole(role);
          }
          if (serviceUserId !== null) {
            console.log("UserId is =====>", serviceUserId);
            setTrainerId(serviceUserId);
          }
        } catch (e) {
          console.log("Token not found ", e);
        }
      },
      signOut: async () => {
        localStorage.removeItem("loginToken");
        localStorage.removeItem("role");
        localStorage.removeItem("userId");
        localStorage.removeItem("serviceUserId");
        setToken(null);
        setRole(null);
        setTrainerId(null);
        setUserDetails(null);
        navigate("/auth/signin");
      },
    };
  }, []);

  const checkLoginStatus = () => {
    try {
      const token = localStorage.getItem("loginToken");
      const role = localStorage.getItem("role");
      const userId = localStorage.getItem("userId");
      const serviceUserId = localStorage.getItem("serviceUserId");

      if (token !== null) {
        // value previously stored
        console.log("Login status is true =====>", token);
        setToken(token);
      }
      if (role !== null) {
        console.log("Role is =====>", role);
        setRole(role);
      }
      if (userId !== null) {
        console.log("Trainer Id =====>", userId);
        // setTrainerId(userId);
      }
      if (serviceUserId) {
        setTrainerId(serviceUserId);
      }
    } catch (e) {
      console.log("Token not found ", e);
    }
  };
  // Get user Details
  const getLoggedInUserDetails = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/getSingleTrainer/${trainerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      let res = await response.json();
      if (response.ok) {
        console.log("Trainer details =======>", res.msg);
        setUserDetails(res.msg);
      } else {
        console.log("Else block ====>", res.msg);
        // alert(res.msg);
      }
    } catch (e) {
      alert(e);
    }
  };

  const getAllLessonPlans = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/getAllLessonPlans`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setLessonPlans(res.msg[0]);
        console.log("Lesson Plans =======>", res.msg[0]);
      } else {
        console.error("API error =====>", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };
  React.useEffect(() => {
    checkLoginStatus();
  }, []);

  React.useEffect(() => {
    if (token) {
      getAllLessonPlans();
    }
    // setToken(true);
  }, [token]);

  React.useEffect(() => {
    if (trainerId) {
      getLoggedInUserDetails();
    }
    // setToken(true);
  }, [trainerId]);

  return (
    <>
      <AuthContext.Provider
        value={{
          userDetails,
          role,
          authHelper,
          token,
          setToken,
          trainerId,
        }}
      >
        <QuestionContext.Provider
          value={{
            setQuestionData,
            questionData,
            countDown: countdown,
            // countDown: countDown,
            mins,
            secs,
            count,
            setCount,
          }}
        >
          <LPContext.Provider value={{ setLessonPlans, lessonplans }}>
            {token ? <Admin /> : <Auth />}
          </LPContext.Provider>
        </QuestionContext.Provider>
      </AuthContext.Provider>
    </>
  );
}

export default App;
