import React, { useEffect, useState } from "react";
import kid from "../../../assets/img/practice_win (1).png";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../../Context/AuthContext";

export default function TrainingWinRestart() {
  const { trainerId } = useAuth();

  const location = useLocation();
  const stateData = location.state.state;
  let resultPP = location?.state?.total;
  let totalResultPP = location?.state?.total;
  console.log(stateData, "stateData");

  const resultId = location?.state?.resultId;
  const skillScore = location?.state?.skillScore;

  let LocoSkill = 0;
  let BodySkill = 0;
  let ObjSkill = 0;
  let LocoPer = 0;
  let BodyPer = 0;
  let ObjPer = 0;

  const [Locomotor, setLocomotor1] = useState();
  const [BodyManagement, setBodyManagement1] = useState();
  const [ObjectControl, setObjectControl1] = useState();

  const skillData1 = () => {
    skillScore?.skills?.map((data) => {
      if (data?.mainSkill == "Body Management") {
        BodySkill++;
        BodyPer += parseInt(data?.percent);
      }
      if (data?.mainSkill == "Object Control") {
        ObjSkill++;
        ObjPer += parseInt(data?.percent);
      }
      if (data?.mainSkill == "Locomotor") {
        LocoSkill++;
        LocoPer += parseInt(data?.percent);
      }
    });
    if (LocoPer !== 0) {
      LocoPer = LocoPer / LocoSkill;
      setLocomotor1(LocoPer);
    }
    if (BodyPer !== 0) {
      BodyPer = BodyPer / BodySkill;
      setBodyManagement1(BodyPer);
    }

    if (ObjPer !== 0) {
      ObjPer = ObjPer / ObjSkill;
      setObjectControl1(ObjPer);
    }
  };

  useEffect(() => {
    skillData1();
  }, []);

  useEffect(() => {
    if (window.ReactNativeWebView) window.ReactNativeWebView.postMessage(3);
  }, []);

  return (
    <>
      <div className="baccha">
        <div className="bacchaImg bg-333333">
          <img src={kid} alt="" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            position: "absolute",
            top: "40%",
          }}
        >
          <div className="resultPP">
            <h3>
              {totalResultPP?.toFixed()}/{stateData.LPXp} PP
            </h3>
            <h4>Play Points</h4>
          </div>
          <div className="resultScore">
            <h2>
              {totalResultPP?.toFixed()}/{stateData.LPXp} PP
            </h2>
            <h5>Score</h5>
          </div>
          <hr className="wid" />
          <div className="completeGrid">
            <div className="resultValue">
              <h3>
                Locomotor&nbsp;{" "}
                {Locomotor == undefined || isNaN(Locomotor) ? "0" : Locomotor}%
              </h3>
              <h3>
                Body Management&nbsp;
                {BodyManagement == undefined || isNaN(BodyManagement)
                  ? "0"
                  : BodyManagement}
                %
              </h3>
              <h3>
                Object Control&nbsp;
                {ObjectControl == undefined || isNaN(ObjectControl)
                  ? "0"
                  : ObjectControl}
                %
              </h3>
            </div>
            <div className="resultPPs">
              <h3>
                {isNaN((resultPP * Locomotor) / 100)
                  ? "0"
                  : (resultPP * Locomotor) / 100}{" "}
                PP
              </h3>
              <h3>
                {isNaN((resultPP * BodyManagement) / 100)
                  ? "0"
                  : (resultPP * BodyManagement) / 100}{" "}
                PP
              </h3>
              <h3>
                {isNaN((resultPP * ObjectControl) / 100)
                  ? "0"
                  : (resultPP * ObjectControl) / 100}{" "}
                PP
              </h3>
            </div>
          </div>
          <Link to="/student/trainings">
            <div className="resultPPBtn">
              <button>Continue</button>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}
