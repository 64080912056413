import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  Tab,
  Tabs,
  DialogContent,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import PropTypes from "prop-types";

//core module
import Ongoing from "./UpcomingTab";
import Compelte from "./CompletedTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function Profile() {
  const { state } = useLocation();
  const [value, setValue] = React.useState(state || 0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          backgroundColor: " #cddc39",
        }}
      >
        <Grid container>
          <Grid container item>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              // justifyContent="center"
              alignItems="center"
            >
              <div className="BackHead ">
                <IoIosArrowBack onClick={() => navigate(-1)} />{" "}
              </div>
              <div className="WhiteColorTypography CourseTypo">Training</div>
              <div> </div>
            </Grid>

            <Grid container className="TabGrid">
              <Grid item conatiner>
                <Tabs
                  value={value}
                  onChange={TabChange}
                  aria-label="basic tabs example"
                  className="AResultTabStyle"
                >
                  <Tab
                    className="AResultTabChanges"
                    label="Upcoming"
                    {...a11yProps(0)}
                  />
                  <Tab
                    className="AResultTabChanges"
                    label="Completed"
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <DialogContent
        style={{
          justifyContent: "center",
          marginLeft: "10px",
          marginRight: "10px",
        }}
        dividers={false}
        className="TabPanelStyle"
      >
        <TabPanel value={value} index={0}>
          <Ongoing />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Compelte />
        </TabPanel>
      </DialogContent>
    </>
  );
}
