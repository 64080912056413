import React, { useState, useEffect } from "react";
import { Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import moment from "moment";

/* for course */
import { BiBookBookmark } from "react-icons/bi";
import { MdLeaderboard } from "react-icons/md";
import { BsJournalBookmark } from "react-icons/bs";
import { AiOutlineVerified } from "react-icons/ai";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { FETCH_URL } from "../../../fetchIp";
import { useSnackbar } from "notistack";
import LoadingSpinner from "../../Spinner/LoadingSpinner";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";

export default function Notifications() {
  const navigate = useNavigate();
  const [notification, setNotification] = useState([]);
  const serviceUserId = localStorage.getItem("userId");
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [notifLength, setNotifLength] = useState(0);
  const role = localStorage.getItem("role");
  const path = role == 5 ? "/student" : "/explore";
  const [learner, setLearner] = useState();
  const [page, setPage] = useState(1);
  const [isLoader, setIsLoader] = useState(true);
  const [isFetching, setIsFetching] = useState(Array.from({ length: 6 }));

  const currentDate = moment().format("DD-MM-YYYY");

  const showAlert = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant,
      autoHideDuration: 2000,
    });
  };

  const getAllNotification = async () => {
    try {
      const resp = await fetch(
        `${FETCH_URL}/api/lessonplan/notification/getSingleNotificationByUserId/${serviceUserId}?pagination=true&page=${page}&limit=9`,
        {
          method: "GET",
          "Content-type": "application/json",
        }
      );

      const res = await resp.json();
      console.log("Notification Data", res);
      if (resp.ok) {
        setNotification((pre) => [...pre, ...res?.msg]);
        if (res?.msg?.length < 9) setIsLoader(false);

        let count = 0;
        res?.msg?.map((item) => {
          if (!item?.status) count++;
        });

        if (count > 0) readNotification();
        // setNotifLength(count);
        console.log("Notification Length: ", count);
      }
      setIsLoading(false);
    } catch (err) {
      console.log("Error is ", err);
      setIsLoading(false);
    }
  };

  const clearNotification = async () => {
    setIsLoading(true);

    try {
      const resp = await fetch(
        `${FETCH_URL}/api/lessonplan/notification/clearAllNotificationByUserId/${serviceUserId}`,
        {
          method: "DELETE",
          "Content-type": "application/json",
        }
      );

      const res = await resp.json();

      if (resp.ok) {
        setIsLoader(false);
        setNotification([]);
        setNotifLength(0);

        showAlert(res?.msg, "success");
      } else {
        showAlert(res?.msg, "error");
      }
      setIsLoading(false);
    } catch (err) {
      console.log("Error is : ", err);
      setIsLoading(false);
    }
  };

  const readNotification = async () => {
    setIsLoading(true);

    try {
      const resp = await fetch(
        `${FETCH_URL}/api/lessonplan/notification/readAllNotificationByUserId/${serviceUserId}`,
        {
          method: "PATCH",
          "Content-type": "application/json",
        }
      );

      console.log("clear all notification: ", await resp.json());
    } catch (err) {
      console.log("Error is : ", err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log("ReadNotification", notifLength);
    // if (notifLength > 0) readNotification();
    // getAllNotification();
  }, []);

  function getSingleLearner() {
    axios
      .get(`/api/learner/getSingleLearner/${serviceUserId}`)
      .then((resp) => {
        setLearner(resp?.data?.msg);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  const getSingleLearnerExplore = async () => {
    // setIsLoading(true);
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/auth/getSingleNormalLearner/${serviceUserId}`,
        {
          method: "GET",
          headers: {
            "Content-type": "application/json",
          },
        }
      );

      const res = await responce.json();
      setLearner(res?.data);
      // setIsLoading(false);
    } catch (err) {
      console.log("Error is ", err);
      setIsLoading(false);
    }
  };

  const nextPage = (url, item, courseType, isAssignedLesson) => {
    console.log("Navigate data is ", `/student/${url}`, `/student/${url}`, {
      state: item,
    });
    if (courseType == "course" || courseType == "expiredCourse")
      navigate(`/student/${url}`, { state: { data: item } });
    else if (courseType == "lessonplan" || courseType == "expiredLessonplan")
      navigate(`/student/${url}`, { state: item });
    else navigate(`/student/${url}`);
  };

  const handleisFetching = () => {
    setTimeout(() => {
      setPage((prev) => prev + 1);
      setIsFetching(isFetching.concat(Array.from({ length: 6 })));
    }, 500);
  };

  useEffect(() => {
    getAllNotification();
  }, [page]);

  return (
    <>
      {isLoading ? <LoadingSpinner /> : null}
      <Grid
        container
        style={{
          height: "92.8vh",
        }}
      >
        <Grid
          xs={12}
          className="bg-cddc39 space-between p-fixed w-100 header-padding"
        >
          <ArrowBackIosRoundedIcon
            onClick={() => navigate(-1)}
            className="text-white font-w-700 font-18"
          />

          <Typography className="font-w-700 text-center text-white">
            Notifications
          </Typography>

          <Typography
            className="font-w-700  font-14"
            onClick={() => clearNotification()}
          >
            Clear all
          </Typography>
        </Grid>

        {console.log("NLength: ", notification?.msg?.length)}

        <Grid container className="h-99v notification-container">
          <Grid
            className={
              notification?.length != 0
                ? "d-none"
                : "h-100per h-100vh d-flex-center justify-content-center w-100"
            }
          >
            <Typography className="color-B5B5C3 align-center justify-content-center font-w-700">
              No Notification
            </Typography>
          </Grid>

          <InfiniteScroll
            style={{ marginTop: "70px", marginBottom: "20px" }}
            dataLength={isFetching.length}
            next={handleisFetching}
            hasMore={isLoader}
            scrollableTarget="scrollableDiv"
            className={notification?.length != 0 ? "w-100" : "d-none"}
            loader={
              <Typography fontWeight={"700"} className="text-center">
                Loading...
              </Typography>
            }
          >
            {notification?.map((item, key) => {
              let type = "New Lesson Plan";
              let IconsImg = BsJournalBookmark;

              let isExpired =
                currentDate > moment(item?.data?.LPEndDate).format("DD-MM-YYYY")
                  ? true
                  : false;
              let url = isExpired ? "trainingcomp" : "trainingstart";

              let isAssignedLesson = true;

              if (item?.message?.type == "course") {
                type = "New Course";
                isAssignedLesson = false;

                isExpired =
                  currentDate >
                  moment(item?.data?.endDate).format("DD-MM-YYYY");

                url = isExpired ? "completelevel" : "startlevel";
                IconsImg = BiBookBookmark;
              } else if (item?.message?.type == "assessment") {
                url = "assesmentnew";
                type = "New Assessment";
                IconsImg = MdLeaderboard;
                isAssignedLesson = false;
              } else if (item?.message?.type == "equipment") {
                type = "New Equipment";
                IconsImg = MdLeaderboard;
                isAssignedLesson = false;
              } else if (item?.message?.type == "expiredCourse") {
                url = "completelevel";
                type = "Expired Course";
                IconsImg = MdLeaderboard;
                isAssignedLesson = false;
              } else if (item?.message?.type == "expiredLessonplan") {
                url = "trainingcomp";
                type = "Expired Lesson Plan";
                IconsImg = MdLeaderboard;
                isAssignedLesson = true;
              }

              return (
                <>
                  <Grid
                    container
                    mt={1}
                    className={key == 0 ? "bg-white " : "bg-white"}
                    p={"10px 12px"}
                    key={key}
                    onClick={() =>
                      nextPage(
                        url,
                        item?.data,
                        item?.message?.type,
                        isAssignedLesson
                      )
                    }
                  >
                    <Grid xs={2} className="align-center d-flex">
                      <Grid className="notification-them-img">
                        <IconsImg className="text-white font-22" />
                      </Grid>
                    </Grid>

                    <Grid
                      className="space-between overflow-hidden w-100"
                      p={"0px 12px 4px 0px"}
                      xs={7}
                    >
                      <Grid className="w-100">
                        <Typography className="font-w-700 font-14 color-black first-cap">
                          {type}
                        </Typography>

                        <Typography className="font-12 color-black text-484848  first-cap">
                          {item?.message?.notification}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid xs={3} pl={"4px"}>
                      <Typography className="font-w-700 font-12 align-center color-B5B5C3">
                        {moment(item?.message?.date).calendar()}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              );
            })}
          </InfiniteScroll>
        </Grid>
      </Grid>
    </>
  );
}
