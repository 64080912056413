import React, { useState, useEffect } from "react";
import { Typography, Grid, Button } from "@mui/material";
import "react-calendar/dist/Calendar.css";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import Rect from "../../../assets/img/Rectangle 3007.png";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import cross from "../../../assets/img/Vector (32).png";
import { useLocation } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state.item;
  console.log(data, "i am data");

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="Image">
        <IoIosArrowBack className="BackHeadEve" onClick={() => navigate(-1)} />
        <div className="imgHeart">
          <img src={cross} onClick={() => navigate(-1)} />
        </div>
        <Grid container item className="Catchingdiv">
          <Typography
            fontSize="18px"
            className="WhiteColorTypography"
            style={{ paddingTop: "23px", fontWeight: "900" }}
          >
            {data.eventName}

            <br />
            <sapn>
              <Typography fontSize="12px" className="WhiteColorTypography">
                {data.dateOfCreation} - {data.endDate}
              </Typography>
            </sapn>
          </Typography>
        </Grid>
      </div>
      <Grid>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.1)",
              borderColor: "divider",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              indicatorColor="none"
            >
              <Tab label="Overview" {...a11yProps(0)} className="colorTab" />
              <Tab label="Guidelines" {...a11yProps(1)} className="colorTab" />
              <Tab label="Rules" {...a11yProps(2)} className="colorTab" />
              <Tab label="Rewards" {...a11yProps(3)} className="colorTab" />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <Grid container className="navGrid">
              <Typography className="aboutText">About</Typography>
              <Typography
                className="GreyColorTypography"
                style={{
                  fontSize: "12px",
                  paddingTop: "5px",
                  paddingBottom: "10px",
                }}
              >
                {data.eventDetails}
              </Typography>
            </Grid>

            <hr className="hrGrid" />
            <Grid className="navBoxes" xs={12}>
              <Typography
                paddingTop="10px"
                paddingBottom="2%"
                fontWeight="bold"
                color="#3A3A3B"
              >
                You’ll Get
              </Typography>

              <Grid container item className="onquestioncard">
                <Grid item xs={2}>
                  <Typography align="center">
                    <div className="backlevels">
                      <img style={{ marginTop: "0px" }} src={Rect} />
                      <div className="levelsPP">
                        <h3>250PP</h3>
                      </div>
                    </div>
                  </Typography>
                </Grid>
                <Grid item xs={9} className="paddings">
                  <Grid container item justifyContent="space-between">
                    <Typography
                      fontSize="15px"
                      marginTop="10px"
                      className="GreyColorTypography"
                    >
                      Play Points
                    </Typography>
                  </Grid>
                  <Typography
                    fontSize="11px"
                    width="200px"
                    className="GreyColorTypo"
                  >
                    More the play points, more the rank.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <div className="joinsBtn">
              <button>Completed</button>
            </div>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <Grid container className="navGrid">
              <Typography className="aboutText">Guidelines</Typography>
              <Typography
                className="GreyColorTypography"
                style={{
                  fontSize: "12px",
                  paddingTop: "5px",
                  paddingBottom: "10px",
                }}
              >
                {data.eventGuidelines}
              </Typography>
            </Grid>

            <hr className="hrGrid" />
            <Grid className="navBoxes" xs={12}>
              <Typography
                paddingTop="10px"
                paddingBottom="2%"
                fontWeight="bold"
                color="#3A3A3B"
              >
                You’ll Get
              </Typography>

              <Grid container item className="onquestioncard">
                <Grid item xs={2}>
                  <Typography align="center">
                    <div className="backlevels">
                      <img style={{ marginTop: "0px" }} src={Rect} />
                      <div className="levelsPP">
                        <h3>250PP</h3>
                      </div>
                    </div>
                  </Typography>
                </Grid>
                <Grid item xs={9} className="paddings">
                  <Grid container item justifyContent="space-between">
                    <Typography
                      fontSize="15px"
                      marginTop="10px"
                      className="GreyColorTypography"
                    >
                      Play Points
                    </Typography>
                  </Grid>
                  <Typography
                    fontSize="11px"
                    width="200px"
                    className="GreyColorTypo"
                  >
                    More the play points, more the rank.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <div className="joinsBtn">
              <button>Completed</button>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Grid container className="navGrid">
              <Typography className="aboutText">Rules</Typography>
              <Typography
                className="GreyColorTypography"
                style={{
                  fontSize: "12px",
                  paddingTop: "5px",
                  paddingBottom: "10px",
                }}
              >
                {data.eventRules}
              </Typography>
            </Grid>

            <hr className="hrGrid" />
            <Grid className="navBoxes" xs={12}>
              <Typography
                paddingTop="10px"
                paddingBottom="2%"
                fontWeight="bold"
                color="#3A3A3B"
              >
                You’ll Get
              </Typography>

              <Grid container item className="onquestioncard">
                <Grid item xs={2}>
                  <Typography align="center">
                    <div className="backlevels">
                      <img style={{ marginTop: "0px" }} src={Rect} />
                      <div className="levelsPP">
                        <h3>250PP</h3>
                      </div>
                    </div>
                  </Typography>
                </Grid>
                <Grid item xs={9} className="paddings">
                  <Grid container item justifyContent="space-between">
                    <Typography
                      fontSize="15px"
                      marginTop="10px"
                      className="GreyColorTypography"
                    >
                      Play Points
                    </Typography>
                  </Grid>
                  <Typography
                    fontSize="11px"
                    width="200px"
                    className="GreyColorTypo"
                  >
                    More the play points, more the rank.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <div className="joinsBtn">
              <button>Completed</button>
            </div>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Grid container className="navGrid">
              <Typography className="aboutText">Rewards</Typography>
              <Typography
                className="GreyColorTypography"
                style={{
                  fontSize: "12px",
                  paddingTop: "5px",
                  paddingBottom: "10px",
                }}
              >
                {data.rewards}
              </Typography>
            </Grid>

            <hr className="hrGrid" />
            <Grid className="navBoxes" xs={12}>
              <Typography
                paddingTop="10px"
                paddingBottom="2%"
                fontWeight="bold"
                color="#3A3A3B"
              >
                You’ll Get
              </Typography>

              <Grid container item className="onquestioncard">
                <Grid item xs={2}>
                  <Typography align="center">
                    <div className="backlevels">
                      <img style={{ marginTop: "0px" }} src={Rect} />
                      <div className="levelsPP">
                        <h3>250PP</h3>
                      </div>
                    </div>
                  </Typography>
                </Grid>
                <Grid item xs={9} className="paddings">
                  <Grid container item justifyContent="space-between">
                    <Typography
                      fontSize="15px"
                      marginTop="10px"
                      className="GreyColorTypography"
                    >
                      Play Points
                    </Typography>
                  </Grid>
                  <Typography
                    fontSize="11px"
                    width="200px"
                    className="GreyColorTypo"
                  >
                    More the play points, more the rank.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <div className="joinsBtn">
              <button>Completed</button>
            </div>
          </TabPanel>
        </Box>
      </Grid>
    </>
  );
}
