import React, { useState, useEffect } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
import { FETCH_URL } from "../../../../fetchIp";
import { useAuth } from "../../../../Context/AuthContext";
import { Replay10Sharp } from "@material-ui/icons";

export default function Profile() {
  const [lessonPlanData, setLessonPlanData] = useState([]);
  const { trainerId } = useAuth();

  const getLessonPlanByLessonPlanIds = async (lessonPlanIds) => {
    try {
      let lpOlddata;
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/getLessonPlanByLessonPlanids`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({ lessonPlanIds }),
        }
      );
      const res = await response.json();

      if (response.ok) {
        lpOlddata = res.msg;
        console.log("Learners Data =======>", res.msg);
      } else {
        console.error("API error =====>", res.err);
      }

      const response2 = await fetch(
        `${FETCH_URL}/api/assessment/getResultByLessonPlanIds`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({ lessonPlanIds }),
        }
      );

      const res2 = await response2.json();
      if (response2.ok) {
        // setLessonPlanData(res2.msg);

        for (let i = 0; i < res.msg.length; i++) {
          let lpdata = res2.msg.find(
            (data) => data.lessonPlanId === res.msg[i]._id
          );
          console.log("lpData", lpdata);
          if (lpdata) {
            lpOlddata[i].assignedPP = lpdata.assignedPP;
            lpOlddata[i].resultPP = lpdata.resultPP;
            lpOlddata[i].totalScore = lpdata.totalScore;
          }
        }

        console.log("Learners Data =======>", res.msg);
      } else {
        console.error("API error =====>", res.err);
      }

      console.log("jdodfjodjf", lpOlddata);
      setLessonPlanData(lpOlddata);
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  const getSingleTrainer = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/getSingleTrainer/${trainerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        const upcomingLesson = res.msg.assignedLessonPlans.filter(
          (data) => data.completion == true
        );

        await getLessonPlanByLessonPlanIds(upcomingLesson);

        console.log("LessonPlan Data =======>", res.msg);
        console.log("upcoming Lesson plan =======>", upcomingLesson);
      } else {
        console.error("API error =====>", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  useEffect(async () => {
    await getSingleTrainer();
  }, []);

  return (
    <>
      {lessonPlanData.map((data, key) => (
        <Grid container spacing={2} className="OnGoingConatiner">
          <Grid item xs={4}>
            <Grid item>
              <img
                src={data.LPThumbnail}
                width="80"
                height="60"
                style={{ borderRadius: 10, margin: 10 }}
              />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Typography className="GreyColorTypography">
              {data.LPTitle}{" "}
            </Typography>
            <Typography className="lightGreyColorTypo Fs-0-8">
              {data.LPUid}
            </Typography>
            <Typography className="lightGreyColorTypo Fs-0-8">
              {data.CourseData}
            </Typography>
            <Link to="/student/trainingprofile" state={data}>
              <Typography
                align="center"
                className="GreenButton700 ViewCourseButton"
                style={{ width: "100%" }}
              >
                See Details
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Typography align="center" className=" PPCourseButton">
              {/* 11111 PP */}
              {data.resultPP}/{data.assignedPP} PP
            </Typography>
          </Grid>
        </Grid>
      ))}
    </>
  );
}
