import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Progress } from "react-sweet-progress";
import card from "../../../assets/img/Rectangle 2934.png";
import { FETCH_URL } from "../../../fetchIp";
import { useAuth } from "../../../Context/AuthContext";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Grid,
  Typography,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@mui/material";
import LoadingSpinner from "../../Spinner/LoadingSpinner";
import moment from "moment";

export default function OngoingTR() {
  const [lessonPlanData, setLessonPlanData] = useState([]);
  const { trainerId } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getLessonPlanByLessonPlanIds = async (lessonPlanIds) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${FETCH_URL}/api/lessonplan/getLessonPlanByLessonPlanids`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ lessonPlanIds }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        setLessonPlanData(res.msg);
        console.log("Learners Data =======>", res.msg);
      } else {
        console.error("API error =====>", res.err);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Catch block ====>", error);
      setIsLoading(false);
    }
  };

  const getSingleTrainer = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/getSingleTrainer/${trainerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const res = await response.json();
      console.log("Response is home page", res);
      if (response.ok) {
        let currentDate = moment().format();

        const upcomingLesson = res?.msg?.assignedLessonPlans?.filter(
          (data) => currentDate <= moment(data?.expireOn).format()
        );

        console.log("Upcoming is ", upcomingLesson);
        getLessonPlanByLessonPlanIds(upcomingLesson);
      } else {
        console.error("API error =====>", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSingleTrainer();
  }, []);

  console.log(lessonPlanData, "upcoming lessonplandata all details");

  return (
    <>
      {isLoading ? <LoadingSpinner /> : null}

      {lessonPlanData.length == 0 && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              height: "70vh",
            }}
          >
            <span className="fs-14 text-757575">
              There is no assigned lessonplan
            </span>
          </div>
        </>
      )}

      {/* <Link to="/student/trainingstart" state={user}>
        <div className="playCard">
          <div className="artsPP">
            <p> {user.LPXp} PP</p>
            <img src={user.LPThumbnail} alt="..." />
          </div>
          <div className="arts">
            <h2> {user.LPTitle}</h2>

          </div>

        </div>
        {loading && <CircularProgress />}
      </Link> */}

      {lessonPlanData.map((user, key) => (
        <Grid p={1}>
          <Link to="/student/trainingstart" key={key} state={user}>
            <Card sx={{ width: "100%" }} className="p-relative">
              <Typography className="p-absolute course-pp zIndex-10">
                {user?.LPXp} PP
              </Typography>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="140"
                  image={user?.LPThumbnail}
                  alt=""
                />
                <CardContent p="10px">
                  <Typography
                    gutterBottom
                    className="font-w-700 cl-title"
                    component="div"
                  >
                    {user?.LPTitle}
                  </Typography>
                  <div className="artsPro">
                    <Progress
                      percent={user?.completion ? 100 : 0}
                      className="progressbar"
                    />
                  </div>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>
        </Grid>
      ))}
    </>
  );
}
