import React, { Fragment, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  Tab,
  Tabs,
  DialogContent,
} from "@mui/material";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { AiOutlineCamera } from "react-icons/ai";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Academic from "./Academic";
import ContactNew from "./ContactNew";
import { useAuth } from "../../../Context/AuthContext";
import { useSnackbar } from "notistack";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import moment from "moment";
import LoadingSpinner from "../../Spinner/LoadingSpinner";
import { RxAvatar } from "react-icons/rx";
import Avatar from "@mui/material/Avatar";
import { FETCH_URL } from "../../../fetchIp";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import axios from "axios";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const initialState = { alt: "", src: "" };

export default function Account() {
  const [file, setFile] = useState("");
  const { userDetails } = useAuth();

  const isDisable = true;
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");
  const [username, setUsername] = useState("");

  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [img, setImg] = useState("");
  const [gender, setGender] = useState("");
  const { trainerId } = useAuth();
  const [regNo, setRegNo] = useState("");
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  function handleImg(event) {
    setFile(event.target.files[0]);
    console.log(event.target.files[0]);
  }

  const [{ alt, src }, setPreview] = useState(initialState);
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  const showAlert = (msg, alertType) => {
    enqueueSnackbar(msg, {
      variant: alertType,
      autoHideDuration: 3000,
    });
  };

  const getSingleTrainer = async () => {
    try {
      const resp = await axios.get(
        `${FETCH_URL}/api/learner/getSingleTrainer/${trainerId}`
      );
      setName(resp?.data?.msg?.trainerDetail?.fullName);
      setGender(resp?.data?.msg?.trainerDetail?.gender?.toLowerCase());
      setUsername(resp?.data?.msg?.trainerDetail?.regNo);
      setImg(resp?.data?.msg?.trainerDetail?.trainerImg);
      setRegNo(resp?.data?.msg?.trainerDetail?.regNo);

      let date = moment(resp?.data?.msg?.trainerDetail?.dob).format(
        "YYYY-MM-DD"
      );
      setDob(date);

      console.log("Data is ", resp);
    } catch (err) {}
  };

  const Input = styled("input")({
    display: "none",
  });

  const fileHandler = (event) => {
    const { files } = event.target;
    setPreview(
      files.length
        ? {
            src: URL.createObjectURL(files[0]),
            alt: files[0].name,
          }
        : initialState
    );
  };

  async function ImageUploader(e) {
    setIsLoading(true);

    const formdata = new FormData();
    formdata.set("trainerId", trainerId);
    formdata.set("regNo", regNo);
    formdata.append("file", e.target.files[0]);

    try {
      const responce = await fetch(
        `${FETCH_URL}/api/learner/uploadTrainerImage`,
        {
          method: "POST",
          headers: {},
          body: formdata,
        }
      );
      let res = await responce.json();
      console.log("Response is ", res);
      if (responce.ok) {
        showAlert(res?.msg, "success");
        getSingleTrainer();
      } else {
        showAlert(res?.err, "error");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getSingleTrainer();
  }, []);

  return (
    <>
      {isLoading ? <LoadingSpinner /> : null}
      <div className="accountCont">
        <Grid container direction="row">
          <div className="BackNotificaton">
            <IoIosArrowBack onClick={() => navigate(-1)} />
          </div>
        </Grid>
        <Typography
          className="GreyColorTypography"
          sx={{ pl: "19px", fontSize: "1.5rem" }}
        >
          Account
        </Typography>

        {console.log("name and image ", name, img)}
        <div className="Settingridcontainer pb-10px">
          <div className="profile-img">
            <Stack direction="row" spacing={2}>
              <Avatar
                alt={name?.[0]?.toUpperCase()}
                src={img?.length == 0 || img == undefined ? RxAvatar : img}
                sx={{ height: "100px", width: "100px", fontSize: "45px" }}
              />
            </Stack>

            <Grid item className="ChooseFileButtonItem">
              <input
                style={{ display: "none" }}
                type="file"
                id="raised-button-file"
                accept="image/*"
                onChange={ImageUploader}
              />
            </Grid>

            <label
              htmlFor="raised-button-file"
              className="d-flex justify-content-center"
            >
              <span
                size="small"
                className="bg-CDDC39 camera-btn"
                sx={{ marginTop: "-20px !important" }}
              >
                <CameraAltIcon
                  className="text-white"
                  sx={{ fontSize: "15px !important" }}
                />
              </span>
            </label>

            <Stack alignItems="center" spacing={2} className="d-none">
              <Input
                type="file"
                onChange={handleImg}
                style={{ display: "none" }}
                id="uploadImg"
                // readOnly
              />
              <label htmlFor="uploadImg">
                <AiOutlineCamera className="Camera-img" />
              </label>
            </Stack>
          </div>

          <form>
            {console.log("Username is ", username, gender)}
            <div className="GreyColorTypography formInfo">
              <label>Username</label>
              <input
                type="text"
                placeholder={userDetails?.learnerDetail?.fullName}
                value={username}
                readOnly={true}
                className={"outline-noen pl-15"}
              />
            </div>
            {/* ) : null} */}

            <div className="GreyColorTypography formInfo">
              <label>Full Name</label>
              <input
                type="text"
                placeholder="Enter Full Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                readOnly={isDisable}
                className={isDisable ? "outline-noen pl-15" : "pl-15"}
              />
            </div>

            <Grid
              mt={"10px"}
              mb={"5px !important"}
              display={"greed"}
              justifyContent={"center"}
            >
              <FormControl
                className="d-flex justify-content-center"
                sx={{ marginLeft: "-20px" }}
              >
                <label
                  id="demo-row-radio-buttons-group-label"
                  className="gender"
                >
                  Gender
                </label>

                <RadioGroup
                  value={gender}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className="d-flex justify-content-center"
                  onChange={(e) => setGender(e.target.value)}
                >
                  <FormControlLabel
                    disabled={isDisable ? true : false}
                    value="female"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    disabled={isDisable ? true : false}
                    value="male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    disabled={isDisable ? true : false}
                    value="other"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {console.log("DOB: ", dob)}
            <div className="GreyColorTypography formInfo">
              <label>Date of birth</label>
              <input
                type="date"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
                readOnly={isDisable}
                className={isDisable ? "outline-noen pl-15" : "pl-15"}
              />
            </div>

            {/* <div className={"account-update-btn"}>
              <button className="updateProfile">Update</button>
            </div> */}
          </form>
        </div>

        <div>
          <Grid container className="TabGrid" paddingTop="20px">
            <Grid item conatiner className="GreyColorTypography">
              <Tabs
                value={value}
                onChange={TabChange}
                aria-label="basic tabs example"
                className="sets"
              >
                <Tab
                  className="AResultTabChanges"
                  label="Academic"
                  {...a11yProps(0)}
                />
                <Tab
                  className="AResultTabChanges"
                  label="Contact"
                  {...a11yProps(1)}
                />
              </Tabs>
            </Grid>
          </Grid>
        </div>
        <div className="tabsBtns">
          <DialogContent
            style={{
              marginTop: "24px",
              justifyContent: "center",
            }}
            dividers={false}
            className="TabPanelStyle"
          >
            <TabPanel value={value} index={0}>
              <Academic />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ContactNew />
            </TabPanel>
          </DialogContent>
        </div>
      </div>
    </>
  );
}
