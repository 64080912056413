import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  Tab,
  Tabs,
  DialogContent,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import { VscBellDot } from "react-icons/vsc";
import { FETCH_URL } from "../../../../fetchIp";

export default function Profile() {
  const locationData = useLocation();
  const AssessmentData = locationData?.state;
  const [learnersData, setLearnersData] = useState([]);

  const getLearnersByLernersIds = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/getLearnerbylearnersids`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            learnersIds: AssessmentData.assignedLearners,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        setLearnersData(res.msg);
        console.log("Learners Data =======>", res.msg);
      } else {
        console.error("API error =====>", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  useEffect(async () => {
    await getLearnersByLernersIds();
  }, [locationData?.state]);

  const [value, setValue] = React.useState(0);

  const navigate = useNavigate();

  const [location, setLocation] = React.useState("");
  const locChange = (event) => {
    setLocation(event.target.value);
  };
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <>
      <div
        style={{
          backgroundColor: " #cddc39",
          borderBottomRightRadius: "25px",
          borderBottomLeftRadius: "25px",
          height: "140px",
        }}
      >
        <Grid container>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: "20px" }}
          >
            <div className="BackHead ">
              <IoIosArrowBack onClick={() => navigate(-1)} />
            </div>
            <div className="WhiteColorTypography MyclassesTypo">Assessment</div>
            <div>
              <VscBellDot className="BellIcon" />
            </div>
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={2} className="CourseProfileConatiner">
        <Grid item xs={4}>
          <Grid item>
            <img
              src={AssessmentData.assessmentImage}
              width="80"
              height="60"
              style={{ borderRadius: 10, margin: 10 }}
            />
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Typography className="GreyColorTypography">
            {AssessmentData.assessmentName}
          </Typography>
          <Typography className="lightGreyColorTypo Fs-0-8">
            {AssessmentData.uid}
          </Typography>
          <Typography className="lightGreyColorTypo Fs-0-8">
            12/03/2022
          </Typography>
        </Grid>
      </Grid>
      <Typography className="GreyColorTypography StudneListTypo">
        Student List
      </Typography>
      <Grid
        container
        style={{
          marginLeft: "15px",
          width: "93%",
          backgroundColor: "white",
          borderRadius: "10px ",
        }}
      >
        <div className="ResponsiveTable">
          <Table className="Tableroot">
            <TableHead>
              <TableRow className="Head">
                <TableCell className="ColHead">Roll no. </TableCell>
                <TableCell className="ColHead"> Name</TableCell>
                <TableCell className="ColHead">FMS Score</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {learnersData.map((user, key) => (
                <TableRow>
                  <TableCell className="Col700">
                    {user.learnerDetail.regNo}
                  </TableCell>
                  <TableCell className="Col700">
                    <Link
                      to="/student/assesmentresult"
                      className="LinkStyle"
                      state={{ user, AssessmentData }}
                    >
                      {user.learnerDetail.fullName}
                    </Link>
                  </TableCell>
                  <TableCell className="Col700">0</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Grid>
    </>
  );
}
