import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Switch from "@mui/material/Switch";
import WifiIcon from "@mui/icons-material/Wifi";
import BluetoothIcon from "@mui/icons-material/Bluetooth";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import Divider from "@mui/material/Divider";

export default function SwitchListSecondary() {
  const [checked, setChecked] = React.useState(["wifi"]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const navigate = useNavigate();

  return (
    <Grid container>
      <Grid container direction="row">
        <div className="BackNotificaton">
          <IoIosArrowBack onClick={() => navigate(-1)} />
        </div>
      </Grid>
      <Typography
        className="GreyColorTypography"
        sx={{ pl: "19px", fontSize: "1.5rem" }}
      >
        Notification
      </Typography>
      <Grid container className="Settingridcontainer">
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          subheader={<ListSubheader></ListSubheader>}
        >
          <ListItem>
            <ListItemText
              className=" NotificationText "
              id="switch-list-label-wifi"
              primary="Show notifications"
            />
            <Switch
              className="NotificationSwitch"
              edge="end"
              onChange={handleToggle("wifi")}
              checked={checked.indexOf("wifi") !== -1}
              inputProps={{
                "aria-labelledby": "switch-list-label-wifi",
              }}
            />{" "}
          </ListItem>{" "}
          <Divider className="DividerStyle" />
          <ListItem>
            <ListItemText
              className=" NotificationText "
              id="switch-list-label-bluetooth"
              primary="Course Notification"
            />
            <Switch
              className="NotificationSwitch"
              edge="end"
              onChange={handleToggle("bluetooth")}
              checked={checked.indexOf("bluetooth") !== -1}
              inputProps={{
                "aria-labelledby": "switch-list-label-bluetooth",
              }}
            />
          </ListItem>{" "}
          <Divider className="DividerStyle" />
          <ListItem>
            <ListItemText
              className=" NotificationText "
              id="switch-list-label-bluetooth"
              primary="Assessment Notifications"
            />
            <Switch
              className="NotificationSwitch"
              edge="end"
              onChange={handleToggle("bluetooth")}
              checked={checked.indexOf("bluetooth") !== -1}
              inputProps={{
                "aria-labelledby": "switch-list-label-bluetooth",
              }}
            />
          </ListItem>{" "}
          <Divider className="DividerStyle" />
          <ListItem>
            <ListItemText
              className=" NotificationText "
              id="switch-list-label-bluetooth"
              primary="Event Notifications"
            />
            <Switch
              className="NotificationSwitch"
              edge="end"
              onChange={handleToggle("bluetooth")}
              checked={checked.indexOf("bluetooth") !== -1}
              inputProps={{
                "aria-labelledby": "switch-list-label-bluetooth",
              }}
            />
          </ListItem>{" "}
          {/* <Divider className="DividerStyle" />
          <ListItem>
            <ListItemText
              className=" NotificationText "
              id="switch-list-label-bluetooth"
              primary="Allow Sound"
            />
            <Switch
              className="NotificationSwitch"
              edge="end"
              onChange={handleToggle("bluetooth")}
              checked={checked.indexOf("bluetooth") !== -1}
              inputProps={{
                "aria-labelledby": "switch-list-label-bluetooth",
              }}
            />
          </ListItem>{" "}
          <Divider className="DividerStyle" />
          <ListItem>
            <ListItemText
              className=" NotificationText "
              id="switch-list-label-bluetooth"
              primary="Allow Vibration"
            />
            <Switch
              className="NotificationSwitch"
              edge="end"
              onChange={handleToggle("bluetooth")}
              checked={checked.indexOf("bluetooth") !== -1}
              inputProps={{
                "aria-labelledby": "switch-list-label-bluetooth",
              }}
            />
          </ListItem>{" "}
          <Divider className="DividerStyle" />
          <ListItem>
            <ListItemText
              className=" NotificationText "
              id="switch-list-label-bluetooth"
              primary="Floating Notification"
            />
            <Switch
              className="NotificationSwitch"
              edge="end"
              onChange={handleToggle("bluetooth")}
              checked={checked.indexOf("bluetooth") !== -1}
              inputProps={{
                "aria-labelledby": "switch-list-label-bluetooth",
              }}
            />
          </ListItem>{" "} */}
        </List>
      </Grid>
    </Grid>
  );
}
