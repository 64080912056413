import React, { useState, useEffect } from "react";
import rank from "../../../assets/img/rank2.png";
import ellipse from "../../../assets/img/Ellipse 720.png";
import { Grid, Typography } from "@mui/material";
import { FETCH_URL } from "../../../fetchIp";
import axios from "axios";
import leaderboardImg from "../../../assets/img/leader.png";
import LoadingSpinner from "../../Spinner/LoadingSpinner";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Stack, Avatar } from "@mui/material";
import { RxAvatar } from "react-icons/rx";

export default function StudentLB() {
  const [active, setActive] = useState("");
  const [leaderData, setLeaderData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState("All Time");

  function leader(data, weekly = null, daily = null) {
    data === null ? setFilter(0) : setFilter(data);
    if (data == "weekly") weekly = data;
    else if (data == "daily") daily = data;
    console.log("Leaderboard is", {
      weekly,
      daily,
      data,
    });
    setIsLoading(true);

    // `${FETCH_URL}/api/assessment/leaderboard/learner`
    axios
      .get(
        `${FETCH_URL}/api/assessment/leaderboard/total/learners?daily=${daily}&weekly=${weekly}`
      )
      .then((resp) => {
        console.log(resp.data.agreg, "leaderboard student data");
        setLeaderData(resp.data.agreg);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);

        console.log(err);
      });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    leader(null);
  }, []);

  return (
    <>
      {isLoading ? <LoadingSpinner /> : null}
      <div>
        <div
          style={{
            width: "89%",
            marginLeft: "20px",
          }}
        >
          <Box className="d-flex-center">
            <FormControl className="drop-down">
              <InputLabel shrink id="demo-simple-select-label">
                &nbsp;
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filter}
                label=""
                className="select-drop-down"
                onChange={(e) => leader(e.target.value)}
              >
                <MenuItem value={0}>All Time</MenuItem>
                <MenuItem value={"weekly"}>This Week</MenuItem>
                <MenuItem value={"daily"}>Today</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {/* <div className="drops2">
            <div className="dropLead">
              <select>
                <option>Locomotor</option>
                <option>Body Management</option>
                <option>Object Control</option>
              </select>
            </div>
            <div className="dropLeadMini">
              <select>
                <option>Locomotor</option>
                <option>Body Management</option>
                <option>Object Control</option>
              </select>
            </div>
          </div> */}
          <Grid container item style={{ justifyContent: "center" }}>
            <div className="AllGP">
              <img src={leaderboardImg} alt="" height={"316px"} />

              <Stack direction="row" spacing={2}>
                <Avatar
                  className="first-avatar"
                  alt={
                    leaderData[1]?.userImg == undefined
                      ? leaderData[1]?.name?.split(" ")[0]
                      : leaderData[1]?.userImg
                  }
                  src={
                    leaderData[1]?.userImg == undefined
                      ? RxAvatar
                      : leaderData[1]?.userImg
                  }
                  sx={{ height: "50px", width: "50px", fontSize: "25px" }}
                />
              </Stack>

              <Stack direction="row" spacing={2}>
                <Avatar
                  className="second-avatar"
                  alt={
                    leaderData[0]?.userImg == undefined
                      ? leaderData[0]?.name?.split(" ")[0]
                      : leaderData[0]?.userImg
                  }
                  src={
                    leaderData[0]?.userImg == undefined
                      ? RxAvatar
                      : leaderData[0]?.userImg
                  }
                  sx={{ height: "50px", width: "50px", fontSize: "30px" }}
                />
              </Stack>

              <Stack direction="row" spacing={2}>
                <Avatar
                  className="third-avatar"
                  alt={
                    leaderData[2]?.userImg == undefined
                      ? leaderData[2]?.name?.split(" ")[0]
                      : leaderData[2]?.userImg
                  }
                  src={
                    leaderData[2]?.userImg == undefined
                      ? RxAvatar
                      : leaderData[2]?.userImg
                  }
                  sx={{ height: "50px", width: "50px", fontSize: "25px" }}
                />
              </Stack>

              <div className="GP0Leader">
                {!isNaN(leaderData[0]?.totalPP)
                  ? leaderData[0]?.totalPP?.toFixed() + " PP"
                  : ""}
                {/* {leaderData[0]?.total}PP */}
              </div>
              <div className="GP1Leader">
                {!isNaN(leaderData[1]?.totalPP)
                  ? leaderData[1]?.totalPP?.toFixed() + " PP"
                  : ""}
              </div>
              <div className="GP2Leader">
                {!isNaN(leaderData[2]?.totalPP)
                  ? leaderData[2]?.totalPP?.toFixed() + " PP"
                  : ""}
              </div>
              <div className="name0Lead">
                {leaderData[1]?.name?.split(" ")[0].slice(0, 10)}
              </div>
              <div className="name1Lead">
                {leaderData[0]?.name?.split(" ")[0].slice(0, 10)}
              </div>
              <div className="name2Lead">
                {leaderData[2]?.name?.split(" ")[0].slice(0, 10)}
              </div>
            </div>
          </Grid>
          <Grid
            item
            style={{
              height: "100%",
              borderRadius: "10px",
              backgroundColor: "#ffffff",
            }}
          >
            {leaderData.length <= 2 ? (
              <Grid className="">
                <Typography className="no-record-found">
                  {leaderData.length === 0
                    ? "No records found"
                    : "No more records found"}
                </Typography>
              </Grid>
            ) : null}
            {leaderData.map((item, key) => {
              if (key > 2) {
                return (
                  <>
                    <div
                      onClick={() => {
                        setActive(item);
                      }}
                      className={
                        active === item ? "colorChangeLeader" : "colorLeader"
                      }
                    >
                      <div className="tableInfo">
                        <div className="tableDates">
                          <div className="tableImg">
                            {/* <img src={ellipse} alt="" /> */}
                            <Stack
                              direction="row"
                              spacing={2}
                              mr={"10px"}
                              className="space-between-center"
                            >
                              <Avatar
                                alt={item?.name?.slice(0, 1)}
                                src={
                                  item?.userImg == undefined
                                    ? RxAvatar
                                    : item?.userImg
                                }
                                sx={{
                                  height: "50px",
                                  width: "50px",
                                  fontSize: "25px",
                                }}
                              />
                            </Stack>
                          </div>
                          <div className="tableData w-150">
                            <h2 className="font-14 triple-dot first-cap w-100">
                              {item?.name}
                            </h2>
                            <h3>{item?.totalPP} PP</h3>
                          </div>
                        </div>
                        {/* <h6>{item?.rank}</h6> */}
                        <h6 className="font-12">{key + 1}</h6>
                      </div>
                      {/* <div className="tableInfo">
                      <div className="tableDates">
                        <div className="tableImg">
                          <img src={ellipse} />
                        </div>
                        <div className="tableData">
                          <h2>{item.learnerName}</h2>
                          <h3>{item.total}</h3>
                        </div>
                      </div>
                      <h6>{item.rank + 1}</h6>
                    </div> */}
                    </div>
                    <hr />
                  </>
                );
              }
            })}
          </Grid>
        </div>
      </div>
    </>
  );
}
