import { AiOutlineHome } from "react-icons/ai";
import Home from "./pages/DrawerComponent/Home/Home";
import Study from "./pages/BottomComponent/Study/study.js";
import Play from "./pages/BottomComponent/Study/study.js";
import Rank from "./pages/BottomComponent/Rank/Rank.js";
import Profile from "./pages/DrawerComponent/Profile/Profile.js";
import Training from "./pages/DrawerComponent/Profile/Training/Trainig";
import TrainigProfile from "./pages/DrawerComponent/Profile/Training/TrainingProfile";
/* Not */
import Course from "./pages/DrawerComponent/Course/Course.js";

import CourseProfile from "./pages/DrawerComponent/Course/CourseProfile/Cprofile";
import ComResultProfile from "./pages/DrawerComponent/Course/CourseProfile/ComResultProfile";
import QuizResult from "./pages/DrawerComponent/Course/CourseProfile/Questionier/QuizResult";
import Leaderboard from "./pages/DrawerComponent/LeaderBoard/Leaderboard.js";
import Result from "./pages/DrawerComponent/Result/ResultTab/ResultTab";
import ResultProfile from "./pages/DrawerComponent/Result/ResultProfile";
import ResultStudentProfile from "./pages/DrawerComponent/Result/StudentReport/StudentReport";
import MyClasses from "./pages/DrawerComponent/MyClasses/MyClasses";
import MCStudentReport from "./pages/DrawerComponent/MyClasses/MCStudentReport";
import Events from "./pages//DrawerComponent/Events/Events";
import EventProfile from "./pages/DrawerComponent/Events/EventProfile";
import Assessment from "./pages/DrawerComponent/Assesment/Assesment";
import AssesmentProfile from "./pages/DrawerComponent/Assesment/AssesmentProfile/AssesmentProfile";
import AssesmentResult from "./pages/DrawerComponent/Assesment/AssesmentProfile/AssesmentResult";
import Badge from "./pages/DrawerComponent/Profile/Badge/Badge";
import Stats from "./pages/DrawerComponent/Profile/Stats/Stats";
import Setting from "./pages/DrawerComponent/Setting/Setting";
import SNotification from "./pages/DrawerComponent/Setting/Notification";
import Notifications from "./pages/DrawerComponent/Notification/Notifications";
import Security from "./pages/DrawerComponent/Setting/Security";
import Account from "./pages/DrawerComponent/Setting/Account";
import Paymentmethod from "./pages/DrawerComponent/Setting/Paymentmethod";
import Mcqs from "./pages/DrawerComponent/Course/Mcq/Mcqs";
import TrainingMcqs from "./pages/DrawerComponent/Profile/Training/Mcq/TrainingMcqs";
import TrainingResult from "./pages/DrawerComponent/Result/ResultTab/TrainingResult";
import LessonPlanProfile from "./pages/DrawerComponent/Course/CourseProfile/LessonPlanProfile";
// React Icons
import { BsJournalBookmark } from "react-icons/bs";
import { GiProgression } from "react-icons/gi";
import { CgClipboard } from "react-icons/cg";
import { MdLeaderboard } from "react-icons/md";
// import { MdOutlineAssessment } from "react-icons/md";
// import { GiCaveman } from "react-icons/gi";
// import { AiOutlineLogout } from "react-icons/ai";
import { AiFillSetting } from "react-icons/ai";
import { AiOutlineVerified } from "react-icons/ai";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { BiStats } from "react-icons/bi";
import AssessmentNew from "./pages/DrawerComponent/Assesment/AssesmentNew";
import AssessmentGrade from "./pages/DrawerComponent/Assesment/AssesmentGrade";
import AssesmentUser from "./pages/DrawerComponent/Assesment/AssesmentUser";
import AssesmentReport from "../src/pages/DrawerComponent/Assesment/AssesmentReport";
import Classes from "../src/pages/DrawerComponent/MyClasses/Classes";
import ClassesUser from "../src/pages/DrawerComponent/MyClasses/ClassesUser";
import ClassesReport from "./pages/DrawerComponent/MyClasses/ClassesReport";
import ClassesResult from "./pages/DrawerComponent/MyClasses/ClassesResult";
import Courses from "./pages/DrawerComponent/Courses/Courses";
import CoursesStart from "./pages/DrawerComponent/Courses/CoursesStart";
import CoursesWin from "./pages/DrawerComponent/Courses/CoursesWin";
import StartLevel from "./pages/DrawerComponent/Courses/StartLevel";
import CompleteLevel from "./pages/DrawerComponent/Courses/CompleteLevel";
import CompleteResult from "./pages/DrawerComponent/Courses/CompleteResult";
import Trainings from "./pages/DrawerComponent/Trainings/Trainings";
import TrainingStart from "./pages/DrawerComponent/Trainings/TrainingStart";
import TrainingComp from "./pages/DrawerComponent/Trainings/TrainingComp";
import LeaderBoardNew from "./pages/DrawerComponent/LeaderBoardNew/LeaderBoardNew";
import ResultNew from "./pages/DrawerComponent/ResultNew/ResultNew";
import CoursesUnlock from "./pages/DrawerComponent/Courses/CoursesUnlock";
import McqsData from "./pages/DrawerComponent/Courses/McqsData";
import StatsNew from "./pages/DrawerComponent/Profile/StatsNew/StatsNew";
import EventsNew from "./pages/DrawerComponent/EventsNew/EventsNew";
import EventProfileNew from "./pages/DrawerComponent/EventsNew/EventProfileNew";
import TrainingWin from "./pages/DrawerComponent/Trainings/TrainingWin";
import TrainingMcq from "./pages/DrawerComponent/Trainings/TrainingMcq";
import TrainingMcqRestart from "./pages/DrawerComponent/Trainings/TrainingMcqRestart";
import TrainingWinRestart from "./pages/DrawerComponent/Trainings/TrainingWinRestart";
import McqsDataRestart from "./pages/DrawerComponent/Courses/McqsDataRestart";
import CoursesWinRestart from "./pages/DrawerComponent/Courses/CourseWinRestart";
import CoursesStartComplete from "./pages/DrawerComponent/Courses/CoursesStartComplete";
import PushNotificationOpen from "./pages/Pushnotification/pushnotification";

const routes = [
  {
    invisible: false,
    name: "Home",
    link: "/home",
    layout: "/student",
    Element: <Home />,
    icon: <AiOutlineHome />,
    activeIndex: 0,
  },
  {
    invisible: false,
    name: "Courses ",
    link: "/courses",
    layout: "/student",
    Element: <Courses />,
    icon: <BsJournalBookmark />,
    activeIndex: 1,
  },
  {
    invisible: false,
    name: "Assesment ",
    layout: "/student",
    link: "/assesmentnew",
    Element: <AssessmentNew />,
    icon: <AiOutlineVerified />,
    activeIndex: 2,
  },
  {
    invisible: false,
    name: "My Classes ",
    link: "/classes",
    layout: "/student",
    Element: <Classes />,
    icon: <GiProgression />,
    activeIndex: 3,
  },
  {
    invisible: false,
    name: "Events ",
    link: "/eventsnew",
    layout: "/student",
    Element: <EventsNew />,
    icon: <CalendarTodayOutlinedIcon />,
    activeIndex: 4,
  },
  {
    invisible: false,
    name: "Training ",
    link: "/trainings",
    layout: "/student",
    Element: <Trainings />,
    icon: <BiStats />,
    activeIndex: 5,
  },
  {
    invisible: false,
    name: "Result ",
    link: "/resultnew",
    layout: "/student",
    Element: <ResultNew />,
    icon: <CgClipboard />,
    activeIndex: 6,
  },
  {
    invisible: false,
    name: "Leaderboard ",
    link: "/leaderboardnew",
    layout: "/student",
    Element: <LeaderBoardNew />,
    icon: <MdLeaderboard />,
    activeIndex: 7,
  },
  {
    invisible: false,
    name: "Settings ",
    link: "/setting",
    layout: "/student",
    Element: <Setting />,
    icon: <AiFillSetting />,
    activeIndex: 8,
  },
  {
    invisible: true,
    link: "/learn",
    layout: "/student",
    Element: <Study />,
  },
  {
    invisible: true,
    link: "/trainingmcq",
    layout: "/student",
    Element: <TrainingMcq />,
  },
  {
    invisible: true,
    link: "/trainingmcqrestart",
    layout: "/student",
    Element: <TrainingMcqRestart />,
  },
  {
    invisible: true,
    link: "/play",
    layout: "/student",
    Element: <Play />,
  },
  {
    invisible: true,
    link: "/rank",
    layout: "/student",
    Element: <Rank />,
  },
  {
    invisible: true,
    link: "/profile",
    layout: "/student",
    Element: <Profile />,
  },
  {
    invisible: true,
    link: "/badge",
    layout: "/student",
    Element: <Badge />,
  },
  {
    invisible: true,
    link: "/stats",
    layout: "/student",
    Element: <Stats />,
  },
  {
    invisible: true,
    link: "/training",
    layout: "/student",
    Element: <Training />,
  },
  {
    invisible: true,
    link: "/mcqs",
    layout: "/student",
    Element: <Mcqs />,
  },
  {
    invisible: true,
    link: "/trainingMcqs",
    layout: "/student",
    Element: <TrainingMcqs />,
  },
  {
    invisible: true,
    link: "/trainingprofile",
    layout: "/student",
    Element: <TrainigProfile />,
  },
  {
    invisible: true,
    link: "/lessonplanprofile",
    layout: "/student",
    Element: <LessonPlanProfile />,
  },
  {
    invisible: true,
    link: "/trainingresult",
    layout: "/student",
    Element: <TrainingResult />,
  },
  {
    invisible: true,
    link: "/courseprofile",
    layout: "/student",
    Element: <CourseProfile />,
  },
  {
    invisible: true,
    link: "/comresultprofile",
    layout: "/student",
    Element: <ComResultProfile />,
  },
  {
    invisible: true,
    link: "/quizresult/:lessonplanId",
    layout: "/student",
    Element: <QuizResult />,
  },

  {
    invisible: true,
    link: "/assesmentprofile",
    layout: "/student",
    Element: <AssesmentProfile />,
  },
  {
    invisible: true,
    link: "/assesmentresult",
    layout: "/student",
    Element: <AssesmentResult />,
  },
  {
    invisible: true,
    link: "/classesstudentreport",
    layout: "/student",
    Element: <MCStudentReport />,
  },
  {
    invisible: true,
    link: "/eventprofile",
    layout: "/student",
    Element: <EventProfile />,
  },
  {
    invisible: true,
    link: "/resultprofile",
    layout: "/student",
    Element: <ResultProfile />,
  },
  {
    invisible: true,
    link: "/resultstudentprofile",
    layout: "/student",
    Element: <ResultStudentProfile />,
  },
  {
    invisible: true,
    link: "/notifcation",
    layout: "/student",
    Element: <SNotification />,
  },
  {
    invisible: true,
    link: "/security",
    layout: "/student",
    Element: <Security />,
  },
  {
    invisible: true,
    link: "/account",
    layout: "/student",
    Element: <Account />,
  },
  {
    invisible: true,
    link: "/paymentmethod",
    layout: "/student",
    Element: <Paymentmethod />,
  },
  {
    invisible: true,
    link: "/assesmentgrade",
    layout: "/student",
    Element: <AssessmentGrade />,
  },
  {
    invisible: true,
    link: "/assesmentuser",
    layout: "/student",
    Element: <AssesmentUser />,
  },
  {
    invisible: true,
    link: "/assesmentreport",
    layout: "/student",
    Element: <AssesmentReport />,
  },
  {
    invisible: true,
    link: "/classesuser",
    layout: "/student",
    Element: <ClassesUser />,
  },
  {
    invisible: true,
    link: "/classesreport",
    layout: "/student",
    Element: <ClassesReport />,
  },
  {
    invisible: true,
    link: "/classesresult",
    layout: "/student",
    Element: <ClassesResult />,
  },
  {
    invisible: true,
    link: "/coursesstart",
    layout: "/student",
    Element: <CoursesStart />,
  },
  {
    invisible: true,
    link: "/courseswin",
    layout: "/student",
    Element: <CoursesWin />,
  },
  {
    invisible: true,
    link: "/courseswinrestart",
    layout: "/student",
    Element: <CoursesWinRestart />,
  },
  {
    invisible: true,
    link: "/startlevel",
    layout: "/student",
    Element: <StartLevel />,
  },
  {
    invisible: true,
    link: "/completelevel",
    layout: "/student",
    Element: <CompleteLevel />,
  },
  {
    invisible: true,
    link: "/completeresult",
    layout: "/student",
    Element: <CompleteResult />,
  },
  {
    invisible: true,
    link: "/trainingstart",
    layout: "/student",
    Element: <TrainingStart />,
  },
  {
    invisible: true,
    link: "/trainingcomp",
    layout: "/student",
    Element: <TrainingComp />,
  },
  {
    invisible: true,
    link: "/coursesunlock",
    layout: "/student",
    Element: <CoursesUnlock />,
  },
  {
    invisible: true,
    link: "/mcqsdata",
    layout: "/student",
    Element: <McqsData />,
  },
  {
    invisible: true,
    link: "/mcqsdatarestart",
    layout: "/student",
    Element: <McqsDataRestart />,
  },
  {
    invisible: true,
    link: "/statsnew",
    layout: "/student",
    Element: <StatsNew />,
  },
  {
    invisible: true,
    link: "/eventprofilenew",
    layout: "/student",
    Element: <EventProfileNew />,
  },
  {
    invisible: true,
    link: "/trainingwin",
    layout: "/student",
    Element: <TrainingWin />,
  },
  {
    invisible: true,
    link: "/trainingwinrestart",
    layout: "/student",
    Element: <TrainingWinRestart />,
  },
  {
    invisible: true,
    link: "/coursestartcomplete",
    layout: "/student",
    Element: <CoursesStartComplete />,
  },
  {
    invisible: true,
    link: "/notification",
    layout: "/student",
    Element: <Notifications />,
  },
  {
    invisible: true,
    link: "/pushnotif",
    layout: "/student",
    Element: <PushNotificationOpen />,
  },
];

export default routes;
