import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { Grid, Typography } from "@mui/material";
import Trainings from "../Trainings/Trainings";
import CompletedCo from "../Courses/CompletedCo";
import CompletedTR from "../Trainings/CompletedTR";

export default function ResultNew() {
  const navigate = useNavigate();

  const [locked, setLocked] = useState(0);
  const handleScreenUnlocked = () => {
    setLocked(0);
  };

  const handleScreenLocked = () => {
    setLocked(1);
  };

  const dontShowNavBar = "true";

  return (
    <>
      <Grid container>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ paddingTop: "15px", backgroundColor: "#cddc39" }}
        >
          <div className="BackHeadResult ">
            <IoIosArrowBack onClick={() => navigate(-1)} />
          </div>
          <div className="WhiteColorTypography MyclassesTypo">Result</div>
          <div></div>
        </Grid>
      </Grid>
      <div className="playFirst">
        <div className="playFirstChanges">
          <span
            onClick={handleScreenUnlocked}
            style={{ float: "left" }}
            className={locked === 0 ? "toggleBar" : "nontoggleBar"}
          >
            Course
          </span>
          <span
            onClick={handleScreenLocked}
            style={{ float: "right" }}
            className={locked === 1 ? "toggleBar" : "nontoggleBar"}
          >
            Training
          </span>
        </div>
      </div>
      <div>
        {locked === 0 ? (
          <>
            <CompletedCo />
          </>
        ) : (
          <>
            <CompletedTR />
          </>
        )}
      </div>
    </>
  );
}
