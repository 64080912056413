import React, { useState, useEffect } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import { VscBellDot } from "react-icons/vsc";
import { FETCH_URL } from "../../../fetchIp";
import moment from "moment";
import LoadingSpinner from "../../Spinner/LoadingSpinner";

export default function AssesmentUser() {
  const navigate = useNavigate();
  const [learnersData, setLearnersData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const locationData = useLocation();
  const AssessmentData = locationData?.state;
  const [Status, setStaus] = useState(AssessmentData.assignedLearners);
  const [completeState, setCompleteState] = useState(false);

  console.log(
    AssessmentData.assignedLearners,
    "AssessmentData.assignedLearners"
  );

  const getLearnersByLernersIds = async (status = true) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${FETCH_URL}/api/learner/getLearnerbylearnersids`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            learnersIds: AssessmentData.assignedLearners,
          }),
        }
      );

      const res = await response.json();
      if (response.ok) {
        setLearnersData(res.msg);
        console.log("Learners Data =======>", res.msg);
      } else {
        console.error("API error =====>", res.err);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Catch block ====>", error);
      setIsLoading(false);
    }
  };

  const getSingleAssessment = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/assessment/getSingleAssessment/${AssessmentData._id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      const res = await response.json();
      if (response.ok) {
        setStaus(res.msg.assignedLearners);
        let totalcompletion = 0;
        res.msg.assignedLearners.map((data) => {
          if (data.completion) {
            totalcompletion++;
          }
        });
        if (res.msg.assignedLearners.length == totalcompletion) {
          setCompleteState(true);
        } else {
          setCompleteState(false);
        }

        console.log("Learners Data =======>", res.msg);
      } else {
        console.error("API error =====>", res.err);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Catch block ====>", error);
      setIsLoading(false);
    }
  };

  useEffect(async () => {
    await getLearnersByLernersIds();
  }, [locationData?.state]);

  useEffect(() => {
    getSingleAssessment();
  }, []);

  return (
    <>
      {isLoading ? <LoadingSpinner /> : null}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        backgroundColor="#CDDC39"
        borderRadius="0px"
        height="104px"
        paddingTop="20px"
      >
        <div className="BackHead ">
          <IoIosArrowBack onClick={() => navigate(-1)} />
        </div>
        <div className="WhiteColorTypography MyclassesTypo ml-10px">
          Assessments
        </div>
        <div>{/* <VscBellDot className="BellIcon" /> */}</div>
      </Grid>
      <div className="jumpedCont">
        <div className="jumpedImage">
          <img src={AssessmentData.assessmentImage} />
        </div>
        <div className="PPInfo">
          <Grid item xs={12}>
            <Typography align="center" className="PPCourseButton">
              {AssessmentData.assignedXp} PP
            </Typography>
          </Grid>
        </div>
        <div className="jumpInfo">
          <div className="jumpDetail">
            <h2>{AssessmentData.assessmentName}</h2>
            <h3>{AssessmentData.uid}</h3>
            <h3>{moment(AssessmentData?.assignedOn).format("DD/MM/YYYY")}</h3>
            <div className={completeState ? "greenBtns" : "redBtns"}>
              <button>{completeState ? "Completed" : "Pending"}</button>
            </div>
          </div>
        </div>
      </div>
      <div className="stuList">
        <h2>Student List</h2>
      </div>

      <div className="listTab">
        <div className="listDivColor">
          <h2>Roll-No.</h2>
          <h2>Name</h2>
          <h2>Score</h2>
        </div>

        {learnersData.map((user, key) => {
          let assessmentStatus;
          Status.map((data) => {
            if (data._id == user._id) {
              assessmentStatus = data.completion;
            }
          });
          return (
            <>
              <Link
                to="/student/assesmentreport"
                state={{ AssessmentData, user }}
                key={key + 100}
              >
                <div className="listDiv">
                  <h2> {user.learnerDetail.regNo}</h2>
                  <h2>{user.learnerDetail.fullName}</h2>
                  <h4
                    className={assessmentStatus ? "colorBtns1" : "colorBtns2"}
                  >
                    {assessmentStatus ? "Completed" : "Pending"}
                  </h4>
                </div>
              </Link>
            </>
          );
        })}
      </div>
    </>
  );
}
