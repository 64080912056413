import React from 'react'
import { Grid, Typography, Box, TextField, Button } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination,Autoplay } from "swiper";
import play from "./../../assets/img/play.png";
import { Link } from "react-router-dom";


export default function SignPlay(){
  return (
    <><div style={{width: '90%',marginLeft: 'auto',marginRight: 'auto',marginTop: '15%'}} >
    <Swiper
      slidesPerView={1}
      spaceBetween={155}
      pagination={{
        clickable: true,
      }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      modules={[Pagination, Autoplay]}
      className="mySwiper"
    >
      <SwiperSlide>
        <img src={play} style={{width:"100%",marginBottom:"10%"}}/>
      </SwiperSlide>
      <SwiperSlide>
      <img src={play} style={{width:"100%"}}/>
      </SwiperSlide>
      <SwiperSlide>
      <img src={play} style={{width:"100%"}}/>
      </SwiperSlide>
      <SwiperSlide>
      <img src={play} style={{width:"100%"}}/>
      </SwiperSlide>
      <SwiperSlide>
      <img src={play} style={{width:"100%"}}/>
      </SwiperSlide>
      
    </Swiper>
    <Grid container xs={12} style={{marginTop:"5%"}} justifyContent="center">
    
        <Grid item xs={10} style={{marginTop:"10%"}}>
          <Typography align="center" className='GreenColorTypography 'style={{fontSize:"25px"}}>Play</Typography>
          <Typography className='GreyColorTypography '>A lot of interesting activities
 on your favourite games!</Typography>
          </Grid>
        </Grid>
        
        <Grid container xs={12} style={{marginTop:"20%"}}justifyContent="center">
          <Button 
            variant="contained"
            fullWidth={true}
            className="GreenButton700 " component={Link} to="/auth/kit" style={{borderRadius:"25px"}}
          >Continue </Button> 
          <Typography className="GreyColorTypography" style={{marginTop:"10%"}}>Skip</Typography>
          </Grid>
  </div></>
  )
}


