import React, { useEffect, useState } from "react";
import grey from "../../../assets/img/Rectangle 4332.png";
import search from "../../../assets/img/Vector (31).png";
import { Link } from "react-router-dom";
import { FETCH_URL } from "../../../fetchIp.js";
import axios from "axios";
import { Typography, Grid } from "@mui/material";

export default function CompEve() {
  const [content, setContent] = useState([]);

  function Details() {
    try {
      axios
        .get(`${FETCH_URL}/api/school/event/getAllEvents?isCompleted=false`)
        .then((resp) => {
          console.log(resp?.data?.msg);

          console.log("Data is ", resp?.data?.msg);
          setContent(resp.data.msg);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log("Error si ", err);
    }
  }

  useEffect(() => {
    Details();
  }, []);

  return (
    <>
      {content?.length === 0 ? (
        <Grid
          display={"grid"}
          justifyContent={"center"}
          className="h-84vh text-center"
        >
          <Typography fontWeight={"700"} className="align-center text-8d97">
            No completed Event
          </Typography>
        </Grid>
      ) : (
        " "
      )}
      {content.map((item) => {
        return (
          <>
            <Link to={"/student/eventprofile"} state={{ item }}>
              <div className="eve101Cont">
                <div className="greyImg">
                  <img
                    src={
                      // item.image
                      grey
                    }
                  />
                </div>
                <div className="eve101">
                  <h2>{item.eventName}</h2>
                  <h3>
                    {item.dateOfCreation} - {item.endDate}
                  </h3>
                  <h5>{item.eventDetails}</h5>
                </div>
                <div className="eve101PP">
                  <button>100 PP</button>
                </div>
              </div>
            </Link>
          </>
        );
      })}
    </>
  );
}
