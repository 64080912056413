import React from "react";
import { Typography } from "@mui/material";

export default function Play() {
  return (
    <>
      <Typography> Explore Activity </Typography>
    </>
  );
}
