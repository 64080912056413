import React, { useState, useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import { VscBellDot } from "react-icons/vsc";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FETCH_URL } from "../../../fetchIp";
import { useAuth } from "../../../Context/AuthContext";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import moment from "moment";
// import publicRequest from "publicRequest";
import { publicRequest } from "../../../BaseUrl";

export default function AssessmentGrade() {
  // const [gradeData, setGradeData] = useState([0]);

  const [gradeId, setGradeId] = useState("");
  const { trainerId } = useAuth();
  const navigate = useNavigate();
  const [assessmentData, setAssessmentData] = useState([]);
  const [age, setAge] = React.useState("");
  const [sectionage, setSectionage] = React.useState("");

  const location = useLocation();
  const data = location.state;

  const selectGrade = async (data) => {};

  const handoleChange = async (id) => {
    const data = await publicRequest.get(
      `/api/assessment/getAssesssmentByDivision/${id}`
    );
    console.log("Data is ", data);
    setGradeId(id);
    setAssessmentData(data?.data?.msg);
    // getAssessment(id);
  };

  const getGrade = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getTrainerGrades/${trainerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        // setGradeData(res.msg);
        setGradeId(res.msg[0]._id);
        // await getAssessment(res.msg[0]._id);
        console.log("Grade Data =======>", res.msg);
      } else {
        console.error("API error =====>", res.err);
      }
    } catch (error) {
      console.log("Catch block ====>", error);
    }
  };

  // const getAssessment = async (gradeId) => {
  //   try {
  //     const response = await fetch(
  //       `${FETCH_URL}/api/assessment/getAssesssmentByGrade/${gradeId}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     const res = await response.json();
  //     if (response.ok) {
  //       setAssessmentData(res.msg);

  //       console.log("Assessment Data =======>", res.msg);
  //     } else {
  //       console.error("API error =====>", res.err);
  //     }
  //   } catch (error) {
  //     console.log("Catch block = ===>", error);
  //   }
  // };

  useEffect(() => {
    console.log("Data Grade is: ", data);
    handoleChange(data?.divisions?.[0]?._id);
    // getGrade();
  }, []);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        backgroundColor="#CDDC39"
        borderRadius="0px"
        height="82px"
      >
        <div className="BackHead ">
          <IoIosArrowBack onClick={() => navigate(-1)} />
        </div>
        <div className="WhiteColorTypography MyclassesTypo ml-10px">
          Assessments
        </div>
        <div>{/* <VscBellDot className="BellIcon" /> */}</div>
      </Grid>

      <div className="GradesBox">
        <div className="blackType locText">
          <h2 value={data._id}>{data.grade}</h2>
        </div>
        <div>
          <Grid pb={2}>
            <FormControl
              sx={{ m: 2.2, minWidth: "90%" }}
              size="small"
              className="select-radius-8px"
            >
              <InputLabel id="school_id">Select School</InputLabel>
              <Select
                labelId="school_id"
                id="school_id"
                value={gradeId}
                label="Select School"
                onChange={(e) => handoleChange(e.target.value)}
              >
                {data?.divisions?.map((data, index) => {
                  return (
                    <MenuItem
                      selected={!index ? true : false}
                      value={data?._id}
                    >
                      {data.division}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </div>
      </div>

      {assessmentData.map((user, key) => {
        let totalcompletion = 0;
        let completeState = false;
        user.assignedLearners.map((data) => {
          if (data.completion) {
            totalcompletion++;
          }
        });
        if (user.assignedLearners.length == totalcompletion) {
          completeState = true;
        } else {
          completeState = false;
        }
        return (
          <div className="gradeCards">
            <div className="jumpCont">
              <div className="jumpsImage">
                <img src={user.assessmentImage} />
              </div>
              <div className="PPInfo">
                <Grid item xs={12}>
                  <Typography align="center" className="PPCourseButton">
                    {user.assignedXp} PP
                  </Typography>
                </Grid>
              </div>
              <div className="jumpInfo">
                <div className="jumpDetail">
                  <h2 className="first-cap"> {user.assessmentName}</h2>
                  <h3> {user.uid}</h3>
                  <h3>{moment(user?.createdOn).format("DD/MM/YYYY")}</h3>
                  <Link to="/student/assesmentuser" state={user}>
                    <div className="downloadBtns">
                      <button>
                        {completeState ? "Completed" : "Attempt NOW"}
                      </button>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div
        className={
          assessmentData.length != 0
            ? "d-none"
            : "gradeCards no-assessment-data"
        }
      >
        {"Data not available"}
      </div>
    </>
  );
}
