import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import { VscBellDot } from "react-icons/vsc";
import { Grid, Typography, Button } from "@mui/material";
import male from "../../../assets/img/unsplash_d2MSDujJl2g.png";
import { CircularProgressbar } from "react-circular-progressbar";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { FETCH_URL } from "../../../fetchIp";
import { useAuth } from "../../../Context/AuthContext";
import axios from "axios";
import LoadingSpinner from "../../Spinner/LoadingSpinner";
import moment from "moment";
import Avatar from "@mui/material/Avatar";
import { RxAvatar } from "react-icons/rx";
import Stack from "@mui/material/Stack";
import { publicRequest } from "../../../BaseUrl";

export default function ClassesReport() {
  let perc = 8.8;
  const currentYear = moment().format("YYYY");

  const navigate = useNavigate();
  const location = useLocation();
  const userData = location?.state;
  let userID = userData?._id;

  const [expanded, setExpanded] = React.useState(false);
  const [assessmentData, setAssessmentData] = useState([]);
  const [gradeId, setGradeId] = useState("");
  const [gradeData, setGradeData] = useState([0]);
  const [locomotor, setLocomotor] = useState([]);
  const [bodyManagement, setBodyManagement] = useState([]);
  const [objectControl, setObjectControl] = useState([]);
  const { trainerId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [percentage, setPercentage] = useState(0);
  const [ids, setIds] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));

  const getGrade = async () => {
    try {
      const response = await fetch(
        `${FETCH_URL}/api/school/grade/getTrainerGrades/${trainerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setGradeData(res?.msg);
        setGradeId(res?.msg[0]?._id);

        getAssessment(res?.msg[0]?._id);
        console.log("Grade Data =======>", res?.msg);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Catch block ====>", error);
    }
  };

  const getAssessment = async (gradeId) => {
    let assessIds = userData?.assignedAssessments?.map((item) => {
      if (item?.completion) ids.push(item?._id);
      return { _id: item?._id };
    });
    console.log("Assessment Ids is ", assessIds);
    try {
      const res = await publicRequest.post(
        `/api/assessment/getAssessmentByAssessmentIds`,
        {
          assessmentIds: assessIds,
        }
      );

      console.log("Data is ", res);
      setAssessmentData(res?.data?.msg);
      setIsLoading(false);
    } catch (error) {
      console.log("Catch block ====>", error);
      setIsLoading(false);
    }
  };

  async function stats() {
    const resp = await publicRequest.get(
      `/api/assessment/getDistinctMainSkillPPByUserId/${userID}`
    );
    console.log("Stats Response is ", resp);

    resp?.data?.msg?.map((item) => {
      if (item?._id?.skillName == "Locomotor") {
        setLocomotor(item);
      } else if (item?._id?.skillName == "Object Control") {
        setObjectControl(item);
      } else if (item?._id?.skillName == "Body Management") {
        setBodyManagement(item);
      }
    });
  }

  const getFMSScore = async () => {
    setIsLoading(true);
    try {
      const responce = await fetch(
        `${FETCH_URL}/api/assessment/getFMSScoreByUserId/${userID}`,
        {
          method: "GET",
          headers: {
            "Content-type": "Application/json",
          },
        }
      );

      const res = await responce.json();
      setPercentage(isNaN(res?.msg) ? 0 : res?.msg * 10);
      console.log("Response issssssssssssss", res);
    } catch (err) {
      console.log("Error is ", err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getFMSScore();
    getGrade();
    stats();

    console.log("UserData: ", userData);
  }, []);

  return (
    <>
      {isLoading ? <LoadingSpinner /> : null}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        backgroundColor="#CDDC39"
        borderRadius="0px"
        height="176px"
        paddingTop="20px"
      >
        <div className="BackHead ">
          <IoIosArrowBack onClick={() => navigate(-1)} />
        </div>
        <div className="WhiteColorTypography MyclassesTypo ml-10px">
          Student Report
        </div>
        <div>{/* <VscBellDot className="BellIcon" /> */}</div>
      </Grid>

      <div className="reportConts">
        <div className="maleReport">
          {/* <img src={userData?.learnerDetail?.learnerImg} /> */}
          <Stack direction="row" spacing={2}>
            <Avatar
              className="radius-50-per"
              alt={userData?.learnerDetail?.fullName?.[0]?.toUpperCase()}
              src={
                userData?.learnerDetail?.learnerImg == undefined
                  ? RxAvatar
                  : userData?.learnerDetail?.learnerImg
              }
              sx={{ height: "80px", width: "80px", fontSize: "35px" }}
            />
          </Stack>
        </div>
        <div className="reportTxt">
          <h2> {userData?.learnerDetail?.fullName?.slice(0, 16)}</h2>
          <h3>{userData?.academicDetails?.grade}</h3>
          <h3>{userData?.academicDetails?.schoolName}</h3>
        </div>
        <Grid item xs={8} style={{ marginTop: "1px" }}>
          <CircularProgressbar
            className="CircleBars stroke-white"
            sx={{ stroke: "white", fill: "white" }}
            value={percentage?.toFixed(2)}
            text={
              percentage != 0
                ? percentage == 100
                  ? "100%"
                  : percentage.toFixed(2) + "%"
                : "0%"
            }
          />
        </Grid>
      </div>

      <div>
        <Accordion
          className="ArrowIcon"
          style={{
            backgroundColor: "#ffffff",
            width: "93.7%",
            marginLeft: "14px",
            borderRadius: "10px",
            marginTop: "20px",
          }}
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className="GreyColorTypography">
              Personal Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="pdHeads">
              <div className="pdClass">
                <h3>Full Name : </h3>
                <h4> {userData?.learnerDetail?.fullName}</h4>
              </div>
              <div className="pdClass">
                <h3>Date of Birth : </h3>
                <h4>
                  {" "}
                  {moment(userData?.learnerDetail?.dob).format("DD/MM/YYYY")}
                </h4>
              </div>
              <div className="pdClass">
                <h3>Father's Name : </h3>
                <h4> {userData?.learnerDetail?.fathersName}</h4>
              </div>
              <div className="pdClass">
                <h3>Age : </h3>
                <h4>
                  {isNaN(
                    moment(currentYear, "YYYY").diff(
                      moment(userData?.learnerDetail?.dob, "YYYY"),
                      "years"
                    )
                  )
                    ? " - "
                    : moment(currentYear, "YYYY").diff(
                        moment(userData?.learnerDetail?.dob, "YYYY"),
                        "years"
                      )}
                </h4>
              </div>
              <div className="pdClass">
                <h3>Phone Number : </h3>
                <h4> {userData?.contactDetail?.learnerPhoneNo}</h4>
              </div>
              <div className="pdClass">
                <h3>Email:</h3>
                <h4 style={{ marginLeft: "2px" }}>
                  {userData?.contactDetail?.learnerEmail}
                </h4>
              </div>
              <div className="pdClass">
                <h3>Address : </h3>
                <h4> {userData?.contactDetail?.learnerLocation}</h4>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion
          className="ArrowIcon"
          style={{
            backgroundColor: "#ffffff",
            width: "93.7%",
            marginLeft: "14px",
            borderRadius: "10px",
            marginTop: "15px",
          }}
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className="GreyColorTypography">
              Academic Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="gameCard">
              {/* <Link to="/student/statsnew"> */}
              <div className="volCards">
                <Grid container>
                  <Grid
                    item
                    style={{
                      backgroundColor: "#ffffff",
                      borderRadius: "15px",
                      width: "85vw",
                    }}
                  >
                    <div>
                      <Grid
                        item
                        style={{
                          justifyContent: "space-between",
                          paddingTop: "8px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            className="Fs-0-8 GreyColorTypography"
                            ml={1}
                          >
                            FMS Report
                          </Typography>
                          <Typography className="Fs-0-7 GreyColorTypography">
                            Weekly
                          </Typography>
                        </div>
                      </Grid>
                    </div>
                    <div className="textDiv">
                      <CircularProgressbar
                        className="CircularPro"
                        value={percentage.toFixed(2)}
                        text={
                          percentage != 0
                            ? percentage == 100
                              ? "100%"
                              : percentage.toFixed(2) + "%"
                            : "0%"
                        }
                      />
                      <Grid item xs={8} style={{ marginLeft: "12px" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{ mt: 1 }}
                            className="lightGreyColorTypo Fs-0-7"
                          >
                            Locomotor
                          </Typography>
                          <Typography
                            sx={{ pt: 1.2, mr: 0 }}
                            className="lightGreyColorTypo Fs-0-7"
                          >
                            {locomotor?.total !== undefined
                              ? locomotor?.total
                              : 0}
                            /
                            {locomotor?.overAll !== undefined
                              ? locomotor?.overAll
                              : 0}{" "}
                            PP
                          </Typography>
                        </div>
                        <Box sx={{ mt: 0 }}>
                          <BorderLinearProgress
                            className="Hprogressbar"
                            variant="determinate"
                            value={
                              locomotor?.total !== undefined
                                ? (
                                    (locomotor?.total * 100) /
                                    parseInt(locomotor?.overAll)
                                  ).toFixed(2)
                                : 0
                            }
                          />
                        </Box>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{ mt: 1 }}
                            className="lightGreyColorTypo Fs-0-7"
                          >
                            Object Control
                          </Typography>
                          <Typography
                            sx={{ pt: 1.2, mr: 0 }}
                            className="lightGreyColorTypo Fs-0-7"
                          >
                            {objectControl?.total !== undefined
                              ? objectControl?.total
                              : 0}
                            /
                            {objectControl?.total !== undefined
                              ? parseInt(objectControl?.overAll)
                              : 0}{" "}
                            PP
                          </Typography>
                        </div>
                        <Box sx={{ mt: 0 }}>
                          <BorderLinearProgress
                            className="Hprogressbar"
                            variant="determinate"
                            value={
                              objectControl?.total !== undefined
                                ? (
                                    (objectControl?.total * 100) /
                                    objectControl?.overAll
                                  ).toFixed(2)
                                : 0
                            }
                          />
                        </Box>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{ mt: 1 }}
                            className="lightGreyColorTypo Fs-0-7"
                          >
                            Body Management
                          </Typography>
                          <Typography
                            sx={{ pt: 1.2, mr: 0 }}
                            className="lightGreyColorTypo Fs-0-7"
                          >
                            {bodyManagement?.total !== undefined
                              ? bodyManagement?.total
                              : 0}
                            /
                            {bodyManagement?.overAll !== undefined
                              ? parseInt(bodyManagement?.overAll)
                              : 0}{" "}
                            PP
                          </Typography>
                        </div>
                        <Box sx={{ mt: 0 }}>
                          <BorderLinearProgress
                            className="Hprogressbar"
                            variant="determinate"
                            value={
                              bodyManagement?.total !== undefined
                                ? (
                                    (bodyManagement?.total * 100) /
                                    bodyManagement?.overAll
                                  ).toFixed(2)
                                : 0
                            }
                          />
                        </Box>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </div>
              {/* </Link> */}
            </div>

            <div className="assesInfos">
              <h2>Assesments</h2>
            </div>

            {assessmentData?.length == 0 ? (
              <Grid mt={3}>
                <Typography className="color-light-black font-14 font-w-700 text-center">
                  {"No Assessment assigned"}
                </Typography>
              </Grid>
            ) : (
              ""
            )}

            {assessmentData?.map((user, key) => (
              <div className="jumpedCont2">
                <div className="jumpsImage" style={{ marginLeft: "4px" }}>
                  <img src={user?.assessmentImage} />
                </div>
                <div className="PPInfo">
                  <Grid item xs={12}>
                    <Typography align="center" className="PPCourseButton">
                      {user?.assignedXp} PP
                    </Typography>
                  </Grid>
                </div>
                <div className="jumpInfo">
                  <div className="jumpDetail first-cap">
                    <h2> {user?.assessmentName}</h2>
                    <h3> {user?.uid}</h3>
                    <h3>
                      {moment(user?.assignedGrades?.[0]?.assignedOn).format(
                        "DD/MM/YYYY"
                      )}
                    </h3>
                    <Link
                      // to="/student/classesresult"
                      to="/student/assesmentreport"
                      state={{ AssessmentData: user, user: userData }}
                    >
                      <div className="downloadBtns">
                        <button>
                          {ids.includes(user?._id)
                            ? "Completed"
                            : "Attempt NOW"}
                        </button>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}
