import React, { useEffect, useState } from "react";
import { Grid, Typography, Button, Divider } from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { IoMdHeartEmpty } from "react-icons/io";
import axios from "axios";
import { useAuth } from "../../../Context/AuthContext";
import { FETCH_URL } from "../../../fetchIp";
import LoadingSpinner from "../../Spinner/LoadingSpinner";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import objCon from "../../../assets/img/Rectangle 3464.png";
import loco from "../../../assets/img/Rectangle 3468.png";
import ReactPlayer from "react-player";

export default function TrainingComp() {
  const { trainerId } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const LPdata = location?.state;
  const lpdata = location?.state;
  // let data = {};
  const data = LPdata;

  let mainSkills = [];
  LPdata?.skills?.map((item) => {
    if (!mainSkills.includes(item?.mainSkill)) mainSkills.push(item?.mainSkill);
  });

  const [skillAbout, setSkillAbout] = useState(0);
  const [resultData, setResultData] = useState([]);
  const [locomotor, setLocomotor] = useState({
    locopercent: 0,
    resultPP: 0,
  });
  const [bodyManagement, setBodyManagement] = useState({
    bodypercent: 0,
    resultPP: 0,
  });
  const [objectControl, setObjectControl] = useState({
    objectpercent: 0,
    resultPP: 0,
  });
  const [skillData, setSkillData] = useState();
  let LocoSkill = 0;
  let BodySkill = 0;
  let ObjSkill = 0;
  let LocoPer = 0;
  let BodyPer = 0;
  let ObjPer = 0;

  let locoP = 0;
  let bodyP = 0;
  let objP = 0;

  const [Locomotor, setLocomotor1] = useState(0);
  const [BodyManagement, setBodyManagement1] = useState(0);
  const [ObjectControl, setObjectControl1] = useState(0);
  const [isActive, setIsActive] = useState(0);
  const [readAbout, setReadAbout] = useState(0);
  const [restart, setRestart] = useState(false);

  const getSingleTrainer = async () => {
    try {
      const resp = await axios.get(
        `${FETCH_URL}/api/learner/getSingleTrainer/${trainerId}`
      );
      isExistsResult(resp?.data?.msg);

      console.log("Data is ", resp);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const isExistsResult = async (trainer) => {
    try {
      let skillsData = [];
      LPdata?.skills?.map((data) => {
        let datas = {};
        datas._id = data?._id;
        datas.mainSkill = data?.mainSkill;
        datas.subSkill = data?.subSkill;
        datas.percent = data?.percent;
        datas.points = data?.points;
        datas.rubricsQts = data?.rubricsQts;
        datas.overAllPoints = data?.overAllPoints;
        datas.skillImg = data?.skillImg;
        skillsData.push(datas);
      });

      const ResultData = await axios.post(
        `${FETCH_URL}/api/assessment/createResult`,
        {
          userId: trainer?._id,
          name: trainer?.trainerDetail?.fullName,
          userImg: trainer?.trainerDetail?.trainerImg,
          isTrainer: true,
          isLessonPlan: true,
          assignedPP: LPdata?.LPXp,
          isIndividualLessonPlan: true,

          lessonPlan: {
            _id: LPdata?._id,
            skills: skillsData,
          },
        }
      );
      console.log("isExistsResult is ", ResultData);

      if (ResultData?.data?.msg2?.resultPP == undefined) {
        setRestart(false);
      } else {
        setResultData(ResultData?.data?.msg2);

        ResultData?.data?.msg2?.skillScore.map((item) => {
          if (item.mainSkill == "Body Management") {
            locoP += +item?.points;
            setBodyManagement({
              bodypercent: item?.percent,
              resultPP: locoP,
            });
          } else if (item.mainSkill == "Locomotor") {
            bodyP += +item?.points;
            setLocomotor({
              locopercent: item?.percent,
              resultPP: bodyP,
            });
          } else if (item.mainSkill == "Object Control") {
            objP += +item?.points;
            setObjectControl({
              objectpercent: item?.percent,
              resultPP: objP,
            });
          }
        });
        setRestart(true);
      }
      setIsLoading(false);
      // setSkillScore(ResultData?.data?.msg2?.skillScore);
    } catch (err) {
      console.log("Error is ", err);
    }
  };

  function skills() {
    axios
      .post(`${FETCH_URL}/api/assessment/getResultByLessonPlanIds`, {
        userId: trainerId,
        lessonPlanIds: [{ lessonPlanId: LPdata._id }],
      })
      .then((resp) => {
        console.log(resp?.data?.msg, "skills data");
        setSkillData(resp?.data?.msg);
        setResultData(resp?.data?.msg);
        resp.data.msg[0].skillScore.map((item) => {
          if (item.mainSkill == "Body Management") {
            setBodyManagement({
              bodypercent: item.percent,
              resultPP: item.points,
            });
          } else if (item.mainSkill == "Locomotor") {
            setLocomotor({ locopercent: item.percent, resultPP: item.points });
          } else if (item.mainSkill == "Object Control") {
            setObjectControl({
              objectpercent: item.percent,
              resultPP: item.points,
            });
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const skillData1 = () => {
    LPdata.skills.map((data) => {
      if (data.mainSkill == "Body Management") {
        BodySkill++;
        if (data.percent != "") {
          BodyPer += parseInt(data.percent);
        }
      }
      if (data.mainSkill == "Object Control") {
        ObjSkill++;
        if (data.percent != "") {
          ObjPer += parseInt(data.percent);
        }
      }
      if (data.mainSkill == "Locomotor") {
        LocoSkill++;
        if (data.percent != "") {
          LocoPer += parseInt(data.percent);
        }
      }
    });
    if (LocoPer !== 0) {
      LocoPer = LocoPer / LocoSkill;
      setLocomotor1(LocoPer);
    }
    if (BodyPer !== 0) {
      BodyPer = BodyPer / BodySkill;
      setBodyManagement1(BodyPer);
    }
    if (ObjPer !== 0) {
      ObjPer = ObjPer / ObjSkill;
      setObjectControl1(ObjPer);
    }
  };

  useEffect(() => {
    getSingleTrainer();
    // skills();
    skillData1();
  }, []);

  return (
    <>
      <>
        {isLoading ? <LoadingSpinner /> : null}
        <div
          className="OnGoingProfileImage"
          style={{
            backgroundImage:
              LPdata?.heroContent?.type == "image"
                ? `url('${LPdata?.heroContent?.url}')`
                : "",
          }}
        >
          <Grid
            container
            className={LPdata?.heroContent?.type == "image" ? "d-none" : ""}
          >
            <Grid xs={12} className="video-grid">
              <ReactPlayer
                url={LPdata?.heroContent?.url}
                playing={true}
                loop={true}
                width={"100%"}
                height={"236px"}
              />
            </Grid>
          </Grid>

          <Grid
            position={"absolute"}
            top={"0px"}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            className="container-img"
          >
            <Grid item>
              <IoIosArrowBack
                className="back-whishlist-icon"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </Grid>

            <Grid item></Grid>
          </Grid>
        </div>

        <Grid
          container
          pl="12px"
          pr="12px"
          mt="10px"
          style={{ backgroundColor: "white" }}
        >
          <Grid item sx={{ overflowX: "scroll" }} display="flex">
            <span
              className={isActive === 0 ? "category bg-color " : "category"}
              onClick={() => setIsActive(0)}
            >
              Overview
            </span>
            <span
              className={isActive === 1 ? "category bg-color " : "category"}
              onClick={() => setIsActive(1)}
            >
              Curriculum
            </span>
            <span
              className={isActive === 2 ? "category bg-color " : "category"}
              onClick={() => setIsActive(2)}
            >
              Benefit
            </span>
          </Grid>
        </Grid>

        <Grid>
          <Grid
            container
            style={{ marginLeft: "0px", width: "100%", zIndex: 1111 }}
            padding={2}
            mb={6}
          >
            <Grid
              mb={1}
              item
              xs={12}
              p={1}
              className="oncontainerdatacard border-E4E6EF radius-8"
            >
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                className={isActive >= 1 ? "d-none" : null}
              >
                <Typography color="#030304" fontWeight="bold" marginLeft="3%">
                  {lpdata?.LPTitle?.[0].toUpperCase() +
                    lpdata?.LPTitle?.slice(1)}
                </Typography>
                <Typography className="GreenColorTypoButton p-7-9">
                  PP {lpdata?.LPXp}
                </Typography>
              </Grid>

              <Grid>
                {isActive === 0 ? (
                  <Grid>
                    <Typography className="BlackColorTypography  mt20px">
                      About
                    </Typography>

                    <Typography
                      fontSize={14}
                      className={readAbout == 1 ? null : "h-67 overflow-hidden"}
                    >
                      {lpdata?.LPDesc}
                    </Typography>

                    <div style={{ height: "4px" }}></div>

                    <div
                      className={
                        lpdata?.LPDesc?.length > 400 ? "text-center" : "d-none"
                      }
                    >
                      <Button
                        onClick={() => setReadAbout(1)}
                        className={
                          readAbout == 1
                            ? "d-none text-d1e700 transform-none"
                            : "text-d1e700 transform-none"
                        }
                      >
                        Read more
                      </Button>
                    </div>
                  </Grid>
                ) : null}

                {isActive === 1 ? (
                  <Grid>
                    <Typography fontSize={14}>
                      {lpdata?.curriculam == undefined ||
                      lpdata?.curriculam?.length == 0
                        ? "No data found"
                        : lpdata?.curriculum?.[0].toUpperCase() +
                          lpdata?.curriculum?.slice(1)}
                    </Typography>
                  </Grid>
                ) : null}

                {isActive === 2 ? (
                  <Grid>
                    <Typography fontSize={14}>
                      {lpdata?.benefits == undefined ||
                      lpdata?.benefits?.length == 0
                        ? "No data found"
                        : lpdata?.benefits?.[0].toUpperCase() +
                          lpdata?.benefits?.slice(1)}
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>

            <div
              style={{ width: "100%", marginBottom: "5px" }}
              className={!restart || isActive != 0 ? "d-none" : null}
            >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography fontWeight="800">Result</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid className="resultPP">
                    <Typography
                      className="font-w-700 text-cddc39"
                      mt={-1}
                      mb={3}
                    >
                      Your Result
                    </Typography>
                    <h3>
                      {resultData?.resultPP == null ? 0 : resultData?.resultPP}/
                      {data?.LPXp == undefined ? data?.assignedPP : data?.LPXp}
                      PP
                    </h3>
                    <Typography
                      className="text-3a3a3b"
                      fontWeight="800"
                      fontSize="16px"
                      mb={3}
                    >
                      Play Points
                    </Typography>
                  </Grid>
                  <Grid className="resultScore">
                    <h2>
                      {resultData?.resultPP == undefined
                        ? 0
                        : resultData?.resultPP}
                      /{data?.LPXp == undefined ? data.assignedPP : data?.LPXp}
                      PP
                    </h2>
                    <Typography
                      className="text-3a3a3b"
                      fontWeight="800"
                      fontSize="16px"
                    >
                      Score
                    </Typography>
                  </Grid>
                  {/* </>
                  );
                })} */}
                  <Divider sx={{ border: "1px solid #cddc39", mt: "32px" }} />
                  <Grid className="resultGrid1">
                    <Grid className="resultValue">
                      <Typography fontWeight="800" fontSize="14px">
                        Locomotor&nbsp; (
                        {Locomotor == undefined ? "0" : Locomotor}
                        %)
                      </Typography>
                      <Typography fontWeight="800" fontSize="14px">
                        Body Management&nbsp; (
                        {BodyManagement == undefined ? "0" : BodyManagement}
                        %)
                      </Typography>
                      <Typography fontWeight="800" fontSize="14px">
                        Object Control&nbsp; (
                        {ObjectControl == undefined ? "0" : ObjectControl}
                        %)
                      </Typography>
                    </Grid>
                    <Grid className="resultPPs" sx={{ maxWidth: "90px" }}>
                      {console.log(locomotor, bodyManagement, objectControl)}
                      <Typography fontWeight="800" fontSize="14px">
                        {locomotor?.resultPP} PP
                      </Typography>
                      <Typography fontWeight="800" fontSize="14px">
                        {bodyManagement?.resultPP} PP
                      </Typography>
                      <Typography fontWeight="800" fontSize="14px">
                        {objectControl?.resultPP} PP
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>

            <Grid
              className={
                isActive !== 0 ? "d-none" : "border-E4E6EF bg-white radius-10"
              }
              item
              xs={12}
              // className="border-E4E6EF bg-white radius-10"
              p={2}
              pb="2px"
              mb={1}
            >
              <Typography className="font-w-700" mb="10px">
                Related Sports
              </Typography>

              <Grid
                display="flex"
                height="57px"
                alignItems="center"
                overflowX="scroll"
                className={
                  lpdata?.sports?.length !== 0
                    ? "popular-search-recomonded"
                    : "d-none"
                }
              >
                {lpdata?.sports?.map((item, key) => (
                  <span key={key} className="popular-search exp-text-color">
                    {item}
                  </span>
                ))}
              </Grid>
              <Typography
                className={
                  lpdata?.sports?.length === 0
                    ? "font-14 font-w-700 text-3a3a3b"
                    : "d-none"
                }
                fontSize={"14px"}
                pb={"10px"}
              >
                Do not have Related Sports
              </Typography>
            </Grid>

            <Grid container className={isActive !== 0 ? "d-none" : null}>
              <Grid
                container
                row
                className="radius-10 border-E4E6EF d-flex bg-white"
                p={2}
                mb={2}
              >
                <Typography mb={2} className="font-w-700">
                  Skills you will learn
                </Typography>
                <Grid item xs={12} className="gameCard scollbar-none">
                  {mainSkills?.map((item, key) => {
                    console.log("Item: ", item);
                    return (
                      <>
                        <Grid
                          className={
                            item === "Object Control"
                              ? "radius-10 border-E4E6EF"
                              : "d-none"
                          }
                          p={1}
                          m={1}
                          sx={{ maxWidth: "150px" }}
                        >
                          <img src={objCon} height="87" />
                          <Typography className="manageControl">
                            Object
                            <br /> Control
                          </Typography>
                        </Grid>

                        <Grid
                          className={
                            item === "Locomotor"
                              ? "radius-10 border-E4E6EF"
                              : "d-none"
                          }
                          p={1}
                          m={1}
                          sx={{ maxWidth: "150px" }}
                        >
                          <img src={loco} height="87" alt="" />
                          <Typography className="manageControl">
                            Locomotor
                          </Typography>
                        </Grid>

                        <Grid
                          className={
                            item === "Body Management"
                              ? "radius-10 border-E4E6EF"
                              : "d-none"
                          }
                          p={1}
                          m={1}
                          sx={{ maxWidth: "150px" }}
                        >
                          <img src={objCon} height="87" alt="" />
                          <Typography className="manageControl">
                            Body management
                          </Typography>
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
              </Grid>
              {/* </Grid> */}

              {/* <Grid container padding={2}> */}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          p={2}
          mt={-12}
          mb={5}
          className={isActive !== 0 ? "d-none" : null}
        >
          <Grid className="Box radius-15 border-E4E6EF " xs={12} pt={2}>
            <Typography
              paddingTop="1px"
              sx={{ paddingBottom: "10px", color: "#030304" }}
              fontWeight="bold"
              color="#3A3A3B"
            >
              Subskill you will learn
            </Typography>

            <Grid container item>
              <Grid item xs={12} pr={2}>
                <Grid item xs={12} className="gameCard scollbar-none w-100">
                  {lpdata?.skills?.map((item, key) => {
                    return (
                      <Grid
                        className="radius-10 border-E4E6EF"
                        p={1}
                        m={1}
                        sx={{ maxWidth: "150px" }}
                      >
                        <img src={item?.skillImg} height="87" />
                        <Typography className="manageControl">
                          {item?.subSkill}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid className="Box radius-15 border-E4E6EF " xs={12} pt={2}>
            <Typography
              paddingTop="1px"
              sx={{ paddingBottom: "10px", color: "#030304" }}
              fontWeight="bold"
              color="#3A3A3B"
            >
              Equipment you will need
            </Typography>

            <Grid container item>
              <Grid item xs={12} pr={2}>
                <Grid item xs={12}>
                  {lpdata?.equipments?.map((item, key) => {
                    return (
                      <>
                        <ul className="equipment-list">
                          <li>
                            <Typography fontWeight={"bold"}>
                              {item?.name}
                            </Typography>
                          </li>
                          <Grid mb={2} className="gameCard scollbar-none w-100">
                            {item?.addedEquipment?.map((data, key) => {
                              return (
                                <Grid
                                  className="radius-10 border-E4E6EF"
                                  p={1}
                                  m={1}
                                  sx={{ maxWidth: "150px" }}
                                >
                                  <img src={data?.equipmentImg} height="87" />
                                  <Typography className="manageControl">
                                    {data?.equipmentName}
                                  </Typography>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </ul>
                      </>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            row
            textAlign="center"
            display="flex"
            padding={2}
            marginTop="8px"
            className="radius-10 border-E4E6EF bg-white"
            mb={2}
          >
            <Grid item xs={12} textAlign="left" marginBottom={2}>
              <Typography
                sx={{ color: "#3A3A3B !important", fontWeight: "bold" }}
              >
                You’ll Get
              </Typography>
            </Grid>

            <Grid container display="flex" alignItems="center">
              <Grid item xs={4} className="radius-8 h-59 border-2-cddc39">
                <Typography
                  fontSize={parseInt(lpdata?.LPXp) > 999 ? "40px" : "48px"}
                  className="font-w-700 text-cddc39"
                  mt={parseInt(lpdata?.LPXp) > 999 ? "-4px" : "-7px"}
                >
                  {lpdata?.LPXp == undefined ? 0 : lpdata?.LPXp}
                </Typography>
              </Grid>

              <Grid item xs={8} textAlign="left" pl={1}>
                <Typography className="font-w-700 light-black">
                  Play Points
                </Typography>
                <Typography className="RSRCTabStyle font-12">
                  More the play points, Higher the rank.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div className="completeBtns">
          <button>Expired</button>
        </div>
      </>
    </>
  );
}
