import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { IoMdHeartEmpty } from "react-icons/io";
import bouncing from "../../../assets/img/BouncingBall.png";
import { FETCH_URL } from "../../../fetchIp";
import { useAuth } from "../../../Context/AuthContext";
import axios from "axios";

export default function CompleteResult() {
  let LocoSkill = 0;
  let BodySkill = 0;
  let ObjSkill = 0;
  let LocoPer = 0;
  let BodyPer = 0;
  let ObjPer = 0;
  const navigate = useNavigate();
  const [Locomotor, setLocomotor1] = useState();
  const [BodyManagement, setBodyManagement1] = useState();
  const [ObjectControl, setObjectControl1] = useState();
  const [lpdata, setLpdata] = useState([]);
  const [locomotor, setLocomotor] = useState({
    locopercent: 0,
    resultPP: 0,
  });
  const [bodyManagement, setBodyManagement] = useState({
    bodypercent: 0,
    resultPP: 0,
  });
  const [objectControl, setObjectControl] = useState({
    objectpercent: 0,
    resultPP: 0,
  });
  const [skillData, setSkillData] = useState();

  const { trainerId } = useAuth();
  const location = useLocation();

  const stateLP = location.state.item;
  console.log(stateLP, "stateData lp result");

  console.log(stateLP._id, "stateData lp id");

  const courseResid = location.state.resId;
  console.log(courseResid, "courseResid");

  const courseid = location.state.courseId;
  console.log(courseid, "stateCourseId");

  function skills() {
    axios
      .post(`${FETCH_URL}/api/assessment/getResultByLessonPlanIds`, {
        userId: trainerId,
        lessonPlanIds: [{ lessonPlanId: stateLP._id }],
      })
      .then((resp) => {
        console.log(resp.data.msg, "skills data");
        setSkillData(resp.data.msg);
        resp.data.msg[0].skillScore.map((item) => {
          if (item.mainSkill == "Body Management") {
            setBodyManagement({
              bodypercent: item.percent,
              resultPP: item.points,
            });
          } else if (item.mainSkill == "Locomotor") {
            setLocomotor({ locopercent: item.percent, resultPP: item.points });
          } else if (item.mainSkill == "Object Control") {
            setObjectControl({
              objectpercent: item.percent,
              resultPP: item.points,
            });
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function lpImg() {
    axios
      .get(`${FETCH_URL}/api/lessonplan/getSingleLessonPlans/${stateLP._id}`)
      .then((resp) => {
        console.log(resp.data.msg, "single lesson data");
        setLpdata(resp.data.msg);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const skillData1 = () => {
    lpdata?.skills?.map((data) => {
      if (data.mainSkill == "Body Management") {
        BodySkill++;
        BodyPer += parseInt(data.percent);
      }
      if (data.mainSkill == "Object Control") {
        ObjSkill++;
        ObjPer += parseInt(data.percent);
      }
      if (data.mainSkill == "Locomotor") {
        LocoSkill++;
        LocoPer += parseInt(data.percent);
      }
    });
    if (LocoPer !== 0) {
      LocoPer = LocoPer / LocoSkill;
      setLocomotor1(LocoPer);
    }
    if (BodyPer !== 0) {
      BodyPer = BodyPer / BodySkill;
      setBodyManagement1(BodyPer);
    }
    if (ObjPer !== 0) {
      ObjPer = ObjPer / ObjSkill;
      setObjectControl1(ObjPer);
    }
  };

  useEffect(() => {
    skills();
    lpImg();
  }, []);

  useEffect(() => {
    skillData1();
  }, [lpdata]);

  return (
    <>
      <div
        className="OnGoingProfileImage"
        style={{
          backgroundImage: `url("${lpdata?.LPThumbnail}")`,
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          className="oncontainerimg"
        >
          <Grid item>
            <IoIosArrowBack
              fontSize="2rem"
              onClick={() => {
                navigate(-1);
              }}
            />
          </Grid>
          <Grid item>
            <IoMdHeartEmpty fontSize="2.5rem" />
          </Grid>
        </Grid>
      </div>
      <Grid>
        <Grid
          container
          style={{ marginLeft: "0px", width: "100%", zIndex: 1111 }}
        >
          <Grid item xs={12} className="oncontainerdatacard">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Typography
                // className="GreyColorTypography onlptitle"
                fontSize="22px"
                color="black"
                fontWeight="bold"
                marginLeft="3%"
              >
                {stateLP?.title}
              </Typography>
              <Typography className="GreenColorTypoButton  Hpptypo">
                {lpdata?.LPXp} PP
              </Typography>
            </Grid>
            <Grid item className="pl">
              <Progress percent={100} className="progressbar" />
              <Typography className="BlackColorTypography  mt20px">
                About
                <Typography>
                  <h6 style={{ marginTop: "4px", fontSize: "13px" }}>
                    {lpdata?.LPDesc}
                  </h6>
                </Typography>
              </Typography>
            </Grid>
          </Grid>

          <Grid className="skillbox">
            <div className="skillPoint">
              <div className="skillText">
                <h2>Skills you will learn</h2>
              </div>
              <div className="skillFlex">
                <div className="skillPP">
                  <h3>{Locomotor == undefined ? " 0" : Locomotor}%</h3>
                  <h5>Locomotor</h5>
                </div>
                <div className="skillPP">
                  <h3>
                    {BodyManagement == undefined ? " 0" : BodyManagement}%
                  </h3>
                  <h5>Body Management</h5>
                </div>
                <div className="skillPP">
                  <h3>{ObjectControl == undefined ? " 0" : ObjectControl}%</h3>
                  <h5>Object Control</h5>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid className="Box" xs={12}>
          <div>
            <div className="resultPP">
              <h6>Your Result</h6>
              <h3>
                {stateLP?.points}/{lpdata?.LPXp} PP
              </h3>
              <h4>Play Points</h4>
            </div>
            <div className="resultScore">
              <h2>
                {stateLP?.points}/{lpdata?.LPXp} PP
              </h2>
              <h5>Score</h5>
            </div>
            <hr className="wid" />
          </div>
          <div className="resultGrid">
            <div className="resultValue">
              <h3>
                Locomotor&nbsp; {Locomotor == undefined ? "0" : Locomotor}%
              </h3>
              <h3>
                Body Management&nbsp;
                {BodyManagement == undefined ? "0" : BodyManagement}%
              </h3>
              <h3>
                Object Control&nbsp;
                {ObjectControl == undefined ? "0" : ObjectControl}%
              </h3>
            </div>
            <div className="resultPPs">
              <h3>{locomotor.resultPP} PP</h3>
              <h3>{bodyManagement.resultPP} PP</h3>
              <h3>{objectControl.resultPP} PP</h3>
            </div>
          </div>
        </Grid>
      </Grid>
      <div className="completeBtns">
        <button>Completed</button>
      </div>
    </>
  );
}
