import React, { useState, useEffect } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { IoMdHeartEmpty } from "react-icons/io";
import bouncing from "../../../assets/img/BouncingBall.png";
import Rect from "../../../assets/img/Rectangle 3007.png";
import card from "../../../assets/img/Rectangle 3235.png";
import { useAuth } from "../../../Context/AuthContext";
import { FETCH_URL } from "../../../fetchIp";
import { useSnackbar } from "notistack";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import objCon from "../../../assets/img/Rectangle 3464.png";
import loco from "../../../assets/img/Rectangle 3468.png";
import { FiLock } from "react-icons/fi";
import LoadingSpinner from "../../Spinner/LoadingSpinner";

import level1 from "../../../assets/img/level1.png";
import level2 from "../../../assets/img/level2.png";
import level3 from "../../../assets/img/level3.png";
import level4 from "../../../assets/img/level4.png";
import level5 from "../../../assets/img/level5.png";
import level6 from "../../../assets/img/level6.png";
import level7 from "../../../assets/img/level7.png";
import level8 from "../../../assets/img/level8.png";
import level9 from "../../../assets/img/level9.png";
import level10 from "../../../assets/img/level10.png";
import level11 from "../../../assets/img/level11.png";
import level12 from "../../../assets/img/level12.png";
import level13 from "../../../assets/img/level13.png";
import level14 from "../../../assets/img/level14.png";
import level15 from "../../../assets/img/level15.png";
import level16 from "../../../assets/img/level16.png";
import level17 from "../../../assets/img/level17.png";
import ReactPlayer from "react-player";

// import axios from "axios";

export default function StartLevel() {
  const navigate = useNavigate();
  const [completeLevels, setCompleteLevels] = useState(0);
  const { userDetails, trainerId } = useAuth();
  const location = useLocation();

  // const [courseData, setCourseData] = useState(location.state);
  const courseData = location?.state?.data;
  const stateItem = location?.state?.data;

  const [coresult, setCoresult] = useState();
  const [totalPP, setTotalPP] = useState();
  const [lessonPlanLength, setLessonPlanLength] = useState();
  const [skillAbout, setSkillAbout] = useState(0);
  const [percentage, setPercentage] = useState({
    percent: 0,
    totalpercent: 0,
  });
  const [isActive, setIsActive] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [resId, setResId] = useState([]);
  const [overallPP, setOverllPP] = useState();
  const userId = localStorage.getItem("userId");
  const serviceUserId = localStorage.getItem("serviceUserId");
  const [readAbout, setReadAbout] = useState(0);

  let mainSkills = [];
  stateItem?.skills?.map((item) => {
    if (!mainSkills?.includes(item?.mainSkill))
      mainSkills.push(item?.mainSkill);
  });

  let BlockClick = 0;
  let LockData = 0;

  const imgArr = [
    level1,
    level2,
    level3,
    level4,
    level5,
    level6,
    level7,
    level8,
    level9,
    level10,
    level11,
    level12,
    level13,
    level14,
    level15,
    level16,
    level17,
  ];

  console.log(" courseData========>", location.state);

  let courseId = courseData?._id;

  let pp = 0;
  courseData?.lessonPlans?.map((data, index) => {
    pp += data?._id?.LPXp;
  });

  console.log(userDetails, "userDetails");

  let imgUrl =
    stateItem?.Thumbnail == undefined
      ? stateItem?.courseThumbnail
      : stateItem?.Thumbnail;

  useEffect(() => {
    const getResultId = async () => {
      setIsLoading(true);
      try {
        const Result = await fetch(`${FETCH_URL}/api/assessment/createResult`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            userId: userDetails?._id,
            name: userDetails?.trainerDetail?.fullName,
            isTrainer: true,
            isCourse: true,
            courseData: { lessonPlans: courseData?.lessonPlans },
            assignedPP: courseData?.assignedPP,
            courseId: courseData?._id,
          }),
        });

        const res = await Result.json();
        console.log(res, "result course");
        setCoresult(res?.msg2?.courseData);
        setLessonPlanLength(res?.msg2?.courseData?.lessonPlans?.length);
        let count = 0;
        res?.msg2?.courseData?.lessonPlans?.map((data, i) => {
          if (data?.completion) count++;
        });
        setPercentage({
          percent: count,
          totalpercent: res?.msg2?.courseData?.lessonPlans?.length,
        });
        let OPP = 0;
        res?.msg2?.courseData?.lessonPlans?.map((item) => {
          OPP += item.assignedPP;
        });
        setOverllPP(OPP);
        setResId(res?.msg2?._id);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    getResultId();
  }, []);

  const showAlert = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant,
      autoHideDuration: 2000,
    });
  };

  let addClass = "";
  if (+overallPP > 999) {
    addClass = "fs-41";
  } else if (+overallPP > 9999) {
    addClass = "fs-33";
  } else if (+overallPP > 99999) {
    addClass = "fs-28";
  }

  useEffect(() => {
    setTotalPP(pp);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    if (window.ReactNativeWebView) window.ReactNativeWebView.postMessage(1);
  }, []);

  return (
    <>
      {isLoading ? <LoadingSpinner /> : null}
      <div
        className="OnGoingProfileImage"
        style={{
          backgroundImage:
            stateItem?.heroContent?.type == "image"
              ? `url('${stateItem?.heroContent?.url}')`
              : "",
        }}
      >
        <Grid
          container
          className={stateItem?.heroContent?.type == "image" ? "d-none" : ""}
        >
          <Grid xs={12} className="video-grid">
            <ReactPlayer
              url={stateItem?.heroContent?.url}
              playing={true}
              loop={true}
              width={"100%"}
              height={"236px"}
            />
          </Grid>
        </Grid>

        <Grid
          position={"absolute"}
          top={"0px"}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          className="container-img"
        >
          <Grid item>
            <IoIosArrowBack
              className="back-whishlist-icon"
              onClick={() => {
                navigate(-1);
                if (window.ReactNativeWebView)
                  window.ReactNativeWebView.postMessage("a");
              }}
            />
          </Grid>

          <Grid item></Grid>
        </Grid>
      </div>

      <Grid container pl="12px" pr="12px" mt="10px" className="bg-white">
        <Grid
          item
          sx={{ overflowX: "scroll" }}
          display="flex"
          className="scrollbar-none"
        >
          <span
            className={isActive === 0 ? "category bg-color" : "category"}
            onClick={() => setIsActive(0)}
          >
            Overview
          </span>
          <span
            className={isActive === 1 ? "category bg-color" : "category"}
            onClick={() => setIsActive(1)}
          >
            Play
          </span>
          <span
            className={isActive === 2 ? "category bg-color" : "category"}
            onClick={() => setIsActive(2)}
          >
            Curriculum
          </span>
          <span
            className={isActive === 3 ? "category bg-color" : "category"}
            onClick={() => setIsActive(3)}
          >
            Benefit
          </span>
        </Grid>
      </Grid>

      <Grid className={isActive === 1 ? "d-none" : null}>
        <Grid
          container
          style={{ marginLeft: "0px", width: "100%", zIndex: 1111 }}
          padding={2}
          mb={6}
        >
          <Grid
            mb={1}
            item
            xs={12}
            p={1}
            className="oncontainerdatacard border-E4E6EF radius-8"
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              className={isActive >= 1 ? "d-none" : null}
            >
              <Typography
                color="#030304"
                className="cl-title1"
                fontWeight="bold"
                marginLeft="3%"
              >
                {/* For 3 to 4 year olds */}
                {stateItem?.courseTitle}
              </Typography>
              <Typography className="GreenColorTypoButton p-7-9">
                PP {overallPP}
              </Typography>
            </Grid>

            <Grid>
              {isActive === 0 ? (
                <Grid>
                  <Typography className="BlackColorTypography  mt20px">
                    About
                  </Typography>

                  <Typography
                    fontSize={14}
                    className={readAbout === 1 ? null : "height-70"}
                  >
                    {stateItem?.courseDesc}
                  </Typography>

                  <div style={{ height: "4px" }}></div>

                  <div
                    className={
                      stateItem?.LPDesc?.length > 400 ? "text-center" : "d-none"
                    }
                  >
                    <Button
                      onClick={() => setReadAbout(1)}
                      className={
                        readAbout === 1
                          ? "d-none text-d1e700 transform-none"
                          : "text-d1e700 transform-none"
                      }
                    >
                      Read more
                    </Button>
                  </div>
                </Grid>
              ) : null}

              {isActive === 2 ? (
                <Grid>
                  <Typography fontSize={14}>
                    {stateItem?.curriculum == undefined ||
                    stateItem?.curriculum?.length == 0
                      ? "No data Found"
                      : stateItem?.curriculum}
                  </Typography>
                </Grid>
              ) : null}

              {isActive === 3 ? (
                <Grid>
                  <Typography fontSize={14}>
                    {stateItem?.benefits == undefined ||
                    stateItem?.benefits?.length == 0
                      ? "No data Found"
                      : stateItem?.benefits}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            className={
              isActive === 0 ? "border-E4E6EF bg-white radius-10" : "d-none"
            }
            p={2}
            pb="2px"
            mb={1}
          >
            <Typography className="font-w-700" mb="10px">
              Related Sports
            </Typography>

            <Grid
              display="flex"
              height="57px"
              alignItems="center"
              overflowX="scroll"
              className={
                stateItem?.sports?.length !== 0
                  ? "popular-search-recomonded"
                  : "d-none"
              }
            >
              {stateItem?.sports?.map((item, key) => (
                <span key={key} className="popular-search exp-text-color">
                  {item}
                </span>
              ))}
            </Grid>
            <Typography
              className={
                stateItem?.sports?.length === 0
                  ? "font-14 font-w-700 text-3a3a3b"
                  : "d-none"
              }
              pb={"10px"}
            >
              Do not have Related Sports
            </Typography>
          </Grid>

          <Grid container className={isActive !== 0 ? "d-none" : null}>
            <Grid
              container
              row
              className="radius-10 border-E4E6EF d-flex bg-white"
              p={2}
              mb={2}
            >
              <Typography mb={2} className="font-w-700">
                Skills you will learn
              </Typography>
              <Grid item xs={12} className="gameCard scollbar-none">
                {mainSkills?.map((item, key) => {
                  console.log("Item: ", item);
                  return (
                    <>
                      <Grid
                        className={
                          item === "Object Control"
                            ? "radius-10 border-E4E6EF"
                            : "d-none"
                        }
                        p={1}
                        m={1}
                        sx={{ maxWidth: "150px" }}
                      >
                        <img src={objCon} height="87" />
                        <Typography className="manageControl">
                          Object
                          <br /> Control
                        </Typography>
                      </Grid>

                      <Grid
                        className={
                          item === "Locomotor"
                            ? "radius-10 border-E4E6EF"
                            : "d-none"
                        }
                        p={1}
                        m={1}
                        sx={{ maxWidth: "150px" }}
                      >
                        <img src={loco} height="87" alt="" />
                        <Typography className="manageControl">
                          Locomotor
                        </Typography>
                      </Grid>

                      <Grid
                        className={
                          item === "Body Management"
                            ? "radius-10 border-E4E6EF"
                            : "d-none"
                        }
                        p={1}
                        m={1}
                        sx={{ maxWidth: "150px" }}
                      >
                        <img src={objCon} height="87" alt="" />
                        <Typography className="manageControl">
                          Body management
                        </Typography>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        mt={-9}
        className={
          isActive === 0 || isActive === 1
            ? isActive === 1
              ? "levelBoxes1 mt-20 bg-white"
              : "levelBoxes1 bg-white"
            : "d-none"
        }
      >
        <Typography
          className="onleveltypo"
          marginLeft="3.5%"
          paddingTop="1px"
          paddingBottom="2%"
          fontWeight="bold"
          color="#3A3A3B"
          mb={2}
          pt={2}
        >
          Lessons
        </Typography>

        {coresult?.lessonPlans?.map((item, index) => {
          let open = 0;
          // BlockClick = index + 1;
          if (lessonPlanLength == 1) {
            BlockClick = 1;
          }
          if (item?.completion == false) {
            //|| item?.completion == true) {
            LockData += 1;
          }
          if (item?.completion == true) {
            open = 1;
            BlockClick = index + 2;
          }
          if (item?.completion == false && LockData == 2) {
            BlockClick = index;
          }
          return (
            <>
              <div
                key={index}
                id="first"
                onClick={
                  open == 0
                    ? () =>
                        LockData >= 1 &&
                        index < BlockClick &&
                        navigate(`/student/coursesstart`, {
                          state: {
                            item,
                            resId,
                            courseId,
                            lessonPlanLength,
                            actualLessonPlanLength: index + 1,
                            stateItem,
                            isMarketPlace: false,
                          },
                        })
                    : () =>
                        navigate(`/student/coursesstart`, {
                          state: {
                            item,
                            resId,
                            courseId,
                            lessonPlanLength,
                            actualLessonPlanLength: index + 1,
                            stateItem,
                            isMarketPlace: false,
                          },
                        })
                }
              >
                <Grid container item className="oncard2">
                  <Grid item xs={2}>
                    <img
                      src={imgArr[index]}
                      alt=""
                      height="50px"
                      style={{ marginTop: "10px", marginLeft: "-20px" }}
                    />
                  </Grid>
                  <Grid item xs={9} className="pl40px" pt={1}>
                    <Grid container item justifyContent="space-between">
                      <Typography
                        fontSize="17px"
                        className="GreyColorTypography first-cap triple-dot"
                      >
                        {item?.title}
                      </Typography>
                      <Typography className="GreenColorTypoButton  Hpptypo onpptypo">
                        {item?.assignedPP} PP
                      </Typography>
                    </Grid>
                    <Typography
                      fontSize="14px"
                      className="GreyColorTypo"
                    ></Typography>
                    <Progress
                      percent={item.completion ? 100 : 0}
                      className="progressbar"
                    />
                  </Grid>
                </Grid>
                <div
                  id={LockData > 1 ? "second" : "first"}
                  className={lessonPlanLength == 1 ? "d-none" : null}
                >
                  <Grid container item className="onquestioncard2">
                    <Grid item xs={11.8}>
                      <div
                        // className="lockInfo"
                        className={
                          completeLevels?.length == lessonPlanLength ||
                          completeLevels?.length == lessonPlanLength - 1
                            ? "d-none"
                            : "lockInfo"
                        }
                        // className={"lockInfo"}
                      >
                        <FiLock
                          fontSize="45px"
                          style={{ margin: "auto", display: "block" }}
                        />

                        <h4 style={{ marginTop: "0px" }}>
                          Complete above level to unlock this
                        </h4>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </>
          );
        })}
      </Grid>

      <Grid
        container
        p={2}
        mt={-2}
        className={isActive !== 0 ? "d-none" : null}
      >
        <Grid className="Box radius-15 border-E4E6EF " xs={12} pt={2}>
          <Typography
            paddingTop="1px"
            sx={{ paddingBottom: "10px", color: "#030304" }}
            fontWeight="bold"
            color="#3A3A3B"
          >
            Subskill you will learn
          </Typography>

          <Grid container item>
            <Grid item xs={12} pr={2}>
              <Grid item xs={12} className="gameCard scollbar-none w-100">
                {stateItem?.skills?.map((item, key) => {
                  return (
                    <Grid
                      className="radius-10 border-E4E6EF"
                      p={1}
                      m={1}
                      sx={{ maxWidth: "150px" }}
                    >
                      <img src={item?.skillImg} height="87" />
                      <Typography className="manageControl">
                        {item?.subSkill}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid className="Box radius-15 border-E4E6EF " xs={12} pt={2}>
          <Typography
            paddingTop="1px"
            sx={{ paddingBottom: "10px", color: "#030304" }}
            fontWeight="bold"
            color="#3A3A3B"
          >
            Equipment you will need
          </Typography>

          <Grid container item>
            <Grid item xs={12} pr={2}>
              <Grid item xs={12}>
                {stateItem?.equipmentKit?.map((item, key) => {
                  return (
                    <>
                      <ul className="equipment-list">
                        <li>
                          <Typography fontWeight={"bold"}>
                            {item?.name}
                          </Typography>
                        </li>
                        <Grid mb={2} className="gameCard scollbar-none w-100">
                          {item?.addedEquipment?.map((data, key) => {
                            return (
                              <Grid
                                className="radius-10 border-E4E6EF"
                                p={1}
                                m={1}
                                sx={{ maxWidth: "150px" }}
                              >
                                <img src={data?.equipmentImg} height="87" />
                                <Typography className="manageControl">
                                  {data?.equipmentName}
                                </Typography>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </ul>
                    </>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          row
          textAlign="center"
          display="flex"
          padding={2}
          marginTop="8px"
          className="radius-10 border-E4E6EF bg-white"
          mb={2}
        >
          <Grid item xs={12} textAlign="left" marginBottom={2}>
            <Typography
              sx={{ color: "#3A3A3B !important", fontWeight: "bold" }}
            >
              You’ll Get
            </Typography>
          </Grid>

          <Grid container display="flex" alignItems="center">
            <Grid
              item
              xs={4}
              className={`radius-8 h-73 `}
              style={{ border: "2px solid #CDDC39" }}
            >
              <Typography
                fontSize="48px"
                className={`font-w-700 text-cddc39 ${addClass}`}
              >
                {/* {stateItem.assignedPP} */}
                {overallPP}
              </Typography>
            </Grid>

            <Grid item xs={8} textAlign="left" pl={1}>
              <Typography className="font-w-700 light-black">
                Play Points
              </Typography>
              <Typography className="RSRCTabStyle font-12">
                More the play points, Higher the rank.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
