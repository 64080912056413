import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import {
  Typography,
  Grid,
  Drawer,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Toolbar,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { HiMenuAlt1 } from "react-icons/hi";
import { AiFillSetting } from "react-icons/ai";
import { AiOutlineLogout } from "react-icons/ai";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import MamImg from "../../assets/img/Man.png";
import { Link, useNavigate } from "react-router-dom";
import routes from "../../routes";
import { useAuth } from "../../Context/AuthContext";
import axios from "axios";
import { FETCH_URL } from "../../fetchIp";

export default function PrimarySearchAppBar() {
  const ref = React.useRef(null);
  const pathname = window.location.pathname;
  const [bottomvalue, setBottomValue] = useState(pathname);
  const [value, setValue] = React.useState(0);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [userDetails, setUserDetail] = useState([]);
  const navigate = useNavigate();

  const { trainerId, authHelper } = useAuth();
  const { signOut } = authHelper;

  const BasicInfo = [
    {
      BasicTypo: " Build foundation skills     ",
    },
    {
      BasicTypo: "  6 essential Skills and games     ",
    },
    {
      BasicTypo: " Basic equipment kit   ",
    },
    {
      BasicTypo: "  Basic FMS Reports   ",
    },
  ];

  console.log(
    "URL: ",
    `${FETCH_URL}/api/learner/getSingleTrainer/${trainerId}`
  );

  const getSingleTrainer = async () => {
    try {
      const resp = await axios.get(
        `${FETCH_URL}/api/learner/getSingleTrainer/${trainerId}`
      );
      setUserDetail(resp?.data?.msg);

      console.log("Data is ", resp);
    } catch (err) {}
  };

  const FlushFirebaseToken = async () => {
    try {
      const responce = await axios.get(
        `/api/learner/removeNotificationTokenForTrainer/${trainerId}`
      );
      if (responce.ok) {
        console.log("Response is Signout", responce);
        console.log("Response is 123123", responce);

        return true;
      }
    } catch (err) {
      console.log("Error is ", err);
    }
  };

  const logoutMe = () => {
    FlushFirebaseToken();

    if (window.ReactNativeWebView) window.ReactNativeWebView.postMessage(true);
    navigate("/auth/signin");
    signOut();
  };

  useEffect(() => {
    getSingleTrainer();
  }, []);

  return (
    <>
      <HiMenuAlt1
        className="Fs-2-4"
        color="inherit"
        onClick={() => setOpenDrawer(!openDrawer)}
      />
      <Box ref={ref}>
        <Paper
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
          elevation={3}
        >
          <div>
            <React.Fragment>
              <Drawer
                anchor={"left"}
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                onOpen={() => setOpenDrawer(true)}
              >
                <Box
                  role="presentation"
                  sx={{
                    width: 220,
                    backgroundColor: "#ffffff",
                  }}
                >
                  <List disablePadding className="">
                    <Grid container className="SideBarProfielconatienr">
                      <Grid
                        container
                        item
                        xs={5}
                        className="SideBarProfielItem"
                      >
                        <img
                          src={userDetails?.trainerDetail?.trainerImg}
                          className="SideBarProfielImage"
                        />
                      </Grid>
                      <Grid item xs={7} className="SideBarProfielTypo">
                        <Typography
                          className="WhiteColorTypography400 w-95-per triple-dot first-cap"
                          style={{ fontSize: "16px", fontWeight: "900" }}
                        >
                          {userDetails?.trainerDetail?.fullName}
                        </Typography>
                        <Link
                          style={{ textDecoration: "none" }}
                          to="/student/profile"
                        >
                          <Typography
                            className="WhiteColorTypography400"
                            style={{ paddingTop: "5px", fontSize: "10px" }}
                          >
                            View Profile
                          </Typography>
                        </Link>
                      </Grid>
                    </Grid>
                    {routes.map((route) => {
                      if (route.invisible === false) {
                        return (
                          <ListItem
                            button
                            className="ListItemStyle"
                            component={Link}
                            to={route.layout + route.link}
                            selected={value === route.activeIndex}
                            onClick={() => {
                              setOpenDrawer(false);
                              setValue(route.activeIndex);
                            }}
                          >
                            <ListItemIcon className="SideBarIcon GcTypo ">
                              {route.icon}
                            </ListItemIcon>
                            <ListItemText
                              disableTypography
                              className="GcTypoText"
                            >
                              {route.name}
                            </ListItemText>
                          </ListItem>
                        );
                      }
                    })}
                    <List>
                      <ListItem
                        className="ListItemLogoutStyle"
                        button
                        // component={Link}
                        // to="/student/Logout"
                        onClick={() => {
                          setOpenDrawer(false);
                          setValue(routes.activeIndex);

                          logoutMe();
                        }}
                      >
                        <ListItemIcon
                          style={{
                            minWidth: "40px",
                            color: "#cddc39",
                          }}
                          className="SideBarIcon GcTypo "
                        >
                          <AiOutlineLogout />
                        </ListItemIcon>
                        <ListItemText disableTypography className="GcTypoText">
                          Logout
                        </ListItemText>
                      </ListItem>
                    </List>
                  </List>
                </Box>
              </Drawer>
            </React.Fragment>
          </div>
        </Paper>
      </Box>
    </>
  );
}
