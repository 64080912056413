import React from "react";
import { useAuth } from "../../../Context/AuthContext";

const Academic = () => {
  const { userDetails } = useAuth();
  console.log(userDetails, "userDetails");

  return (
    <>
      <div className="formContainer pb-pt-24px bg-white">
        <form>
          <div className="GreyColorTypography school">
            <label>School</label>
            <input
              className="outline-noen pl-15"
              type="text"
              readOnly={true}
              value={userDetails?.assignedSchool[0]?.schoolName}
              placeholder={"School Name"}
            />
          </div>
          <div className="locate GreyColorTypography ">
            <div className="locateInfo1">
              <label>Location</label>
              <input
                type="text"
                readOnly={true}
                value={userDetails?.assignedSchool[0]?.location}
                placeholder="Location"
                className="outline-noen pl-15"
              />
            </div>
            {/* <div className="locateInfo">
              <label>State</label>
              <input type="text" />
            </div> */}
          </div>
          <div
            className="GreyColorTypography school"
            style={{ marginTop: "14px" }}
          >
            <label>Grade</label>
            <input
              type="text"
              className="outline-noen pl-15"
              readOnly={true}
              value={userDetails?.assignedSchool[0]?.gradeName}
              placeholder="Grade"
            />
          </div>
          {/* <div className="GreyColorTypography schoolBtn">
            <button>Save</button>
          </div> */}
        </form>
      </div>
    </>
  );
};

export default Academic;
