import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
// import myHtml from "./html/test.html";
import { useAuth } from "../../../../Context/AuthContext";
import { FETCH_URL } from "../../../../fetchIp";

function Mcqs() {
  const { trainerId } = useAuth();

  const { state } = useLocation();
  const navigate = useNavigate();

  console.log("data from useLocation", state.data._id);
  console.log("CourseId from useLocation", state.courseId);

  const [myState, setMyState] = React.useState("");
  const [currentIndex, setCurrentIndex] = React.useState(0);

  // If true then show result
  const [showResult, setShowResult] = React.useState(false);
  const [totalMarks, setTotalMarks] = React.useState(0);
  const [finalMarksState, setFinalMarks] = React.useState(0);
  const [resultId, setResultId] = useState(0);
  const [mcqMarks, setMcqMarks] = useState(0);
  const [myVisits, setMyVisits] = React.useState([{}]);

  // For submit button show
  const [showSubmit, setShowSubmit] = useState(false);

  // States for storing Question data:

  const [questionData, setQuestionData] = React.useState([]);

  const [shtml, setsHtml] = useState([]);

  const checkPrevChoice = (index) => {
    // Add on previous button
    const myFrame = document.getElementById("my-frame");
    console.log("I trigger=======>", index);
    // myFrame.srcdoc = "";
    // myFrame.srcdoc = shtml[index].pageData.pageHtml;
    console.log("My frame ======>", questionData[index].checkedAnswer);
    if (index >= 0) {
      if (
        questionData &&
        questionData.length > 0 &&
        questionData[index] &&
        questionData[index].isMcq &&
        questionData[index].checkedAnswer
      ) {
        myFrame.contentWindow.postMessage(
          { prevChoice: questionData[index].checkedAnswer },
          "*"
        );
      }
    }

    // // * Logic to show submit button
    // if (index + 1 === shtml.length) {
    //   console.log("Last page now show submit button.......");
    //   setShowSubmit(true);
    // } else {
    //   console.log("Not the last page, don't show submit.....");
    //   setShowSubmit(false);
    // }
    // myFrame.contentWindow.postMessage(message, "*");
  };

  const checkQuestions = async () => {
    // Saving the userScore
    console.log("Me just checking the questions ====>", questionData);

    let total = 0;

    console.log(
      "Triggering on prev button call in react ======>",
      questionData
    );

    for (let i = 0; i < questionData.length; i++) {
      if (questionData[i].isMcq) {
        if ("checkedAnswer" in questionData[i]) {
          if (questionData[i].correctChoice === questionData[i].checkedAnswer) {
            total = total + questionData[i].quesMarks;
          }
        }
      }
    }

    // * All this above logic goes to API call for saving the data, on Submit button....
    console.log("My total from the finale =======>", total);
    // ! Save to API
    let resultPP = (state.data._id.LPXp * total) / mcqMarks;
    try {
      const response = await fetch(
        `${FETCH_URL}/api/assessment/calculateResult/${resultId}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            totalScore: total,
            resultPP,
            courseId: state.courseId,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("On response arrived after creating page", res);
        // setsHtml(res.msg.pages);
        // navigate(-1);
        navigate("/student/course");
      } else {
        console.log("On resp err on creating page", res.err);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const getResultId = async () => {
      try {
        const response1 = await fetch(
          `${FETCH_URL}/api/learner/getSingleTrainer/${trainerId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const res1 = await response1.json();
        console.log("data from get single trainer", res1.msg);

        const Result = await fetch(`${FETCH_URL}/api/assessment/createResult`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            userId: trainerId,
            name: res1.msg.trainerDetail.fullName,
            isTrainer: true,
            isLessonPlan: true,
            lessonPlan: {
              _id: state.data._id._id,
              skills: state.data._id.skills,
            },
            trainerAssignedTo: res1.msg.assignedSchool,
            assignedPP: state.data._id.LPXp,
          }),
        });

        const res = await Result.json();
        if (Result.ok) {
          setResultId(res.msg2._id);
          console.log("Result Data =======>", res.msg2);
        } else {
          console.error("API error =====>", res.err);
        }
      } catch (error) {}
    };
    getResultId();
  }, []);

  useEffect(() => {
    let data = [];
    state.data._id.assignedFolder.pages.map((datas, index) => {
      data.push({ pageData: datas.pageData, marks: datas.marks });
    });
    console.log("push data", data[0]);
    setsHtml(data);
  }, []);

  useEffect(() => {
    // This is working
    // console.log("Total marks ======>", shtml[1].total);
    let total1 = 0;

    console.log("UseEffect is running..........", shtml);

    if (shtml && shtml.length > 0) {
      for (let index = 0; index < shtml.length; index++) {
        const element = shtml[index];
        total1 = total1 + element.marks;
      }
      console.log("My total marks ======>", total1);
      setMcqMarks(total1);

      let index = 0;
      console.log("shtml is validated....................");
      let marks;
      let total = 0;
      let finalMarks = 0;
      window.addEventListener("message", function (e) {
        // Get the sent data
        // if (!e.data?.source) {
        //   console.log("Triggering.........", e.data);
        // }

        let question = e.data.question ? e.data.question : null;
        let options = e.data.options ? e.data.options : [];
        let correctChoice = e.data.correctChoice ? e.data.correctChoice : null;
        let isMcq = e.data.isMcq ? e.data.isMcq : true;
        let checkedAnswer = e.data.checkedAnswer ? e.data.checkedAnswer : null;

        // console.log(
        //   "My question =====>",
        //   question,
        //   "  ",
        //   options,
        //   "  ",
        //   correctChoice,
        //   "  ",
        //   isMcq
        // );

        // console.log("Available options ======>", checkedAnswer, index);
        let dataArr = questionData;
        if (isMcq && !dataArr[index]) {
          // Only store if isMcq, this will run on initially
          dataArr[index] = {
            question,
            options,
            correctChoice,
            isMcq,
            quesMarks: shtml[index].marks,
          };
          setQuestionData(dataArr);
        } else if (dataArr[index] && checkedAnswer) {
          dataArr[index].checkedAnswer = checkedAnswer;
          setQuestionData(dataArr);
        }

        let isVisited = e.data.isVisited ? e.data.isVisited : false;
        const prev = e.data.prev ? e.data.prev : null;
        const next = e.data.next ? e.data.next : null;
        marks = e.data.marks ? e.data.marks : null;
        const questionExists = e.data.questionExists
          ? e.data.questionExists
          : null;

        // this value get's reset
        if (prev) {
          if (index === 0) {
            return this.alert("You are already at the start");
          }
          index = index - 1;
          const myFrame = document.getElementById("my-frame");
          myFrame.srcdoc = "";
          myFrame.srcdoc = shtml[index].pageData.pageHtml;

          setTimeout(() => {
            checkPrevChoice(index);
          }, 200);
          // console.log("Index finalised", index);
        }

        if (next) {
          console.log("Next triggered");
          //setCurrentIndex((prevValue) => prevValue + 1);
          const myFrame = document.getElementById("my-frame");
          myFrame.srcdoc = "";
          index = index + 1;
          // add the marks
          if (!isVisited) {
            total += marks;
          }
          finalMarks =
            questionExists && !isVisited ? finalMarks + 2 : finalMarks;
          setTotalMarks(total);
          console.log(shtml, " index =====>", index);
          if (index > shtml.length - 1) {
            console.log("Index has reached ahead now", index, shtml);
            setFinalMarks(finalMarks);
            setShowSubmit(true);
            // setShowResult(true);
            return;
          }
          console.log("Total marks ======>", total);
          myFrame.srcdoc = shtml[index].pageData.pageHtml;
          setTimeout(() => {
            checkPrevChoice(index);
          }, 200);
          // console.log("Index finalised", currentIndex);
        }

        // If you encode the message in JSON before sending them,
        // then decode here
        // const decoded = JSON.parse(data);
      });
    }

    // return () => {
    //   window.removeEventListener("message");
    // };
  }, [shtml]);

  useEffect(() => {
    console.log("Questions of the useEffect", questionData);
  }, [questionData]);

  return (
    <div className="App">
      <header className="App-header">
        {showResult ? (
          <div>
            Your final score is {totalMarks} out of {finalMarksState}
          </div>
        ) : (
          <>
            <iframe
              title="MyTitle"
              srcDoc={
                shtml && shtml.length > 0
                  ? shtml[0].pageData.pageHtml
                  : "<div>Loading....</div>"
              }
              id="my-frame"
              height="800px"
              width="100%"
            />

            {/* {showSubmit ? (
              <>
                <button onClick={checkPrevChoice}>next</button>
                <button onClick={checkQuestions}>prev</button>
              </>
            ) : (
              <button onClick={checkQuestions}>Submit</button>
            )} */}

            {showSubmit && (
              <Link to="/student/courseswin">
                <button onClick={checkQuestions}>Submit</button>
              </Link>
            )}
          </>
        )}
      </header>
    </div>
  );
}

export default Mcqs;
