import React from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { IoMdHeartEmpty } from "react-icons/io";
import { HiOutlineClipboardList } from "react-icons/hi";
import { GiSkills } from "react-icons/gi";
import { useLessonPlan } from "../../../../../Context/LPContext";
import BouncingBall from "../../../../../assets/img/BouncingBall.png";
import Ques1 from "../Questionier/Ques";
export default function Profile() {
  const navigate = useNavigate();
  const { lessonplans } = useLessonPlan();
  console.log(" LEsson Profileplan data=====================>", lessonplans);
  return (
    <>
      <div
        className="OnGoingProfileImage"
        style={{
          backgroundImage: `url(${BouncingBall})`,
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          className="oncontainerimg"
        >
          <Grid item>
            <IoIosArrowBack fontSize="2rem" onClick={() => navigate(-1)} />
          </Grid>
          <Grid item>
            <IoMdHeartEmpty fontSize="2.5rem" />
          </Grid>
        </Grid>
      </div>
      <Grid
        container
        style={{ marginLeft: "20px", width: "90%", zIndex: 1111 }}
      >
        <Grid item xs={12} className="oncontainerdatacard">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Typography className="GreyColorTypography onlptitle">
              {lessonplans?.LPTitle}
            </Typography>
            <Typography className="GreenColorTypoButton  Hpptypo">
              PP 600
            </Typography>
          </Grid>
          <Grid item className="pl">
            <Typography className="GreyColorTypography">6 Level</Typography>
            <Progress percent={69} className="progressbar" />
            <Typography className="BlackColorTypography  mt6px">
              About
            </Typography>
            <Typography className="GreyColorTypography  ondescrip">
              Consequuntur alias dicta sapiente. Qui ab nam quibusdam. Et et
              dicta pariatur non qui ipsa totam. Ut possimus nobis. Itaque
              recusandae reprehenderit provident placeat.
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              className="onsportstypocard"
            >
              <Typography className="BlackColorTypography fs14px">
                {" "}
                Sports
              </Typography>
              <Typography className="BlackColorTypography   oncritypo">
                Cricket, Baseball, Basketball
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              className="onskilltypo"
            >
              <Typography className="BlackColorTypography">Skills</Typography>
              <Typography className="BlackColorTypography   oncritypo">
                Catching, Running, Swinging
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Typography className="GreyColorTypography onyoutypo ">
          You’ll Get
        </Typography>
        <Grid container xs={12} className="onyoucardcontainer">
          <Grid item xs={4}>
            <Grid item xs={11} className="onyoucarditem">
              <Link to="/student/result">
                <Typography align="center" className="GreenColorTypography">
                  <HiOutlineClipboardList fontSize="4rem" />
                </Typography>
              </Link>
            </Grid>
            <Typography align="center" className="GreyColorTypography pt7px">
              Play Points
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid item xs={11} className="onyoucarditem">
              <Link to="/student/result">
                <Typography align="center" className="GreenColorTypography">
                  <GiSkills fontSize="4rem" />
                </Typography>
              </Link>
            </Grid>
            <Typography align="center" className="GreyColorTypography pt7px">
              Certificate
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid item xs={11} className="onyoucarditem">
              <Link to="/student/result">
                <Typography align="center" className="GreenColorTypography">
                  <HiOutlineClipboardList fontSize="4rem" />
                </Typography>
              </Link>
            </Grid>
            <Typography align="center" className="GreyColorTypography pt7px">
              Badge
            </Typography>
          </Grid>
        </Grid>

        <Typography className="GreyColorTypography onleveltypo">
          Levels
        </Typography>

        <Grid container item className="onquestioncard">
          <Grid item xs={2}>
            <Typography align="center" className="onquestionitem">
              <Ques1 />
            </Typography>
          </Grid>
          <Grid item xs={9} className="pl40px">
            <Grid container item justifyContent="space-between">
              <Typography fontSize="17px" className="GreyColorTypography">
                Level - 1
              </Typography>
              <Typography className="GreenColorTypoButton  Hpptypo onpptypo">
                PP 100
              </Typography>
            </Grid>
            <Typography fontSize="14px" className="GreyColorTypo">
              RBounce with one hand
            </Typography>
            <Progress percent={45} className="progressbar" />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
