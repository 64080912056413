import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import BottomNavigation from "../components/BottomNavigation";
import routes from "../routes";
import { useLocation } from "react-router-dom";

export default function Admin() {
  let location = useLocation();
  const path = location.pathname?.split("/")[2];
  const pathname = ["courses", "trainings", "assesmentnew", "profile", "home"];

  console.log("pathname", path, pathname.includes(path));
  let isBottom = false;
  if (pathname.includes(path)) isBottom = true;

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/student") {
        return (
          <Route
            path={prop.layout + prop.link}
            element={prop.Element}
            key={key}
          />
        );
      } else {
        console.log("AHHHHHHHI");
        return null;
      }
    });
  };

  return (
    <>
      <Routes>
        {getRoutes(routes)}
        <Route path="/" element={<Navigate replace={true} to="/student" />} />
        <Route
          path="/student"
          element={<Navigate replace={true} to="/student/home" />}
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      {isBottom ? <BottomNavigation /> : null}
    </>
  );
}
