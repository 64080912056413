import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  FormControl,
  Typography,
  Grid,
  InputBase,
  Tab,
  Tabs,
} from "@mui/material";
import PropTypes from "prop-types";
import QuestInstruction from "./Component/QuesInstruction";
import Navbar from "./Component/Navbar";
import footerimg from "../../../../../assets/img/ZoommerBootmlogo.png";

import { styled, alpha } from "@mui/material/styles";
import { FiPlay } from "react-icons/fi";
import Ques1 from "./AllQuestion/Ques1";
import Ques2 from "./AllQuestion/Ques2";
import Ques3 from "./AllQuestion/Ques3";
import Ques4 from "./AllQuestion/Ques4";
import Ques5 from "./AllQuestion/Ques5";
import Ques6 from "./AllQuestion/Ques6";
import Ques7 from "./AllQuestion/Ques7";
import Ques8 from "./AllQuestion/Ques8";
import Ques9 from "./AllQuestion/Ques9";
import Ques10 from "./AllQuestion/Ques10";
import Ques11 from "./AllQuestion/Ques11";
import Ques12 from "./AllQuestion/Ques12";
import Ques13 from "./AllQuestion/Ques13";

// Context

import { useLessonPlan } from "../../../../../Context/LPContext";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function MaxWidthDialog() {
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("lg");
  const [value, setValue] = React.useState(0);

  // Context

  const { lessonplans } = useLessonPlan();
  // console.log(" LEssonPlan data=======================>", lessonplans);
  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function TabNext() {
    setValue(1);
  }
  function TabPre1() {
    setValue(0);
  }
  function TabNext1() {
    setValue(2);
  }
  function TabPre2() {
    setValue(1);
  }
  function TabNext2() {
    setValue(3);
  }
  function TabPre3() {
    setValue(2);
  }
  function TabNext3() {
    setValue(4);
  }
  function TabPre4() {
    setValue(3);
  }
  function TabNext4() {
    setValue(5);
  }
  function TabPre5() {
    setValue(4);
  }
  function TabNext5() {
    setValue(6);
  }
  function TabPre6() {
    setValue(5);
  }
  function TabNext6() {
    setValue(7);
  }
  function TabPre7() {
    setValue(6);
  }
  function TabNext7() {
    setValue(8);
  }
  function TabPre8() {
    setValue(7);
  }
  function TabNext8() {
    setValue(9);
  }
  function TabPre9() {
    setValue(8);
  }
  function TabNext9() {
    setValue(10);
  }
  function TabPre10() {
    setValue(9);
  }
  function TabNext10() {
    setValue(11);
  }
  function TabPre11() {
    setValue(10);
  }
  function TabNext11() {
    setValue(12);
  }
  function TabPre12() {
    setValue(11);
  }
  function TabNext12() {
    setValue(13);
  }
  function TabPre13() {
    setValue(12);
  }
  function TabNext13() {
    setValue(11);
  }

  return (
    <React.Fragment>
      <Typography align="center">
        <FiPlay onClick={handleClickOpen} fontSize="2.3rem" />
      </Typography>
      
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        PaperProps={{
          className: "SmallDialog",
        }}
      >
        <Grid container style={{ display: "none" }}>
          <Grid item conatiner>
            <Tabs
              value={value}
              onChange={TabChange}
              aria-label="basic tabs example"
              className=""
            >
              <Tab
                className="AResultTabChanges"
                label="Ongoing"
                {...a11yProps(0)}
              />
              <Tab className="" label="Completed" {...a11yProps(1)} />
            </Tabs>
          </Grid>
        </Grid>
        <DialogContent style={{ padding: "0px" }}>
          <Navbar />
          <TabPanel value={value} index={0}>
            <QuestInstruction TabNext={TabNext} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Ques1 TabPre1={TabPre1} TabNext1={TabNext1} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Ques2 TabPre2={TabPre2} TabNext2={TabNext2} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Ques3 TabPre3={TabPre3} TabNext3={TabNext3} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Ques4 TabPre4={TabPre4} TabNext4={TabNext4} />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Ques5 TabPre5={TabPre5} TabNext5={TabNext5} />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <Ques6 TabPre6={TabPre6} TabNext6={TabNext6} />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <Ques7 TabPre7={TabPre7} TabNext7={TabNext7} />
          </TabPanel>
          <TabPanel value={value} index={8}>
            <Ques8 TabPre8={TabPre8} TabNext8={TabNext8} />
          </TabPanel>
          <TabPanel value={value} index={9}>
            <Ques9 TabPre9={TabPre9} TabNext9={TabNext9} />
          </TabPanel>
          <TabPanel value={value} index={10}>
            <Ques10 TabPre10={TabPre10} TabNext10={TabNext10} />
          </TabPanel>
          <TabPanel value={value} index={11}>
            <Ques11 TabPre11={TabPre11} TabNext11={TabNext11} />
          </TabPanel>
          <TabPanel value={value} index={12}>
            <Ques12 TabPre12={TabPre12} TabNext12={TabNext12} />
          </TabPanel>
          <TabPanel value={value} index={13}>
            <Ques13 TabPre13={TabPre13} TabNext13={TabNext13} />
          </TabPanel>
        </DialogContent>{" "}
        <footer>
          <div>
            <img
              src={footerimg}
              style={{ width: "100%", height: "72px", marginBottom: "-10px" }}
            />
          </div>
        </footer>
      </Dialog>
    </React.Fragment>
  );
}
