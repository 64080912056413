import React, { useState, useEffect } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import "react-circular-progressbar/dist/styles.css";
import rect from "../../../assets/img/Rectangle 3199.png";
import Male from "../../../assets/img/Pexels Photo by Pixabay.png";
import { CircularProgressbar } from "react-circular-progressbar";
import { FETCH_URL } from "../../../fetchIp";
import ResultButtonCardTwo from "../Assesment/ResultButtonCardTwo";
import moment from "moment";

export default function ClassesResult() {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state;

  const user = data?.userData;
  // console.log(users, "userinfo");

  const assesdata = data?.user;
  // console.log(assesdata, "assesmentdata");

  const [resultData, setResultData] = useState();
  const [score, setScore] = useState(0);
  const [totalScore, setTotalScore] = useState(0);
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const getResultId = async () => {
      try {
        const Result = await fetch(`${FETCH_URL}/api/assessment/createResult`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            userId: user?._id,
            name: user?.learnerDetail?.fullName,
            isLearner: true,
            isAssessment: true,
            assessment: assesdata,
            learnerDetails: {
              schoolName: user?.academicDetails?.schoolName,
              location: user?.academicDetails?.location,
              schoolId: user?.assignedSchool,
              grade: user?.academicDetails?.grade,
              gradeId: user?.assignedGrade,
            },
          }),
        });

        const res = await Result.json();
        if (Result.ok) {
          // setAssessmentData(assesdata);
          // setResultId(res.msg2._id);
          setScore(res?.msg2?.totalScore);

          let score1 = 0;
          await res?.msg2?.skillScore?.map((data, key) =>
            data?.rubricsQts?.map((datas, i) => (score1 += datas?.quesMarks))
          );

          setPercentage((res?.msg2?.totalScore / score1) * 100);
          setTotalScore(score1);
          console.log("df", score1);
          setResultData(res?.msg2);
          console.log("Result Data =======>", res?.msg2);
        } else {
          console.error("API error =====>", res?.err);
        }
      } catch (error) {}
    };
    getResultId();
  }, []);

  const [test, setTest] = useState(1);

  return (
    <>
      {console.log("Assessment is ", assesdata)}
      <div className="jumpContainer">
        <div className="assesmentMainDiv">
          <Grid container>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{ marginTop: "30px" }}
            >
              <div className="BackHeadAsses">
                <IoIosArrowBack onClick={() => navigate(-1)} />
              </div>
              <div
                className="WhiteColorTypography MyclassesTypo first-cap"
                // style={{ marginLeft: "-15px" }}
              >
                {assesdata?.assessmentName}
              </div>
              <div></div>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={2} className="AssesProfileConatiner2">
          <div className="jumpCont">
            <div className="jumpsImage">
              <img src={assesdata?.assessmentImage} />
            </div>
            <div className="PPInfo">
              <Grid item xs={12}>
                <Typography align="center" className="PPCourseButton">
                  {assesdata?.assignedXp} PP
                </Typography>
              </Grid>
            </div>
            <div className="jumpInfo">
              <div className="jumpDetail">
                <h2>{assesdata?.assessmentName}</h2>
                <h3>{assesdata?.uid}</h3>
                <h3>
                  {moment(assesdata?.assignedGrades?.[0]?.assignedOn).format(
                    "DD/MM/YYYY"
                  )}
                </h3>
                <div className="downloadBtns">
                  <button>Completed</button>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <div className="assesWid2">
          <div className="assesUsers">
            <div className="assesInfo">
              <div className="assesImgs">
                <img src={user?.learnerDetail?.learnerImg} />
              </div>
              <div className="assesTexts first-cap">
                <h2>{user?.learnerDetail?.fullName}</h2>
                <div className="assesMinTxt">
                  <h5> {user?.learnerDetail?.regNo},</h5>
                  <h5 style={{ marginLeft: "5px" }}>
                    {user?.academicDetails?.grade}
                  </h5>
                </div>
                <h5 style={{ width: "99%" }}>
                  {user?.academicDetails?.schoolName}
                </h5>
              </div>
            </div>
            <div className="proscores">
              <div className="proAsses">
                <CircularProgressbar
                  justifyContent="center"
                  className="CircularBar stroke-white"
                  value={percentage.toFixed(2)}
                  text={
                    percentage != 0
                      ? percentage == 100
                        ? "100%"
                        : percentage.toFixed(2) + "%"
                      : "0%"
                  }
                />
              </div>
              <div className="scoresAsses">
                <h2>Score:</h2>
                <h3>
                  {score}/ {totalScore}
                </h3>
              </div>
            </div>
          </div>

          {score > 0 && (
            <ResultButtonCardTwo test={test} resultData={resultData} />
          )}

          {/* <div className="Assesboxbtn">
            <div className="assesQ">
              <h2>Able to jump properly?</h2>
            </div>
            <div className="Btnscore">
              <div className="notBtn">
                <button>Not Yet</button>
              </div>
              <div className="assesMark">
                <h3>Score:</h3>
                <h4>0/2</h4>
              </div>
            </div>
          </div>

          <div className="Assesboxbtn">
            <div className="assesQ">
              <h2>Able to land properly?</h2>
            </div>
            <div className="Btnscore">
              <div className="yesBtn">
                <button>Yes</button>
              </div>
              <div className="assesMark">
                <h3>Score:</h3>
                <h4>0/2</h4>
              </div>
            </div>
          </div>

          <div className="Assesboxbtn">
            <div className="assesQ">
              <h2>Able to jump 5 times</h2>
            </div>
            <div className="Btnscore">
              <div className="numBtn">
                <button>4</button>
              </div>
              <div className="assesMark">
                <h3>Score:</h3>
                <h4>0/2</h4>
              </div>
            </div>
          </div>

          <div className="Assesboxbtn">
            <div className="assesQ">
              <h2>Able to land properly?</h2>
            </div>
            <div className="Btnscore">
              <div className="yesBtn">
                <button>Gets it</button>
              </div>
              <div className="assesMark">
                <h3>Score:</h3>
                <h4>0/2</h4>
              </div>
            </div>
          </div>

          <div className="Assesboxbtn">
            <div className="assesQ">
              <h2>Able to land properly?</h2>
            </div>
            <div className="Btnscore">
              <div className="getsBtn">
                <button>Getting there</button>
              </div>
              <div className="assesMark">
                <h3>Score:</h3>
                <h4>0/2</h4>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
