import React, { useState, useEffect } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import { VscBellDot } from "react-icons/vsc";
import { FETCH_URL } from "../../../fetchIp";
import { useAuth } from "../../../Context/AuthContext";
import LoadingSpinner from "../../Spinner/LoadingSpinner";
import axios from "axios";
import { publicRequest } from "../../../BaseUrl";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function ClassesUser() {
  const navigate = useNavigate();

  const { trainerId } = useAuth();
  const [gradeData, setGradeData] = useState([0]);
  const [divisionData, setDivisionData] = useState([]);
  const [learnerData, setlearnerData] = useState([]);
  const [gradeId, setGradeId] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const data = location.state;

  const [idValue, setIdValue] = useState(data?.divisions?.[0]?._id);

  const changeDivision = async (divisionId) => {
    // setIdValue(divisionId);

    console.log("DivisionId", divisionId);
    try {
      const data = await publicRequest.get(
        `/api/learner/getPaginatedLearner?division=${divisionId}`
      );

      console.log("Learner Data is ", learnerData);
      setlearnerData(data?.data?.msg);
    } catch (err) {
      console.log("Error is ", err);
    }
  };

  const getGrade = async () => {
    try {
      setIsLoading(true);
      const res2 = await publicRequest.get(
        `/api/learner/getPaginatedLearner?division=${data?.divisions?.[0]?._id}`
      );

      console.log("Learner Data =======>", res2);
      setlearnerData(res2?.data?.msg);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("Catch block ====>", error);
    }
  };

  useEffect(() => {
    getGrade();
    console.log("State Data is :", data);
    // console.log("Select ID is :", id);
  }, []);

  return (
    <>
      {isLoading ? <LoadingSpinner /> : null}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        backgroundColor="#CDDC39"
        borderRadius="0px"
        height="84px"
        paddingTop="20px"
      >
        <div className="BackHead ">
          <IoIosArrowBack onClick={() => navigate(-1)} />
        </div>
        <div className="WhiteColorTypography MyclassesTypo ml-10px">
          {data?.schoolName}
        </div>
        <div> </div>
      </Grid>

      <div className="schlBox">
        <div className="blackType locText">
          <h2 value={data._id}>{data.grade}</h2>
        </div>

        <FormControl
          sx={{ m: 2.2, minWidth: "90%" }}
          size="small"
          className="select-radius-8px"
        >
          <InputLabel id="select_division">Select Division</InputLabel>
          <Select
            labelId="select_division"
            id="select_division"
            value={idValue}
            onChange={(e) => {
              changeDivision(e.target.value);
              setIdValue(e.target.value);
            }}
            label="Select Division"
          >
            {data?.divisions?.map((item, index) => {
              return <MenuItem value={item?._id}>{item.division}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </div>

      <Grid mt={2} p={0.5} pl={2} mb={1}>
        <Typography className="color-6f6f6f font-w-700">
          Student List
        </Typography>
      </Grid>
      <div className="listTab">
        <div className="listDivColor">
          <h2>Roll-No.</h2>
          <h2>Name</h2>
          <h2>Score</h2>
        </div>
        {learnerData?.map((user) => (
          <Link to="/student/classesreport" state={user} key={user?._id}>
            <div className="listDiv">
              <h2> {user.learnerDetail.regNo}</h2>
              <h2> {user.learnerDetail.fullName}</h2>
              <h2 style={{ color: "#CDDC39" }}>View</h2>
            </div>
          </Link>
        ))}

        {learnerData?.length == 0 ? (
          <Grid className="d-flex-center align-center h-350px color-light-black">
            <Typography className="font-w-700 font-14">{`No Learners Data Found `}</Typography>
          </Grid>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
