import React, { useEffect, useState } from "react";
import { Input, Grid, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { FETCH_URL } from "../../fetchIp";
import { SettingsAccessibilitySharp } from "@mui/icons-material";

export default function AssementButtonCard(props) {
  const [assessmentData, setAssessmentData] = useState(props.AssessmentData);

  const resultId = props.resultId;
  console.log(props.AssessmentData);
  const handleInputChange = (e, key, i, minvalue) => {
    props.onButtonChange(key, i, e.target.value, minvalue);
  };

  useEffect(() => {
    setAssessmentData(props.AssessmentData);
    console.log(
      "I am re rendering now ........",
      props.AssessmentData,
      "  ",
      props.test
    );
  }, [props.AssessmentData, props.test]);

  return (
    <>
      {assessmentData?.skills?.map((data, key) =>
        data.rubricsQts.map((datas, i) => (
          <>
            <Grid
              container
              xs={12}
              style={{
                backgroundColor: "#ffffff",
                height: "auto",
                width: "93%",
                marginLeft: "10px",
                marginRight: "10px",
                marginTop: "10px",
                margin: "10px",
                borderRadius: "10px",
              }}
            >
              <Grid item style={{ textAlign: "left" }}>
                <Typography
                  className="lightGreyColorTypo Fs-0-8"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  {datas.quesDesc}
                </Typography>
              </Grid>

              {datas.quesType == 1 && (
                <Grid container style={{ marginBottom: "8px" }}>
                  <Grid item xs={3}>
                    <Typography className="GreyColorTypography QNoTypo">
                      value
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Input
                      className="RubicInputs"
                      id="QuNo"
                      name="quesNo"
                      onChange={(e) =>
                        handleInputChange(e, key, i, datas.minValue)
                      }
                      value={datas?.type}
                      // onChange={(e) => {
                      //   // add to list
                      //   setRubricsInput([
                      //     ...rubricsQts,
                      //     {
                      //       quesNo: " ",
                      //     },
                      //   ]);
                      // }}
                    ></Input>
                  </Grid>
                  <Grid item xs={6}>
                    <button
                      type="submit"
                      className="YellowButton700 lightGreyColorTypo Fs-0-8 SmallButtons"
                      // onClick={(e) =>
                      //   props.onButtonChange(key, i, e.currentTarget.value)
                      // }
                    >
                      Evaluate
                    </button>
                  </Grid>
                </Grid>
              )}

              {datas.quesType == 2 && (
                <Grid container style={{ marginBottom: "8px" }}>
                  <Grid item xs={4}>
                    <button
                      type="submit"
                      value="Yes"
                      onClick={(e) =>
                        props.onButtonChange(key, i, e.currentTarget.value)
                      }
                      className={
                        datas?.type == "Yes"
                          ? "YellowButton700 lightGreyColorTypo Fs-0-8 SmallButtons"
                          : "WhiteButton700"
                      }
                      //  className="RedButton700 lightGreyColorTypo Fs-0-8 SmallButtons "
                    >
                      Yes
                    </button>
                  </Grid>
                  <Grid item xs={4}>
                    <button
                      type="submit"
                      value="No"
                      onClick={(e) =>
                        props.onButtonChange(key, i, e.currentTarget.value)
                      }
                      className={
                        datas?.type == "No"
                          ? "RedButton700 lightGreyColorTypo Fs-0-8 SmallButtons "
                          : "WhiteButton700   ProfileButton"
                      }
                    >
                      No
                    </button>
                  </Grid>
                </Grid>
              )}

              {datas.quesType == 3 && (
                <Grid container style={{ marginBottom: "8px" }}>
                  <Grid item xs={4}>
                    <button
                      type="submit"
                      value="Not Yet"
                      onClick={(e) =>
                        props.onButtonChange(key, i, e.currentTarget.value)
                      }
                      className={
                        datas?.type == "Not Yet"
                          ? "RedButton700 lightGreyColorTypo Fs-0-8 SmallButtons "
                          : "WhiteButton700"
                      }
                      //  className="RedButton700 lightGreyColorTypo Fs-0-8 SmallButtons "
                    >
                      Not Yet
                    </button>
                  </Grid>
                  <Grid item xs={4}>
                    <button
                      type="submit"
                      value="Getting There"
                      onClick={(e) =>
                        props.onButtonChange(key, i, e.currentTarget.value)
                      }
                      className={
                        datas?.type == "Getting There"
                          ? "YellowButton700 lightGreyColorTypo Fs-0-8 SmallButtons"
                          : "WhiteButton700   ProfileButton"
                      }
                    >
                      Getting There
                    </button>
                  </Grid>
                  <Grid item xs={4}>
                    <button
                      type="submit"
                      value="Gets IT"
                      onClick={(e) =>
                        props.onButtonChange(key, i, e.currentTarget.value)
                      }
                      className={
                        datas?.type == "Gets IT"
                          ? "GreenButton700 lightGreyColorTypo Fs-0-8 SmallButtons "
                          : "WhiteButton700   ProfileButton"
                      }
                    >
                      Gets IT
                    </button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        ))
      )}
    </>
  );
}
