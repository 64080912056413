import React from "react";
import unlock from "../../../assets/img/card 2.png";

export default function CoursesUnlock() {
  return (
    <>
      <div className="unlocksImg">
        <img src={unlock} />
        <div className="unlockTxt1">
          <h2>You Unlocked Level 2</h2>
        </div>
        <div className="unlockTxt2">
          <h6>Level 6</h6>
        </div>
        <div className="unlockTxt3">
          <h4>Catch Ball with Both Hands</h4>
        </div>
        <div className="unlockTxt4">
          <h5>+124 GP</h5>
        </div>
        <div className="unlockBtns1">
          <button>Go to Level 2</button>
        </div>
        <div className="unlockBtns2">
          <button>Back</button>
        </div>
      </div>
    </>
  );
}
