import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import lockIcon from "../../../../assets/img/akar-icons_lock-on.png";
import { IoMdHeartEmpty } from "react-icons/io";
import Avatar from "@mui/material/Avatar";
import { HiOutlineClipboardList } from "react-icons/hi";
import { GiSkills } from "react-icons/gi";
import { styled } from "@mui/material/styles";
import Ques1 from "./Questionier/Ques";
import { FiPlay } from "react-icons/fi";
import { FETCH_URL } from "../../../../fetchIp";
import { useLessonPlan } from "../../../../Context/LPContext";

export default function Profile() {
  const navigate = useNavigate();
  const location = useLocation();
  const [courseData, setCourseData] = useState(location.state);
  const [totalPP, setTotalPP] = useState();
  let lockstate;

  console.log(" courseData========>", courseData);
  console.log(" courseId========>", location.state._id);
  console.log("lessonPP", courseData.lessonPlans[0]);

  let pp = 0;
  courseData.lessonPlans.map((data, index) => {
    pp += data._id.LPXp;
  });

  useEffect(() => {
    setTotalPP(pp);
  }, []);

  return (
    <>
      <div
        className="OnGoingProfileImage"
        style={{
          backgroundImage: `url(${courseData.courseThumbnail})`,
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          className="oncontainerimg"
        >
          <Grid item>
            <IoIosArrowBack fontSize="2rem" onClick={() => navigate(-1)} />
          </Grid>
          <Grid item>
            <IoMdHeartEmpty fontSize="2.5rem" />
          </Grid>
        </Grid>
      </div>
      <Grid
        container
        style={{ marginLeft: "20px", width: "90%", zIndex: 1111 }}
      >
        <Grid item xs={12} className="oncontainerdatacard">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Typography className="GreyColorTypography onlptitle">
              {courseData?.courseTitle}
            </Typography>
            <Typography className="GreenColorTypoButton  Hpptypo">
              PP {totalPP}
            </Typography>
          </Grid>
          <Grid item className="pl">
            <Typography className="GreyColorTypography">
              {/* {lp.LPLevel} Level */}
              {courseData.lessonPlans.length}
              {courseData.lessonPlans.length > 1 ? " Levels" : " Level"}
            </Typography>
            <Progress percent={69} className="progressbar" />
            <Typography className="BlackColorTypography  mt6px">
              About
            </Typography>
            <Typography className="GreyColorTypography  ondescrip">
              {courseData?.courseDesc}
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              className="onsportstypocard"
            >
              <Typography className="BlackColorTypography fs14px">
                Sports
              </Typography>
              <Typography className="BlackColorTypography   oncritypo">
                Cricket, Baseball, Basketball
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              className="onskilltypo"
            >
              <Typography className="BlackColorTypography">Skills</Typography>
              <Typography className="BlackColorTypography   oncritypo">
                {/* Catching, Running, Swinging */}
                {`${courseData?.skills[0]?.mainSkill} ,`}
                {courseData?.skills[1]?.mainSkill}
                {courseData?.skills[2]?.mainSkill}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Typography className="GreyColorTypography onyoutypo ">
          You’ll Get
        </Typography>
        <Grid container xs={12} className="onyoucardcontainer">
          <Grid item xs={4}>
            <Grid item xs={11} className="onyoucarditem">
              <Link to="/student/result">
                <Typography align="center" className="GreenColorTypography">
                  <HiOutlineClipboardList fontSize="4rem" />
                </Typography>
              </Link>
            </Grid>
            <Typography align="center" className="GreyColorTypography pt7px">
              Play Points
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid item xs={11} className="onyoucarditem">
              <Link to="/student/result">
                <Typography align="center" className="GreenColorTypography">
                  <GiSkills fontSize="4rem" />
                </Typography>
              </Link>
            </Grid>
            <Typography align="center" className="GreyColorTypography pt7px">
              Certificate
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid item xs={11} className="onyoucarditem">
              <Link to="/student/result">
                <Typography align="center" className="GreenColorTypography">
                  <HiOutlineClipboardList fontSize="4rem" />
                </Typography>
              </Link>
            </Grid>
            <Typography align="center" className="GreyColorTypography pt7px">
              Badge
            </Typography>
          </Grid>
        </Grid>
        {courseData.lessonPlans.length > 0 ? (
          <>
            <Typography className="GreyColorTypography onleveltypo">
              Levels
            </Typography>

            {courseData.lessonPlans.map((data, index) => {
              (data.completion || index === 0) && (
                <Grid
                  container
                  item
                  onClick={() =>
                    navigate("/student/lessonplanprofile", {
                      state: { data, courseId: location.state._id },
                    })
                  }
                >
                  <Grid container item className="onquestioncard-course">
                    <Grid item xs={2}>
                      <Typography align="center" className="onquestionitem">
                        {/* <Ques1 /> */}
                        <Typography align="center">
                          <FiPlay fontSize="2.3rem" />
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={9} className="pl40px">
                      <Grid container item justifyContent="space-between">
                        <Typography
                          fontSize="17px"
                          className="GreyColorTypography"
                        >
                          {/* Level - {lp.LPLevel}Level-hard */}

                          {data.title}
                        </Typography>
                        <Typography className="GreenColorTypoButton  Hpptypo onpptypo">
                          {/* PP {lp.LPXp} */} PP {data._id.LPXp}
                        </Typography>
                      </Grid>
                      <Typography fontSize="14px" className="GreyColorTypo">
                        RBounce with one hand
                      </Typography>
                      <Progress percent={45} className="progressbar" />
                    </Grid>
                  </Grid>
                </Grid>
              );

              if (data?.completion) {
                lockstate = index + 1;
              }
              (data.completion && index !== 0) || index === lockstate ? (
                <Grid
                  container
                  item
                  onClick={() =>
                    navigate("/student/lessonplanprofile", {
                      state: { data, courseId: location.state._id },
                    })
                  }
                >
                  <Grid container item className="onquestioncard-course">
                    <Grid item xs={2}>
                      <Typography align="center" className="onquestionitem">
                        {/* <Ques1 /> */}
                        <Typography align="center">
                          <FiPlay fontSize="2.3rem" />
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={9} className="pl40px">
                      <Grid container item justifyContent="space-between">
                        <Typography
                          fontSize="17px"
                          className="GreyColorTypography"
                        >
                          {/* Level - {lp.LPLevel}Level-hard */}

                          {data.title}
                        </Typography>
                        <Typography className="GreenColorTypoButton  Hpptypo onpptypo">
                          {/* PP {lp.LPXp} */} PP {data._id.LPXp}
                        </Typography>
                      </Grid>
                      <Typography fontSize="14px" className="GreyColorTypo">
                        RBounce with one hand
                      </Typography>
                      <Progress percent={45} className="progressbar" />
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  item
                  onClick={() =>
                    navigate("/student/lessonplanprofile", {
                      state: { data, courseId: location.state._id },
                    })
                  }
                >
                  <Grid container className="locked">
                    <Grid item className="locked_content">
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{ marginBottom: "10px" }}
                          src={lockIcon}
                          alt=""
                        />
                        <br />
                        <span>Reach Level 10 to unlock this </span>
                      </div>
                    </Grid>
                    <Grid container item className="onquestioncard-course">
                      <Grid item xs={2}>
                        <Typography align="center" className="onquestionitem">
                          {/* <Ques1 /> */}
                          <Typography align="center">
                            <FiPlay fontSize="2.3rem" />
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={9} className="pl40px">
                        <Grid container item justifyContent="space-between">
                          <Typography
                            fontSize="17px"
                            className="GreyColorTypography"
                          >
                            {/* Level - {lp.LPLevel}Level-hard */}

                            {data.title}
                          </Typography>
                          <Typography className="GreenColorTypoButton  Hpptypo onpptypo">
                            {/* PP {lp.LPXp} */} PP {data._id.LPXp}
                          </Typography>
                        </Grid>
                        <Typography fontSize="14px" className="GreyColorTypo">
                          RBounce with one hand
                        </Typography>
                        <Progress percent={45} className="progressbar" />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </>
        ) : null}
      </Grid>
    </>
  );
}
