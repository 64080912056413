import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
// import myHtml from "./html/test.html";
import { useAuth } from "../../../Context/AuthContext";
import { FETCH_URL } from "../../../fetchIp";
import SumbitVideo from "../../../assets/Video/SubmitVideo.mp4";
import { Typography, Button, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function McqsData() {
  const vidRef = useRef();

  const { learnerId } = useAuth();
  const navigate = useNavigate();

  const location = useLocation();
  const states = location.state.lpdata;
  const stateData = location?.state;
  const courseResId = location.state.courseResid;

  console.log(courseResId, "course result id");
  console.log("StateData", stateData);

  const lessonResId = location.state.resultIdlessonCourseId;
  console.log(lessonResId, "lesson Result Id");

  console.log("data from useLocation", states);
  console.log("CourseId from useLocation", states.courseId);

  const [myState, setMyState] = React.useState("");
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const lessonPlanLength = location.state.lessonPlanLength;
  const actualLessonPlanLength = location.state.actualLessonPlanLength;

  console.log(
    "lessonPlanLength+actualLessonPlanLength",
    lessonPlanLength,
    actualLessonPlanLength
  );

  // If true then show result
  const [showResult, setShowResult] = React.useState(false);
  const [totalMarks, setTotalMarks] = React.useState(0);
  const [finalMarksState, setFinalMarks] = React.useState(0);
  const [mcqMarks, setMcqMarks] = useState(0);
  const [myVisits, setMyVisits] = React.useState([{}]);
  const [resultTotal, setResultTotal] = useState();
  const [CourseCompleted, setCourseCompleted] = useState(null);

  // For submit button show
  const [showSubmit, setShowSubmit] = useState(false);

  // States for storing Question data:
  const [questionData, setQuestionData] = React.useState([]);

  const [shtml, setsHtml] = useState([]);

  const checkPrevChoice = (index) => {
    // Add on previous button
    const myFrame = document.getElementById("my-frame");
    console.log("I trigger=======>", index);
    // myFrame.srcdoc = "";
    // myFrame.srcdoc = shtml[index].pageData.pageHtml;
    console.log("My frame ======>", questionData[index].checkedAnswer);
    if (index >= 0) {
      if (
        questionData &&
        questionData.length > 0 &&
        questionData[index] &&
        questionData[index].isMcq &&
        questionData[index].checkedAnswer
      ) {
        myFrame.contentWindow.postMessage(
          { prevChoice: questionData[index].checkedAnswer },
          "*"
        );
      }
    }
  };

  const checkQuestions = async () => {
    // Saving the userScore
    console.log("Me just checking the questions ====>", questionData);

    let total = 0;

    console.log(
      "Triggering on prev button call in react ======>",
      questionData
    );

    for (let i = 0; i < questionData.length; i++) {
      if (questionData[i].isMcq) {
        if ("checkedAnswer" in questionData[i]) {
          if (questionData[i].correctChoice === questionData[i].checkedAnswer) {
            total = total + parseInt(questionData[i].quesMarks);
          }
        } else {
          total = total + parseInt(questionData[i].quesMarks);
        }
      }
    }

    // * All this above logic goes to API call for saving the data, on Submit button....
    console.log("My total from the finale =======>", total);
    setResultTotal(total);

    // ! Save to API
    let resultPP = (states.LPXp * total) / mcqMarks;

    console.log(total, "mytotal");
    console.log(courseResId, lessonResId, "courseResId lessonResId");

    try {
      const response = await fetch(
        `${FETCH_URL}/api/assessment/calculateCourseResult/${courseResId}/${lessonResId}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            totalScore: total,
            resultPP,
            CourseCompleted,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        console.log("On response arrived after creating page", res);
        navigate("/student/courseswin", {
          state: { total, states, stateData },
        });
      } else {
        console.log("On resp err on creating page", res.err);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (window.ReactNativeWebView) window.ReactNativeWebView.postMessage(2);
  }, []);

  useEffect(() => {
    let data = [];
    states.assignedFolder.pages.map((datas, index) => {
      data.push({
        pageData: datas.pageData,
        marks: datas.marks,
        isMcq: datas?.isMcq,
      });
    });
    console.log("push data", data[0]);
    setsHtml(data);
    if (lessonPlanLength == actualLessonPlanLength) {
      setCourseCompleted(true);
    }
  }, []);

  useEffect(() => {
    // console.log("Total marks ======>", shtml[1].total);
    let total1 = 0;

    console.log("UseEffect is running..........", shtml);

    if (shtml && shtml.length > 0) {
      for (let index = 0; index < shtml.length; index++) {
        const element = shtml[index];
        total1 = total1 + element.marks;
      }
      console.log("My total marks ======>", total1);
      setMcqMarks(total1);

      let index = 0;
      console.log("shtml is validated....................");
      let marks;
      let total = 0;
      let finalMarks = 0;
      window.addEventListener("message", function (e) {
        let question = e.data.question ? e.data.question : null;
        let options = e.data.options ? e.data.options : [];
        let correctChoice = e.data.correctChoice ? e.data.correctChoice : null;
        let isMcq = e.data.isMcq ? e.data.isMcq : true;
        let checkedAnswer = e.data.checkedAnswer ? e.data.checkedAnswer : null;

        let dataArr = questionData;
        if (isMcq && !dataArr[index]) {
          // Only store if isMcq, this will run on initially
          dataArr[index] = {
            question,
            options,
            correctChoice,
            isMcq,
            quesMarks: shtml[index].marks,
            mcqStatus: shtml[index].isMcq,
          };
          setQuestionData(dataArr);
        } else if (dataArr[index] && checkedAnswer) {
          dataArr[index].checkedAnswer = checkedAnswer;
          setQuestionData(dataArr);
        }

        let isVisited = e.data.isVisited ? e.data.isVisited : false;
        const prev = e.data.prev ? e.data.prev : null;
        const next = e.data.next ? e.data.next : null;
        marks = e.data.marks ? e.data.marks : null;
        const questionExists = e.data.questionExists
          ? e.data.questionExists
          : null;

        // this value get's reset
        if (prev) {
          if (index === 0) {
            return this.alert("You are already at the start");
          }
          index = index - 1;
          const myFrame = document.getElementById("my-frame");
          myFrame.srcdoc = "";
          myFrame.srcdoc = shtml[index].pageData.pageHtml;

          setTimeout(() => {
            checkPrevChoice(index);
          }, 200);
          // console.log("Index finalised", index);
        }

        if (next) {
          console.log("Next triggered");
          //setCurrentIndex((prevValue) => prevValue + 1);
          const myFrame = document.getElementById("my-frame");
          myFrame.srcdoc = "";
          index = index + 1;
          // add the marks
          if (!isVisited) {
            total += marks;
          }
          finalMarks =
            questionExists && !isVisited ? finalMarks + 2 : finalMarks;
          setTotalMarks(total);
          console.log(shtml, " index =====>", index);
          if (index > shtml.length - 1) {
            console.log("Index has reached ahead now", index, shtml);
            setFinalMarks(finalMarks);
            setShowSubmit(true);
            setShowResult(true);
            return;
          }
          console.log("Total marks ======>", total);
          myFrame.srcdoc = shtml[index].pageData.pageHtml;
          setTimeout(() => {
            checkPrevChoice(index);
          }, 200);
          // console.log("Index finalised", currentIndex);
        }

        // If you encode the message in JSON before sending them,
        // then decode here
        // const decoded = JSON.parse(data);
      });
    }

    // return () => {
    //   window.removeEventListener("message");
    // };
  }, [shtml]);

  useEffect(() => {
    console.log("Questions of the useEffect", questionData);
    window.scrollTo(0, 0);
  }, [questionData]);

  return (
    <div className="App">
      <header className="App-header">
        <Grid container>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            style={{ padding: "5px" }}
          >
            <div></div>
            <Link to={`/student/courses`}>
              <CloseIcon />
            </Link>
          </Grid>
        </Grid>

        {showResult ? (
          <div>
            {/* Your final score is {totalMarks} out of {finalMarksState} */}
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{
                  fontSize: "20px",
                  width: "80%",
                  color: "#4B4B4B",
                  marginTop: "30px",
                  fontFamily: "Play sans-serif",
                  fontWeight: 700,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                align="center"
              >
                Congratulations
              </Typography>
              <Typography
                style={{
                  fontSize: "20px",
                  color: "#4B4B4B",
                  marginTop: "10px",
                  fontFamily: "Play sans-serif",
                  fontWeight: 700,
                  marginTop: "30px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                align="center"
              >
                You just completed an amazing lesson.
              </Typography>{" "}
              <Typography
                style={{
                  marginTop: "10px",
                  fontSize: "20px",
                  width: "100%",
                  color: "#4B4B4B",
                  marginTop: "30px",
                  fontFamily: "Play sans-serif",
                  fontWeight: 700,
                }}
                align="center"
              >
                <video
                  autoPlay
                  loop
                  src={SumbitVideo}
                  ref={vidRef}
                  controls={true}
                  style={{
                    width: "80%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </Typography>{" "}
              <Typography
                style={{
                  fontSize: "20px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  color: "#4B4B4B",
                  marginTop: "10px",
                  fontFamily: "Play sans-serif",
                  fontWeight: 700,
                  width: "70%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                align="center"
              >
                Now press Submit button to check your result
              </Typography>{" "}
              <Typography
                style={{
                  fontSize: "20px",
                  width: "100%",
                  color: "#4B4B4B",
                  marginTop: "10px",
                  fontFamily: "Play sans-serif",
                  fontWeight: 700,
                  marginTop: "60px",
                }}
                align="center"
              >
                <Button
                  style={{
                    color: "#FFFFFF",
                    borderRadius: "8px",
                    width: "30%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    height: "36px",
                    backgroundColor: "#CDDC39 ",
                    fontFamily: "Play sans-serif",
                    fontWeight: 700,
                  }}
                  onClick={checkQuestions}
                >
                  Sumbit
                </Button>
              </Typography>
            </Grid>
          </div>
        ) : (
          <>
            <iframe
              title="MyTitle"
              srcDoc={
                shtml && shtml.length > 0
                  ? shtml[0].pageData.pageHtml
                  : "<div>Loading....</div>"
              }
              height="800px"
              id="my-frame"
              width="100%"
            />

            {/* {showSubmit && (
            
            )} */}
          </>
        )}
      </header>
    </div>
  );
}

export default McqsData;
