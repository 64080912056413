import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import OngoingCo from "./OngoingCo";
import CompletedCo from "./CompletedCo";

export default function Courses({ dontShowNavBar }) {
  const navigate = useNavigate();

  const [locked, setLocked] = useState(0);
  const handleScreenUnlocked = () => {
    setLocked(0);
  };

  const handleScreenLocked = () => {
    setLocked(1);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section>
        {/* {dontShowNavBar !== "true" && ( */}
        <Grid className="bg-cddc39 p-fixed zIndex-400 w-100">
          <Grid container>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{ paddingTop: "15px", backgroundColor: "#cddc39" }}
            >
              <div className="BackHeadLess">
                <IoIosArrowBack onClick={() => navigate("/student/home")} />
              </div>
              <div className="WhiteColorTypography MyclassesTypo ml-32px">
                Course
              </div>
              <div></div>
            </Grid>
          </Grid>
          <div className="playFirst">
            <div className="playFirstChanges">
              <span
                onClick={handleScreenUnlocked}
                style={{ float: "left" }}
                className={locked === 0 ? "toggleBar" : "nontoggleBar"}
              >
                Ongoing
              </span>
              <span
                onClick={handleScreenLocked}
                style={{ float: "right" }}
                className={locked === 1 ? "toggleBar" : "nontoggleBar"}
              >
                Expired
              </span>
            </div>
          </div>
        </Grid>
        {/* )} */}
        <div>
          {locked === 0 ? (
            <div className="pt-132px">
              <OngoingCo />
            </div>
          ) : (
            <div className="pt-132px">
              <CompletedCo />
            </div>
          )}
        </div>
      </section>
    </>
  );
}
