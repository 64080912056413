import React from "react";
import { Grid, Tab, Box, Tabs, DialogContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import PropTypes from "prop-types";
import StudentLB from "./StudentLB";
import TeacherLB from "./TeacherLB";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function LeaderBoardNew() {
  const navigate = useNavigate();

  const [value, setValue] = React.useState(0);

  const TabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div
        style={{
          minHeight: "99vh",
          maxHeight: "100%",
          backgroundColor: " #cddc39",
          paddingBottom: "20px",
        }}
      >
        <div style={{ width: "89%", marginLeft: "10px" }}>
          <Grid container>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <div className="BackHeadLead">
                <IoIosArrowBack onClick={() => navigate(-1)} />
              </div>
              <div className="WhiteColorTypography LeaderBoardTypo ">
                Leaderboard
              </div>
              <div></div>
            </Grid>
          </Grid>
          <Grid container className="TabGrid2" paddingTop="16px" ml={2}>
            <Grid item conatiner>
              <Tabs
                value={value}
                onChange={TabChange}
                aria-label="basic tabs example"
                className="AResultTabStyle2"
              >
                <Tab
                  className="AResultTabChanges2"
                  label="Student"
                  {...a11yProps(0)}
                />
                <Tab
                  className="AResultTabChanges2"
                  label="Teacher"
                  {...a11yProps(1)}
                />
              </Tabs>
            </Grid>
          </Grid>
        </div>

        <DialogContent
          style={{
            marginTop: "24px",
            justifyContent: "center",
            marginLeft: "10px",
            marginRight: "10px",
          }}
          dividers={false}
          className="TabPanelStyle"
        >
          <TabPanel value={value} index={0}>
            <StudentLB />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TeacherLB />
          </TabPanel>
        </DialogContent>
      </div>
    </>
  );
}
